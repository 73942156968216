import React, { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { customerText } from "../../../components/Seo/whitelabel/bybest/bybest-customer-layout";
import CustomerService from "../../../services/customer-service";
import CustomerFooterButtons from "../../customer/customer-footer-buttons";
import CustomerHeader from "../../customer/customer-header";
import Address from "../../customer/personal-information/address";
import CommunicationPreferences from "../../customer/personal-information/communication-preferences";
import GeneralInfo from "../../customer/personal-information/general-info";
import "../../customer/personal-information/index.css";

const AccPersonalInformation = () => {
  const [switchStatus, setSwitchStatus] = useState({
    promotion_sms_notify: false,
    promotion_email_notify: false,
    booking_sms_notify: false,
    booking_email_notify: false,
    source: 'metrosuites',
  });
  const [switchStatusFlag, setSwitchStatusFlag] = useState(false);

  const [data, setData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    country: "",
    street_address: "",
    city: "",
    state: "",
    zip: "",
  });

  const [loading, setLoading] = useState(false);

  const getProfileData = () => {
    CustomerService.getProfile().then((response) => {
      const fullName = response?.user?.name || "";
      const [firstNameFallback, lastNameFallback] = fullName.split(" ");

      setData({
        first_name: response?.user?.first_name || firstNameFallback || "",
        last_name: response?.user?.last_name || lastNameFallback || "",
        email: response?.guest?.email || "",
        phone: response?.guest?.phone || "",
        country: response?.address?.country || "",
        street_address: response?.address?.address_line1 || "",
        city: response?.address?.city || "",
        state: response?.address?.state || "",
        zip: response?.address?.postcode || "",
        cId: response?.user?.id || null,
        uId: response?.user?.id || null,
        aId: response?.address?.id || null,
      });
    });
  };

  useEffect(() => {
    getProfileData();
    fetchMarketingSettings();
  }, []);

  const fetchMarketingSettings = () => {
    CustomerService.getMarketingSettings()
      .then((response) => {
        setSwitchStatus(response.marketing_settings);
      })
      .catch((error) => {
        NotificationManager.error(
          error?.message || "Failed to fetch settings",
          "Error",
          3000
        );
      });
  };

  const handleMarketingSettingsSave = (flag) => {
    setSwitchStatusFlag(flag);
  };

  useEffect(() => {
    if (switchStatusFlag) {
      CustomerService.updateMarketingSettings(switchStatus)
        .then(() => {
          NotificationManager.success(
            "Settings updated successfully",
            "Success",
            3000
          );
          setSwitchStatusFlag(false);
        })
        .catch((error) => {
          NotificationManager.error(
            error?.message || "Failed to update settings",
            "Error",
            3000
          );
          setSwitchStatusFlag(false);
        });
    }
  }, [switchStatusFlag, switchStatus]);

  const handleChange = (e) => {
    setData((info) => ({ ...info, [e.target.name]: e.target.value }));
  };

  const onSwitchChange = (type, checked) => {
    setSwitchStatus((prevStatus) => ({
      ...prevStatus,
      [type]: checked,
    }));
  };

  const onSave = () => {
    setLoading(true);
    CustomerService.bybestUpdateProfile(data)
      .then(() => {
        NotificationManager.success(
          "Profile Updated Successfully",
          "Success",
          3000
        );
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        NotificationManager.error(
          error?.message || "Something went wrong!",
          "Error",
          3000
        );
      });
  };

  return (
    <div className="flex flex-1 flex-col gap-5 ">
      <div className="flex flex-col gap-5">
        <div className="bg-white flex flex-col rounded-lg">
          <div className="flex flex-col gap-1 p-6 border-b-[1px] border-[#EAECF0]">
            <CustomerHeader
              title={customerText("personalInfo")}
              description={customerText("personalInfoDescription")}
              showItems={false}
            />
          </div>
          <div className="grid lg:grid-cols-2 grid-cols-1 gap-5">
            <GeneralInfo
              data={data}
              handleChange={handleChange}
              containerClassNames="!border-0"
            />
            <Address
              data={data}
              handleChange={handleChange}
              containerClassNames="!border-0"
            />
          </div>
          <div className="p-6 border-t-[1px] border-[#EAECF0]">
            <CustomerFooterButtons
              buttonColor={"#6DDFE2"}
              onSave={onSave}
              loading={loading}
            />
          </div>
        </div>
      </div>
      <CommunicationPreferences
        switchStatus={switchStatus}
        onSwitchChange={onSwitchChange}
        title={customerText("marketingCommunication")}
        description={customerText("marketingCommunicationDescription")}
        theme={{
          phoneIconBgColor: "#6DDFE2",
          inboxIconBgColor: "#6DDFE2",
          switchClassName: "Acc-switch",
        }}
        handleMarketingSettingsSave={handleMarketingSettingsSave}
        type="booking"
      />
      <CommunicationPreferences
        switchStatus={switchStatus}
        onSwitchChange={onSwitchChange}
        title={customerText("promotionCommunication")}
        description={customerText("promotionCommunicationDescription")}
        theme={{
          phoneIconBgColor: "#6DDFE2",
          inboxIconBgColor: "#6DDFE2",
          switchClassName: "Acc-switch",
        }}
        handleMarketingSettingsSave={handleMarketingSettingsSave}
        type="promotion"
      />
    </div>
  );
};

export default AccPersonalInformation;
