import React from "react";
import { useState } from "react";
import PromotionSummary from "./promotion-summary";
import CustomCalendar from "./custom-calendar";
import "./index.css";
import { customerText } from "../../../components/Seo/whitelabel/bybest/bybest-customer-layout";

const PromotionCalendar = ({
  promoClassName,
  promoCalendarClassName,
  dataSource = [],
}) => {
  const [bookingItem, setBookingItem] = useState(null);

  const onSetSummary = (booking) => {
    setBookingItem(booking);
  };

  return (
    <div className="px-[15px] w-full boxshadow border-[1px] border-[#EAECF0] rounded-[8px] bg-white flex flex-col justify-between">
      <div className="w-full">
        <CustomCalendar
          promoCalendarClassName={promoCalendarClassName}
          data={dataSource}
          onSetSummary={onSetSummary}
        />
      </div>

      <div className="flex flex-col">
        {bookingItem && (
          <PromotionSummary
            title={bookingItem?.title}
            description={`${bookingItem?.description} Expired on ${new Date(
              bookingItem?.end_time
            ).getDate()}/${
              new Date(bookingItem?.end_time).getMonth() + 1
            }/${new Date(bookingItem?.end_time).getFullYear()}`}
            promo={`${Number(
              bookingItem?.discounts?.[0]?.value
            )}% ${customerText("promoOff")}`}
            promoClassName={promoClassName}
          />
        )}

        <hr className="border-[1px] border-[#E5E7EB]" />

        {/* <PromotionSummary
          title="bbvrtc707hv"
          description={`24.57% ${customerText("expiresOn")} 12/08/2024`}
          promo={`20% ${customerText("promoOff")}`}
          promoClassName={promoClassName}
        /> */}
      </div>
    </div>
  );
};

export default PromotionCalendar;
