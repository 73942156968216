import { getAccommodationText } from "..";

const AccommodationLocation = ({ accId, location, address }) => {
  return (
    <div className="w-full mx-auto">
      <h2 className="text-[#2D3748] text-24 md:text-[32px] font-bold leading-[120%] mb-2">
        {getAccommodationText("locationHeader")}
      </h2>
      <p className="text-[#6E706E] text-[16px]">
        {accId !== "OAS9218APPU" && (
          <>{getAccommodationText("locationDescription")}</>
        )}

        {accId === "OAS9218APPU" && (
          <>
            This is the location of the property. You can also take a virtual
            tour of the property by clicking the button below. It boasts a prime
            location, just a stone's throw away from the bustling main avenues
            and the serene Lana River, making it an ideal setting for both urban
            exploration and tranquil retreats. With its tree-lined streets and a
            diverse array of shops, restaurants, and cultural attractions
            nearby, West Blaine Street offers the perfect blend of convenience
            and leisure.{" "}
          </>
        )}
      </p>
    </div>
  );
};

export default AccommodationLocation;
