import React from "react";
import {isEmpty} from "../../utils/common";
import Tooltip from "@mui/material/Tooltip";

const FormTextInput = ({
  name,
  error,
  value,
  type = "text",
  disabled = false,
  enableLabel = true,
  placeholder,
  className,
  inputClassName,
  onChange,
  required = false,
  tooltipText = "",
}) => {
  return (
    <div className={`w-full ${className}`}>
      {enableLabel && (
        <div className={"flex"}>
          <Tooltip
            arrow
            placement='top'
            title={tooltipText ? tooltipText : ""}
            sx={{fontSize: "12px"}}
          >
            <p className='text-16 text-[#232323]'>{placeholder}</p>
          </Tooltip>
          {required && (
            <span style={{color: "#F44336", marginLeft: "2px"}}>*</span>
          )}
        </div>
      )}
      <input
        type={type}
        name={name}
        disabled={disabled}
        className={`w-full bg-white h-[48px] border-[1px] font-medium mt-2 mb-0 rounded-[5px] border-[#CBD5E1] focus:border-[1px] outline-none focus:border-blue-400 py-0 px-2 ${inputClassName} ${
          !enableLabel && "px-5"
        }`}
        placeholder={!enableLabel && placeholder}
        value={value}
        onChange={e => onChange(e)}
      />
      {!isEmpty(error) && (
        <div className={"text-red-600 text-12 mt-2"}>{error}</div>
      )}
    </div>
  );
};

export default FormTextInput;
