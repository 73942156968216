import { KEYS, getStorageKey, setStorageKey } from "../../utils/storage";
import { ADD_TO_CART_BILLING_DETAILS, SET_CART, CLEAR_CHECKOUT_FORM, REMOVE_FROM_CART_BILLING_DETAILS, REMOVE_FROM_CART, ADD_TO_CART_COUPON, REMOVE_FROM_CART_COUPON, SET_CART_SHIPPING_METHOD, SET_ORDER_SUCCESS_DATA, CLEAR_ORDER_SUCCESS_DATA } from "./types";
import { API_RETAIL, API_WHITE_LABEL } from "../../constants/apiConfig";
import apiFactory from "../../utils/apiFactory";
import WhiteLabelService from "../../services/whiteLabelService";

// actions/cartActions.js
export const loadCartItems = () => (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
        let items = getStorageKey(KEYS.CART_PRODUCTS) ?? [];
        let venueKey = getStorageKey(KEYS.CART_VENUE_KEY) ?? {};
        let cartCoupon = getStorageKey(KEYS.CART_COUPON) ?? {};
        let orderSuccessData = getStorageKey(KEYS.ORDER_SUCCESS_DATA)
        dispatch({
            type: SET_CART,
            payload: { products: items, venue_app_key: venueKey?.venue_app_key, venueName: venueKey?.venueName, currency: venueKey?.currency, venueLogo: venueKey.venueLogo, deliveryFee: venueKey.deliveryFee, discount: venueKey.discount },
        });
        dispatch({
            type: ADD_TO_CART_COUPON,
            payload: cartCoupon
        });

        dispatch({
            type: SET_ORDER_SUCCESS_DATA,
            payload: orderSuccessData
        });

        resolve(items)
    });
}
export const addToCart = (product, venue_app_key, venueName, currency, venueLogo = '', deliveryFee = 0, discount = {}) => (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
    product.quantity = product.quantity ?? 1;
    let products = getStorageKey(KEYS.CART_PRODUCTS);
    let venueKey = getStorageKey(KEYS.CART_VENUE_KEY);
    if (venueKey?.venue_app_key) {
        if ((venueKey?.venue_app_key == venue_app_key) && products) {
            if (products.some(item => item.id == product.id)) {
                let index = products.findIndex(item => item.id == product.id);
                if (index != -1) {
                    products[index].quantity += product.quantity ?? 1;
                } else {
                    products.push(product);
                }
                dispatch({
                    type: SET_CART,
                    payload: { products, venue_app_key, currency, venueName: venueName, venueLogo: venueLogo, deliveryFee: deliveryFee, discount: discount },
                });
                setStorageKey(KEYS.CART_PRODUCTS, products);
            } else {
                products.push(product);
                dispatch({
                    type: SET_CART,
                    payload: { products: products, venue_app_key: venue_app_key, currency: currency, venueName: venueName, venueLogo: venueLogo, deliveryFee: deliveryFee, discount: discount },
                });
                setStorageKey(KEYS.CART_PRODUCTS, products);
            }
        } else {
            dispatch({
                type: SET_CART,
                payload: { products: products, venue_app_key: venue_app_key, currency: currency, venueName: venueName, venueLogo: venueLogo, deliveryFee: deliveryFee, discount: discount },
            });
            setStorageKey(KEYS.CART_PRODUCTS, [product]);
            setStorageKey(KEYS.CART_VENUE_KEY, { venue_app_key: venue_app_key, currency: currency, venueName: venueName, venueLogo: venueLogo, deliveryFee: deliveryFee, discount: discount });
        }
    } else {
        dispatch({
            type: SET_CART,
            payload: { products: products, venue_app_key: venue_app_key, currency: currency, venueName: venueName, venueLogo: venueLogo, deliveryFee: deliveryFee, discount: discount },
        });
        setStorageKey(KEYS.CART_PRODUCTS, [product]);
        setStorageKey(KEYS.CART_VENUE_KEY, { venue_app_key: venue_app_key, currency: currency, venueName: venueName, venueLogo: venueLogo, deliveryFee: deliveryFee, discount: discount });
    }
    resolve()
});

};

export const updateCartProductQuantity = (product, venue_app_key, venueName, currency, venueLogo, deliveryFee = 0, discount = {}) => (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
    product.quantity = product.quantity ?? 1;
    let products = getStorageKey(KEYS.CART_PRODUCTS);
    let venueKey = getStorageKey(KEYS.CART_VENUE_KEY);
        if ((venueKey?.venue_app_key == venue_app_key) && products) {
            if (products.some(item => item.id == product.id)) {
                let index = products.findIndex(item => item.id == product.id);
                if (index != -1) {
                    products[index].quantity = product.quantity ?? 1;
                } else {
                    products.push(product);
                }
                dispatch({
                    type: SET_CART,
                    payload: { products, venue_app_key, currency, venueName: venueName, venueLogo: venueLogo, deliveryFee: deliveryFee, discount: discount },
                });
                setStorageKey(KEYS.CART_PRODUCTS, products);
            } else {
                products.push(product);
                dispatch({
                    type: SET_CART,
                    payload: { products: products, venue_app_key: venue_app_key, currency: currency, venueName: venueName, venueLogo: venueLogo, deliveryFee: deliveryFee, discount: discount },
                });
                setStorageKey(KEYS.CART_PRODUCTS, products);
            }
        } else {
            dispatch({
                type: SET_CART,
                payload: { products: products, venue_app_key: venue_app_key, currency: currency, venueName: venueName, venueLogo: venueLogo, deliveryFee: deliveryFee, discount: discount },
            });
            setStorageKey(KEYS.CART_PRODUCTS, [product]);
            setStorageKey(KEYS.CART_VENUE_KEY, { venue_app_key: venue_app_key, currency: currency, venueName: venueName, venueLogo: venueLogo, deliveryFee: deliveryFee, discount: discount });
        }
    resolve()
});

};

export const updateCart = (products, venue_app_key, venueName, currency, venueLogo, deliveryFee = 0, discount = {}) => (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
        dispatch({
            type: SET_CART,
            payload: { products: products, venue_app_key: venue_app_key, currency: currency, venueName: venueName, venueLogo: venueLogo, deliveryFee: deliveryFee, discount: discount },
        });
        setStorageKey(KEYS.CART_PRODUCTS, products);
        setStorageKey(KEYS.CART_VENUE_KEY, { venue_app_key: venue_app_key, currency: currency, venueName: venueName, venueLogo: venueLogo, deliveryFee: deliveryFee, discount: discount });
        resolve()
    });
};

//   export const removeFromCart = (product) => {
//     return {
//       type: REMOVE_FROM_CART,
//       payload: product,
//     };
//   };

export const removeFromCart = (product) => (dispatch) => {
    return new Promise(async (resolve, reject) => {
        let products = getStorageKey(KEYS.CART_PRODUCTS);
        if (products) {
            let newProducts = products.filter((item) => item.id !== product.id);
            setStorageKey(KEYS.CART_PRODUCTS, newProducts);
            dispatch({
                type: REMOVE_FROM_CART,
                payload: product
            });
        }
        resolve()

    });
};


export const verifyCoupon = (payload) => (dispatch) => {
    return new Promise(async (resolve, reject) => {
        let pay = {};
        let venueKey = getStorageKey(KEYS.CART_VENUE_KEY);
        let cartCoupon = getStorageKey(KEYS.CART_COUPON);
        if (payload) {
            pay = payload;
        } else if (cartCoupon) {
            pay = cartCoupon;
        }
        apiFactory({ ...API_RETAIL, path: (API_RETAIL.path + `/validate-coupon`), method: 'POST', query: `venue_app_key=${venueKey.venue_app_key}` }, pay)
            .then(async (data) => {
                dispatch({
                    type: ADD_TO_CART_COUPON,
                    payload: {
                        ...pay,
                        ...data
                    }
                });
                setStorageKey(KEYS.CART_COUPON, {
                    ...pay,
                    ...data
                });
                resolve({
                    ...pay,
                    ...data
                });

            })
            .catch(error => {
                dispatch({
                    type: REMOVE_FROM_CART_COUPON,
                    payload: null
                });
                setStorageKey(KEYS.CART_COUPON, null);
                reject(error);
            })
    });
};

export const verifyRestaurantCoupon = (payload) => (dispatch) => {
    return new Promise(async (resolve, reject) => {
        let pay = {};
        let venueKey = getStorageKey(KEYS.CART_VENUE_KEY);
        let cartCoupon = getStorageKey(KEYS.CART_COUPON);
        if (payload) {
            pay = payload;
        } else if (cartCoupon) {
            pay = cartCoupon;
        }
        apiFactory({ ...API_WHITE_LABEL, path: (API_WHITE_LABEL.path + `/restaurant/validate-coupon`), method: 'POST', query: `venue_app_key=${venueKey.venue_app_key}` }, pay)
            .then(async (data) => {
                dispatch({
                    type: ADD_TO_CART_COUPON,
                    payload: {
                        ...pay,
                        ...data
                    }
                });
                setStorageKey(KEYS.CART_COUPON, {
                    ...pay,
                    ...data
                });
                resolve({
                    ...pay,
                    ...data
                });

            })
            .catch(error => {
                dispatch({
                    type: REMOVE_FROM_CART_COUPON,
                    payload: null
                });
                setStorageKey(KEYS.CART_COUPON, null);
                reject(error);
            })
    });
};


export const addCartBillingDetails = (payload) => {
    return {
        type: ADD_TO_CART_BILLING_DETAILS,
        payload: payload,
    };
};

export const removeFromCartBillingDetails = () => {
    return {
        type: REMOVE_FROM_CART_BILLING_DETAILS,
        payload: null,
    };
};

export const addOrderSucessDetails = (payload)  => (dispatch) => {
    return new Promise(async (resolve, reject) => {

        dispatch({
            type: SET_ORDER_SUCCESS_DATA,
            payload: payload
        });
        setStorageKey(KEYS.ORDER_SUCCESS_DATA, payload);
        resolve(payload);

    })
};

export const removeOrderSucessDetails = () => (dispatch) => {
    return new Promise(async (resolve, reject) => {
        dispatch({
            type: SET_ORDER_SUCCESS_DATA,
            payload: null
        });
       
        setStorageKey(KEYS.ORDER_SUCCESS_DATA, null);
        resolve()
    });
};

export const setCartShippingMethod = (payload) => {
    return {
        type: SET_CART_SHIPPING_METHOD,
        payload: payload,
    };
};

export const clearCheckoutForm = () => (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
        dispatch({
            type: CLEAR_CHECKOUT_FORM,
            payload: null
        });
        dispatch({
            type: REMOVE_FROM_CART_COUPON,
            payload: null
        });
        setStorageKey(KEYS.CART_PRODUCTS, null);
        setStorageKey(KEYS.CART_VENUE_KEY, null);
        setStorageKey(KEYS.CART_COUPON, null);
    });
};