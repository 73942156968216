/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import CustomerHeader from "../../customer/customer-header";
import { customerText } from "../../../components/Seo/whitelabel/bybest/bybest-customer-layout";
import GenericTable from "../../../components/Table/generic-table";
import { NotificationManager } from "react-notifications";
import { t } from "i18next";
import CustomerService from "../../../services/customer-service";
import { useGetPromotionsData } from "./data";
import { initialPagination } from ".";

const UsedPromotions = () => {
  const { usedPromotionsColumns } = useGetPromotionsData();

  const [promotionParam, setPromotionParams] = useState(initialPagination);
  const [dataSource, setDataSource] = useState({});

  const handlePageChange = (response) => {
    setPromotionParams((prev) => ({
      ...prev,
      current:
        typeof response === "number" ? response : response?.current_page ?? 1,
      ...response,
    }));
  };

  const query = useMemo(() => {
    return {
      per_page: initialPagination.pageSize,
      page: promotionParam.current,
      type: "used",
    };
  }, [promotionParam?.current]);

  useEffect(() => {
    CustomerService.getPromotions(query)
      .then((response) => {
        setDataSource(response);
        if (response) {
          handlePageChange(response);
        }
      })
      .catch((error) => {
        NotificationManager.error(
          error?.message || t("contact_us.somethingWentWrong"),
          customerText("error"),
          3000
        );
      });
  }, [query]);

  return (
    <div className="col-span-2 w-full border rounded-[12px]">
      <div className="flex justify-between items-center w-full p-6">
        <CustomerHeader
          showHome={false}
          numberOfItems={dataSource?.used_promotions?.total}
          items={customerText("promotions")}
          title={customerText("usedPromotions")}
        />
      </div>
      <GenericTable
        columns={usedPromotionsColumns}
        data={dataSource?.data || []}
        className="w-full md:block pr-4"
        pagination={{
          ...promotionParam,
          onChange: handlePageChange,
        }}
        isBackendPaginated
        setParams={setPromotionParams}
      />
    </div>
  );
};

export default UsedPromotions;
