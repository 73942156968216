import { CircularProgress } from "@mui/material";
import React, { useState } from "react";
import {
  MSG_TYPE_IMG,
  MSG_TYPE_TEXT,
} from "../../../../components/Messages/react-web-gifted-chat/Constant";
import { customerText } from "../../../../components/Seo/whitelabel/bybest/bybest-customer-layout";
import ActionBtns from "../action-btns";
import "../index.css";
import EmojiPickerModal from "./emoji-picker";
import { ImgItem } from "./ImgItem";

const FooterChatSection = ({ loading, onSendMsg }) => {
  const [image, setImage] = useState(null);
  const [emoji, setEmoji] = useState(null);
  const [text, setText] = useState("");

  const handleInputChange = (event) => setText(event.target.value);

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && text.trim()) {
      handleSendMessage();
    }
  };

  const handleSendMessage = () => {
    if (image != null) {
      onSendMsg({ type: MSG_TYPE_IMG, content: image });
    } else if (Boolean(text)) {
      onSendMsg({ type: MSG_TYPE_TEXT, content: text });
    }
    setText("");
    setEmoji(null);
    setImage(null);
  };

  return (
    <div className="w-full bg-white shadow-md p-2">
      {image != null && (
        <div className="flex items-start w-min mb-3">
          <ImgItem
            image={image}
            classes=" w-[120px] h-[120px] max-w-[100%] bg-gray-300"
            onCancel={() => setImage(null)}
          />
        </div>
      )}
      <div className="flex items-center w-full">
        <ActionBtns onImageSelected={(item) => setImage(item)} />
        <div className="flex-grow relative mx-2">
          <input
            value={text}
            onChange={handleInputChange}
            onKeyPress={handleKeyPress}
            placeholder="Type a message"
            className="rounded-[12px] border outline-none w-full h-12 px-5"
          />

          <EmojiPickerModal
            onSelect={(item) => {
              console.log(item, "emoji item");
              setEmoji(item.emoji);
              setText((text) => text + item.emoji);
            }}
          />
        </div>
        <div className="flex items-center md:w-48">
          <button
            className={`rounded-lg w-full font-bold bg-[#6DDFE2] hover:bg-[#6DDFE2] flex h-12 justify-center items-center text-white px-4 py-2 ${
              text.length === 0 && !emoji && !image
                ? "cursor-not-allowed opacity-60"
                : ""
            }`}
            onClick={handleSendMessage}
            disabled={text.length === 0 && !emoji && !image}
          >
            {loading ? <CircularProgress size={16} /> : customerText("send")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default FooterChatSection;
