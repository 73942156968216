import React from "react";
import FormText from "../../../components/FormText";
import accomodationImg from "../../../assets/images/accomodation-2.jpg";
import { SelectApartment } from "./select-apartment";
import { getAccommodationText } from "..";

const GenericAccommodationHeader = ({
  TagsButtonData,
  data,
  title,
  selectApartments,
}) => {
  return (
    <div
      className="md:bg-gray2 bg-white relative flex flex-col mt-[80px] lg:min-h-[86vh] justify-center"
      style={{
        backgroundImage: `url(${accomodationImg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="absolute inset-0 bg-black opacity-50"></div>
      <div className="flex items-center justify-center md:gap-10 py-10 w-full overflow-hidden flex-col">
        <div className="flex flex-col justify-start z-10 items-center lg:w-full w-[90%]">
          <FormText
            title={title}
            type="title-whitelabel"
            customClass="mb-3 !text-white !font-medium text-[38px] lg:!text-[64px] max-w-[1100px] lg:!leading-[70px] leading-[48px]"
            customStyle={{ textAlign: "center" }}
          />
          <p className="text-[#DCDCDC] lg:text-[20px] lg text-[16px] font-semibold text-center" style={{width: '80%'}}>
            {getAccommodationText("luxuryLiving")}
          </p>
          <div
            className="hidden md:flex flex-row flex-wrap mt-4"
            style={{ overflowY: "auto", maxWidth: "100%" }}
          >
            {data?.additional_information?.main_tag && (
              <div
                className={`bg-white px-3 md:px-4 py-1 md:py-2 my-3 cursor-pointer`}
              >
                <span
                  className={`md:text-base text-sm`}
                  style={{
                    color: TagsButtonData?.bgColor
                      ? TagsButtonData?.bgColor
                      : "rgb(141 90 187)",
                    textWrap: "nowrap",
                  }}
                >
                  <b>#{data?.additional_information?.main_tag}</b>
                </span>
              </div>
            )}
            {data?.additional_information?.tags?.map((item, index) => (
              <div
                key={index}
                className={`bg-white px-3 md:px-4 py-1 md:py-2 my-3 cursor-pointer`}
              >
                <span
                  className={`md:text-base text-sm`}
                  style={{
                    color: TagsButtonData?.bgColor
                      ? TagsButtonData?.bgColor
                      : "rgb(141 90 187)",
                    textWrap: "nowrap",
                  }}
                >
                  <b>#{item}</b>
                </span>
              </div>
            ))}
          </div>

          <div
            className="md:hidden flex flex-row flex-wrap"
            style={{ overflowY: "auto", maxWidth: "100%" }}
          >
            {data?.additional_information?.main_tag && (
              <div
                className={`bg-white px-3 md:px-3 py-1 md:py-2 my-3 cursor-pointer`}
              >
                <span
                  className={`md:text-base text-sm`}
                  style={{
                    color: TagsButtonData?.bgColor
                      ? TagsButtonData?.bgColor
                      : "rgb(141 90 187)",
                    textWrap: "nowrap",
                  }}
                >
                  <b>#{data?.additional_information?.main_tag}</b>
                </span>
              </div>
            )}
            {data?.additional_information?.tags?.map((item, index) => (
              <div
                key={index}
                className={`bg-white px-3 md:px-3 py-1 md:py-2 my-3 cursor-pointer`}
              >
                <span
                  className={`md:text-base text-sm`}
                  style={{
                    color: TagsButtonData?.bgColor
                      ? TagsButtonData?.bgColor
                      : "rgb(141 90 187)",
                    textWrap: "nowrap",
                  }}
                >
                  <b>#{item}</b>
                </span>
              </div>
            ))}
          </div>
        </div>

        {selectApartments && <SelectApartment />}
      </div>
    </div>
  );
};

export default GenericAccommodationHeader;
