import React from "react";

import GenericAccommodationHeader from "../../venue-accommodation/accommodation-header/generic-acc-header";
import { getAccommodationText } from "../../venue-accommodation";

const AboutHeader = ({ TagsButtonData, data }) => {
  return (
    <GenericAccommodationHeader
      {...{
        TagsButtonData,
        data,
        title: getAccommodationText("about"),
      }}
    />
  );
};

export default AboutHeader;
