import React from "react";
import { Switch } from "antd";
import PhoneIcon from "../../../assets/svgs/phone.svg";
import InboxIcon from "../../../assets/svgs/inbox.svg";
import CustomerHeader from "../customer-header";
import { customerText } from "../../../components/Seo/whitelabel/bybest/bybest-customer-layout";

const CommunicationPreferences = ({
  switchStatus = {
    promotion_sms_notify: false,
    promotion_email_notify: false,
    booking_sms_notify: false,
    booking_email_notify: false,
    flag: true,
  },
  onSwitchChange,
  title = "",
  description = "",
  theme = {
    phoneIconBgColor: "#cb0000",
    inboxIconBgColor: "#cb0000",
    switchClassName: "PI-switch",
  },
  type = "",
  handleMarketingSettingsSave,
}) => {
  return (
    <div className="bg-white w-full boxshadow border-[1px] border-[#EAECF0] rounded-lg flex flex-col pr-4">
      <div className="p-6">
        <CustomerHeader
          {...{ title, description }}
          showItems={false}
          showHome={false}
        />
      </div>
      <hr className="border-[1px] hidden md:block border-[#E5E7EB] " />
      {/* SMS Notification Settings */}
      <div className="flex flex-col md:flex-row gap-4 p-6">
        <div
          className="w-[48px] h-[48px] p-[10px] align-middle rounded-[4px]"
          style={{ backgroundColor: theme.phoneIconBgColor }}
        >
          <img alt="" src={PhoneIcon} />
        </div>
        <div className="flex justify-between flex-col flex-1">
          <p className="text-[#101828] text-14 md:text-20 font-semibold leading-5">
            {customerText("smsNotifications")}
            <span className="ml-2 text-[#240b3b] text-14 md:text-[16px] font-medium bg-[#F9F5FF] rounded-[16px] px-2 py-[2px]">
              0 {customerText("smsReceived")}
            </span>
          </p>
          <p className="text-[#667085] text-14 font-medium leading-5">
            {customerText("smsDescription")}
          </p>
        </div>
        <div className="flex items-center gap-2">
          <p className="text-[#240b3b] text-14 font-medium leading-5">
            {switchStatus[`${type}_sms_notify`]
              ? customerText("on")
              : customerText("off")}
          </p>
          <Switch
            checked={switchStatus[`${type}_sms_notify`]}
            onChange={(checked) => {
              onSwitchChange(`${type}_sms_notify`, checked);
              handleMarketingSettingsSave(true);
            }}
            className={theme.switchClassName}
          />
        </div>
      </div>
      <hr className="border-[1px] hidden md:block border-[#E5E7EB] mx-6" />
      {/* Email Notification Settings */}
      <div className="flex flex-col md:flex-row gap-4 p-6">
        <div
          className="w-[48px] h-[48px] p-[10px] align-middle rounded-[4px]"
          style={{ backgroundColor: theme.inboxIconBgColor }}
        >
          <img alt="" src={InboxIcon} />
        </div>
        <div className="flex justify-between flex-col flex-1">
          <p className="text-[#101828] text-14 md:text-20 font-semibold leading-5 ">
            {customerText("emailNotifications")}
            <span className="ml-2 text-[#240b3b] text-14 md:text-[16px] font-medium bg-[#F9F5FF] rounded-[16px] px-2 py-[2px]">
              0 {customerText("emailReceived")}
            </span>
          </p>
          <p className="text-[#667085] text-14 font-medium leading-5">
            {customerText("emailDescription")}
          </p>
        </div>
        <div className="flex items-center gap-2">
          <p className="text-[#240b3b] text-14 font-medium leading-5">
            {switchStatus[`${type}_email_notify`]
              ? customerText("on")
              : customerText("off")}
          </p>
          <Switch
            checked={switchStatus[`${type}_email_notify`]}
            onChange={(checked) => {
              handleMarketingSettingsSave(true);
              onSwitchChange(`${type}_email_notify`, checked);
            }}
            className={theme.switchClassName}
          />
        </div>
      </div>
      <hr className="border-[1px] hidden md:block border-[#E5E7EB] " />
    </div>
  );
};

export default CommunicationPreferences;
