import moment from "moment";
import { getStatus } from "../../../components/columns/get-status";
import { customerText } from "../../../components/Seo/whitelabel/bybest/bybest-customer-layout";

export const walletColumns = [
  {
    title: customerText("type"),
    dataIndex: "type",
    key: "type",
    render: (type) => (
      <p className="text-14 text-[#667085] leading-5 font-medium">
        {type ? type.charAt(0).toUpperCase() + type.slice(1) : ""}
      </p>
    ),
  },
  {
    title: customerText("date"),
    dataIndex: "updatedAt",
    key: "updatedAt",
    render: (date) => (
      <p className="text-14 text-[#667085] leading-5 font-medium">
        {moment(date).format("Do MMMM YYYY, h:mm A")}
      </p>
    ),
  },
  {
    title: customerText("points"),
    dataIndex: "amount",
    key: "amount",
    render: (points) => (
      <p className="text-14 text-[#240b3b] leading-5 font-bold">{points}</p>
    ),
  },
  {
    title: customerText("description"),
    dataIndex: "description",
    key: "description",
    render: (value) => (
      <p className="text-14 text-[#667085] leading-5 font-medium">{value}</p>
    ),
  },
  {
    title: customerText("status"),
    dataIndex: "status",
    key: "status",
    render: getStatus,
  },
];

export const referralsColumns = [
  {
    title: customerText("type"),
    dataIndex: "type",
    key: "type",
    render: (value) => (
      <p className="text-14 text-[#667085] leading-5 font-medium">{value}</p>
    ),
  },
  {
    title: customerText("dateJoined"),
    dataIndex: "dateJoined",
    key: "dateJoined",
    render: (value) => (
      <p className="text-14 text-[#667085] leading-5 font-medium">{value}</p>
    ),
  },
  {
    title: customerText("status"),
    dataIndex: "status",
    key: "status",
    render: getStatus,
  },
];

export const referralsData = [
  {
    key: "1",
    type: "John Doe",
    dateJoined: "15/10/2023 16:00 PM",
    status: customerText("joined"),
  },
  {
    key: "2",
    type: "Nico Merris",
    dateJoined: "15/10/2023 16:00 PM",
    status: customerText("joined"),
  },
  {
    key: "3",
    type: "John Doe",
    dateJoined: "15/10/2023 16:00 PM",
    status: customerText("pending"),
  },
  {
    key: "1",
    type: "John Doe",
    dateJoined: "15/10/2023 16:00 PM",
    status: customerText("joined"),
  },
  {
    key: "2",
    type: "Nico Merris",
    dateJoined: "15/10/2023 16:00 PM",
    status: customerText("joined"),
  },
  {
    key: "3",
    type: "John Doe",
    dateJoined: "15/10/2023 16:00 PM",
    status: customerText("pending"),
  },
  {
    key: "1",
    type: "John Doe",
    dateJoined: "15/10/2023 16:00 PM",
    status: customerText("joined"),
  },
  {
    key: "2",
    type: "Nico Merris",
    dateJoined: "15/10/2023 16:00 PM",
    status: customerText("joined"),
  },
  {
    key: "3",
    type: "John Doe",
    dateJoined: "15/10/2023 16:00 PM",
    status: customerText("pending"),
  },
  {
    key: "1",
    type: "John Doe",
    dateJoined: "15/10/2023 16:00 PM",
    status: customerText("joined"),
  },
  {
    key: "2",
    type: "Nico Merris",
    dateJoined: "15/10/2023 16:00 PM",
    status: customerText("joined"),
  },
  {
    key: "3",
    type: "John Doe",
    dateJoined: "15/10/2023 16:00 PM",
    status: customerText("pending"),
  },
  {
    key: "11",
    type: "John Doe",
    dateJoined: "15/10/2023 16:00 PM",
    status: customerText("joined"),
  },
  {
    key: "22",
    type: "Nico Merris",
    dateJoined: "15/10/2023 16:00 PM",
    status: customerText("joined"),
  },
];
