export const starIcon = (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.7606 1.9188C12.0751 1.28574 12.9782 1.28574 13.2927 1.9188L15.8616 7.08897C15.9861 7.33966 16.2254 7.51355 16.5024 7.55454L22.2133 8.39996C22.9126 8.50348 23.1916 9.36235 22.6868 9.85712L18.5634 13.8979C18.3635 14.0938 18.2721 14.3752 18.3187 14.6512L19.2794 20.3439C19.397 21.0409 18.6664 21.5717 18.0399 21.2444L12.9227 18.5716C12.6746 18.442 12.3787 18.442 12.1306 18.5716L7.01346 21.2444C6.38689 21.5717 5.65629 21.0409 5.77393 20.3439L6.73466 14.6512C6.78124 14.3752 6.68983 14.0938 6.48989 13.8979L2.36658 9.85712C1.8617 9.36235 2.14076 8.50348 2.84004 8.39997L8.55097 7.55454C8.82788 7.51355 9.06722 7.33966 9.19178 7.08897L11.7606 1.9188Z"
      fill="#FEA500"
    />
  </svg>
);
