import { SET_VENUE_DATA } from '../actions/types';

const initialState = {
};

const venue = (state = initialState, action) => {
    switch (action.type) {
        case SET_VENUE_DATA:
            return { ...state, ...action.payload  };
        default:
            return state;
    }
};

export default venue;
