import React from 'react'

const FormText = ({type = '', customClass = '', customStyle = '', title}) => {
    const styleClass = () => {
        switch (type) {
            case 'page-title':
                return 'color-dark md:text-6xl text-4xl font-semibold'
            case 'title':
                return 'color-dark text-4xl font-semibold'
            case 'title-whitelabel':
                return 'color-dark  md:text-5xl text-3xl font-semibold'
            case 'pretitle':
                return 'color-dark text-3xl font-semibold'
            case 'pretitle-bold':
                return 'color-dark text-2xl md:text-3xl font-bold'
            case 'itemtitle':
                return 'color-dark text-2xl font-semibold'
            case 'itemtitle-normal':
                return 'color-dark text-2xl'
            case 'itemtitle-sub':
                return 'color-dark text-xl font-semibold'
            case 'itemtitle-sub-normal':
                return 'color-dark md:text-xl text-sm'
            case 'itemtitle-sub-s':
                return 'color-dark md:text-xl text-based font-semibold'
            case 'itemtitle-small':
                return 'color-dark text-base md:text-lg font-semibold'
            case 'itemtitle-bold':
                return 'color-dark text-lg font-bold'
            case 'subtitle-dark':
                return 'color-dark text-base font-normal'
            case 'subtitle-dark-s':
                return 'color-dark md:text-base text-sm font-normal'
            case 'subtitle':
                return 'color-gray text-base md:text-lg font-normal'
            case 'subtitle-based':
                return 'color-gray text-base font-normal'
            case 'subtitle-based-s':
                return 'color-gray md:text-base text-sm font-semibold'
            case 'subtitle-gray':
                return 'text-slate-600 font-normal'
            case 'subtitle-link':
                return 'text-primary1 text-base font-normal'
            case 'normal':
                return 'color-dark text-lg font-normal'
            case 'custom':
                return ''
            case 'itemtitle-whitelabel':
                return 'color-dark text-m font-semibold'
            default:
                return 'color-dark text-lg font-normal'
        }
    }
    
    return (
        <span
            className={`
                ${styleClass()}
                ${customClass}
            `}
            style={{...customStyle}}
        >
            {title}
        </span>
    )
}

export default FormText
