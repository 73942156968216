import React, { useEffect, useState } from 'react';

import FormText from '../FormText';

import FormTextInput from '../FormTextInput';
import FormSelect from '../FormSelect';
import { SubmitBtn } from '../FormBtn';

import calendarIcon from '../../assets/svgs/calendar_ano.svg';
import timeIcon from '../../assets/svgs/time_ano.svg';
import userIcon from '../../assets/svgs/user.svg';
import { Alert } from 'antd';
import confirmed_success from '../../assets/svgs/confirmed_success.svg';

const ReservationForm = (props) => {
  const {
    data = {},
    success = false,
    onBack = () => { },
    onSubmit = () => { }
  } = props;

  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [occasion, setOccasion] = useState('');
  const [additional, setAdditional] = useState('');
  const [holdingTime, setHoldingTime] = useState(300);

  useEffect(() => {
    setTimeout(() => {
      setHoldingTime(v => (v - 1))
    }, 1000)
  }, [])

  useEffect(() => {
    if (holdingTime > 0) {
      setTimeout(() => {
        setHoldingTime(v => (v - 1))
      }, 1000)
    }
  }, [holdingTime])

  const onSubmitForm = () => {
    const data ={
      name,
      lastName,
      phone,
      email,
      occasion,
      additional
    }
    onSubmit(data);
  }

  return (
    <div className="max-w-screen-main mx-auto px-4 sm:px-6 md:px-8 lg:px-16 w-full my-8">
      {success == false ? <div className='flex flex-col  border rounded-xl px-6 md:px-12 py-6 md:py-8'>
        <span className='mb-2' onClick={() => onBack()}>Back</span>
        <FormText title="Reservation details" type="itemtitle-normal" customClass='hidden md:block' />
        <div className='flex flex-row items-center my-5'>
          <div className='flex flex-row items-center me-4 md:me-6'>
            <img src={calendarIcon} alt='icon' className='w-4 h-4 md:w-6 md:h-6' />
            <FormText
              title={data?.date}
              type='itemtitle-sub-normal'
              customClass='ms-2 md:ms-4'
            />
          </div>
          <div className='flex flex-row items-center me-4 md:me-6'>
            <img src={timeIcon} alt='icon' className='w-4 h-4 md:w-6 md:h-6' />
            <FormText
              title={data?.time}
              type='itemtitle-sub-normal'
              customClass='ms-2 md:ms-4'
            />
          </div>
          <div className='flex flex-row items-center me-4 md:me-6'>
            <img src={userIcon} alt='icon' className='w-4 h-4 md:w-6 md:h-6' />
            <FormText
              title={data?.people}
              type='itemtitle-sub-normal'
              customClass='ms-2 md:ms-4'
            />
          </div>
        </div>
        <FormText
          title={
            holdingTime > 0 ?
            `We’re holding this table for you for ${Math.floor(holdingTime/60)}:${holdingTime%60} minutes`
            :
            "You can still try to complete your reservation, but this venue space may no longer be available."
          }
          type='custom'
          customClass='bg-red1 color-dark px-6 py-2 rounded-lg'
        />
        <div className='grid md:grid-cols-2 grid-cols-1 gap-8 mt-6'>
          <FormTextInput
            name="first_name"
            enableLabel={false}
            placeholder="First Name"
            onChange={e => setName(e.target.value)}
          />
          <FormTextInput
            name="last_name"
            enableLabel={false}
            placeholder="Last Name"
            onChange={e => setLastName(e.target.value)}

          />
          <FormTextInput
            name="phone"
            enableLabel={false}
            placeholder="Phone Number"
            onChange={e => setPhone(e.target.value)}
          />
          <FormTextInput
            name="email"
            enableLabel={false}
            placeholder="Email"
            onChange={e => setEmail(e.target.value)}

          />
          {/* <FormSelect
            name="occasion"
            placeholder="Why are you contacting?"
            enableLabel={false}
            dataList={[{ value: 'select', title: 'Select an occasion' }]}
            onChange={e => setOccasion(e.target.value)}

          /> */}
          <FormTextInput
            name="other"
            enableLabel={false}
            placeholder="Add a special request (optional)"
            onChange={e => setAdditional(e.target.value)}

          />
        </div>
        <SubmitBtn
          title="Complete reservation"
          classes="mt-8 py-5"
          onAction={() => {
            onSubmitForm(true)
          }}
          dark={true}
        />
      </div> :
        <div className='flex flex-col justify-center border border-[#E0E0E0] rounded-[13px] border-[1px]'>
          <div className='flex flex-col w-full justify-center items-center'>
            <img src={confirmed_success} alt='icon' className='w-[100px] h-[100px] py-4' />
            <p className='text-black1 text-[16px] font-bold'>Order booked successfully</p>
            <div className='flex flex-row justify-center items-center flex_wrap gap-8 mt-4 mb-4'>
              <div className='flex flex-row justify-center items-center gap-8'>
              <p className='text-black1 font-bold'>Date: <span className='text-gray1'>{data?.date}</span></p>
              <p className='text-black1 font-bold'>Guest Name: <span className='text-gray1'>{name}</span></p>
                </div>
              <p className='text-black1 font-bold'>Nr of people: <span className='text-gray1'>{data?.people}</span></p>
            </div>
            {/* ECFDF3 */}
            <div className='flex flex-col justify-center items-center text-[13px] text-cyan1 bg-[#ECFDF3] border-[#ECFDF3] border align-center rounded-[10px] px-12 py-4 mb-4'>
              <div className="flex justify-center items-center text-[12px] font-normal text-black1">
                You will get an email with the confirmation and any notes from the restaurant
              </div>
            </div>
            {/* <Alert type="success" className='min-h-[90px]' message="You will get an email with the confirmation and any notes from the restaurant"></Alert> */}
          </div>
        </div>}
    </div>
  )
}

export default ReservationForm
