import { customerText } from "../Seo/whitelabel/bybest/bybest-customer-layout";
import {
  docListIcon,
  personalInfoIcon,
  promotionIcon,
  securityIcon,
  shopIcon,
  walletIcon,
  wishlistIcon,
} from "./icons";

export const links = (selected) => [
  {
    href: "/",
    icon: shopIcon(selected === "shop"),
    label: customerText("sidebar.shop"),
    key: "shop",
  },
  {
    href: "/personal-information",
    icon: personalInfoIcon(selected === "personal-information"),
    label: customerText("sidebar.personalInfo"),
    key: "personal-information",
  },
  {
    href: "/orders",
    icon: docListIcon(selected === "orders"),
    label: customerText("sidebar.orders"),

    key: "orders",
  },
  {
    href: "/wallet",
    icon: walletIcon(selected === "wallet"),
    label: customerText("sidebar.wallet"),
    key: "wallet",
  },
  {
    href: "/promotions",
    icon: promotionIcon(selected === "promotions"),
    label: customerText("sidebar.promotions"),
    key: "promotions",
  },
  {
    href: "/wishlist",
    icon: wishlistIcon(selected === "wishlist"),
    label: customerText("sidebar.wishlist"),
    key: "wishlist",
  },
  {
    href: "/login-security",
    icon: securityIcon(selected === "login-security"),
    label: customerText("sidebar.security"),
    key: "login-security",
  },
];

export const getAccommodationLinks = (selected) => {
  return [
    {
      href: "/",
      icon: shopIcon(selected === "home"),
      label: customerText("sidebar.home"),
      key: "home",
    },
    {
      href: "/personal-info",
      icon: personalInfoIcon(selected === "personal-info"),
      label: customerText("sidebar.personalInfo"),
      key: "personal-info",
    },
    {
      href: "/bookings",
      icon: docListIcon(selected === "bookings"),
      label: customerText("sidebar.bookings"),
      key: "bookings",
    },
    {
      href: "/wallet",
      icon: walletIcon(selected === "wallet"),
      label: customerText("sidebar.wallet"),
      key: "wallet",
    },
    {
      href: "/promotions",
      icon: promotionIcon(selected === "promotions"),
      label: customerText("sidebar.promotions"),
      key: "promotions",
    },
    {
      href: "/login-security",
      icon: securityIcon(selected === "login-security"),
      label: customerText("sidebar.security"),
      key: "login-security",
    },
  ];
};

function extractAppKey(pathname = "") {
  const segments = pathname?.split("/");
  return segments[2];
}
export const appKey = extractAppKey(window.location.pathname);
