import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { customerText } from "../../../components/Seo/whitelabel/bybest/bybest-customer-layout";
import CustomerHeader from "../../customer/customer-header";
import { BookingsData } from "./bookings-data";
import { API_BOOST_CORE_URL } from "../../../constants/config";

const AccBookings = () => {
  const navigate = useNavigate();
  const [bookingsData, setBookingsData] = useState(null);
  const [filteredBookingsData, setFilteredBookingsData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState(null);

  const end_user_api_key = "boost-sn-25010xC0R5-enduser!";

  const [filterValue, setFilterValue] = useState("Active");

  const goToBookingDetails = (row) => {
    if (row) {
      navigate(`${row.id}`, {
        state: row,
      });
    }
  };

  const filterButtons = [
    { label: customerText("active"), value: "Active" },
    { label: customerText("completed"), value: "Completed" },
    { label: customerText("cancelled"), value: "Cancelled" },
  ];

  const fetchBookings = async () => {
    const token = localStorage.getItem("accToken");
    try {
      setIsLoading(true);
      setIsError(false);
      setError(null);
      const res = await fetch(
        `${API_BOOST_CORE_URL}end-user/bookings?SN_BOOST_CORE_ENDUSER_API_KEY=${end_user_api_key}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await res.json();
      setBookingsData(data);
      setFilteredBookingsData(data);
    } catch (error) {
      setIsError(true);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchBookings();
  }, []);

  return (
    <div className="bg-white w-full boxshadow border-[1px] border-[#EAECF0] rounded-lg">
      <div className="relative flex flex-col w-full p-6">
        <div className="col-span-9">
          <CustomerHeader
            description={customerText("view_and_Manage_booking_history")}
            numberOfItems={bookingsData?.Unknown?.total}
            items={customerText("bookingsSm")}
            title={customerText("bookings")}
          />
        </div>
        <div className="flex gap-3 mt-4">
          {filterButtons.map((item) => (
            <div
              key={item.value}
              onClick={() => {
                setFilterValue(item.value);
              }}
              className={`cursor-pointer px-4 py-2 text-sm rounded-md border-[1px] border-[#D0D5DD] ${
                filterValue === item.value
                  ? "!border-[#6DDFE2] bg-[#6DDFE236]"
                  : ""
              } `}
            >
              {item.label}
            </div>
          ))}
        </div>
        {isLoading ? (
          <p>Loading</p>
        ) : isError ? (
          <p>{error.message}</p>
        ) : bookingsData !== null ? (
          <BookingsData
            bookingsData={filteredBookingsData}
            onClick={goToBookingDetails}
            filterValue={filterValue}
          />
        ) : null}
      </div>
    </div>
  );
};

export default AccBookings;
