// mixpanelUtil.js

import mixpanel from 'mixpanel-browser';
import {getDeviceUniqueId} from "./storage";

export const initializeMixpanel = () => {
    const token = '2653f4432b0f2db3f4cc418b82d3464b';
    mixpanel.init(token, { debug: true, track_pageview: true, persistence: 'localStorage' });
};

export const identifyVenue = (venueAppKey) => {
    mixpanel.identify(venueAppKey);
};

export const identifyDevice = () => {
    mixpanel.identify(getDeviceUniqueId());
};

export const trackEvent = (eventName, properties) => {
    mixpanel.track(eventName, properties);
};