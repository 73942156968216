import React, { useRef } from "react";
import bedIcon from "../../../assets/svgs/bed.svg";
import roomIcon from "../../../assets/svgs/room.svg";
import { useSelector } from "react-redux";
import { getFacilityImageByValue } from "../../rental";
import Carousel from "react-multi-carousel";
import { motion } from "framer-motion";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";
import { getAccommodationText } from "..";

export const CarusellImages = ({
  isHome = true,
  btnLabel = getAccommodationText("enterDates"),
}) => {
  const navigate = useNavigate();
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    largeDesktop: {
      breakpoint: { max: 3000, min: 2050 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 2050, min: 1240 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1240, min: 768 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
    },
  };
  const venueData = useSelector((state) => state.venue);
  const rental_units = venueData?.additional_information?.rental_units ?? [];

  const carouselRef = useRef(null);

  const handlePrev = () => {
    if (carouselRef?.current) {
      carouselRef?.current?.previous();
    }
  };

  const handleNext = () => {
    if (carouselRef?.current) {
      carouselRef?.current?.next();
    }
  };

  function getPathAfterDomain(url) {
    const domain = "venueboost.io";
    const domainIndex = url.indexOf(domain);

    if (domainIndex === -1) {
      return null;
    }
    const startIndex = domainIndex + domain.length;

    return url.substring(startIndex);
  }

  return (
    <div className="flex flex-col gap-2 md:gap-10 items-center w-full">
      <Carousel
        ref={carouselRef}
        responsive={responsive}
        spaceBetween={5}
        infinite={false}
        autoPlay={false}
        showDots={false}
        partialVisible={false}
        arrows={false}
        swipeable={true}
        draggable={true}
        keyBoardControl={true}
        containerClass="pt-6 w-full"
      >
        {rental_units.map((item, index) => {
          const beds = (item?.beds ?? [])
            .join(", ")
            .replace(/,([^,]*)$/, " &$1");
          return (
            <motion.div
              key={index}
              className="pb-2 mr-5 rounded-[16px] border border-[#E7E7E7]"
            >
              <div className="relative">
                <img
                  src={item?.photo_path}
                  alt=""
                  style={{
                    minHeight: "258px",
                    maxHeight: "258px",
                    borderTopLeftRadius: "16px",
                    borderTopRightRadius: "16px",
                  }}
                  className="w-full lg:object-cover object-cover md:object-contain "
                />
                {isHome && (
                  <div className="absolute top-5 left-5">
                    <span className="text-[#000] bg-[#E7C873] rounded-[100px] px-3 py-2 text-14 font-bold uppercase">
                      {getAccommodationText("Featured")}
                    </span>
                  </div>
                )}
              </div>
              <div className="h-full flex flex-col p-2 gap-4">
                <div className="flex flex-col justify-between gap-2 px-2 pt-2 flex-1">
                  <span className="font-bold text-24 text-[#0F1015]">
                    {item?.name}
                  </span>
                  {isHome && (
                    <div className="flex flex-col gap-3">
                      <div className="flex flex-row items-center gap-3">
                        <img src={roomIcon} alt="icon" className="w-5" />
                        <span className="text-16 font-normal">
                          {item?.space_txt}: {item?.space}
                        </span>
                      </div>
                      {item?.facility && (
                        <div className="flex flex-row items-center gap-3">
                          <img
                            src={getFacilityImageByValue(item?.facility)}
                            alt="icon"
                            className="w-5"
                          />
                          <span className="text-16 font-normal">
                            {item?.facility}
                          </span>
                        </div>
                      )}
                      {item.beds && item.beds.length > 0 && (
                        <div className="flex flex-row items-center gap-3">
                          <img src={bedIcon} alt="icon" className="w-5" />
                          <span className="text-16 font-normal">{beds}</span>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className="border-[#A4A6AC33] border"></div>
                <a
                  // href={item?.url}
                  onClick={() => navigate(getPathAfterDomain(item?.url))}
                  className="h-10 cursor-pointer bg-[#6DDFE2] text-white text-center text-sm rounded-lg p-2"
                >
                  {btnLabel}
                </a>
              </div>
            </motion.div>
          );
        })}
      </Carousel>

      <div className="flex items-center gap-8">
        <div
          className="w-11 h-11 min-w-[44px] bg-white rounded-full items-center justify-center border cursor-pointer flex"
          onClick={handlePrev}
        >
          <svg
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.94444 15.4444L3.5 9.99999M3.5 9.99999L8.94444 4.55554M3.5 9.99999L17.5 9.99999"
              stroke="#171717"
              strokeWidth="1.67"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>

        <div
          className="w-11 h-11 min-w-[44px] bg-white rounded-full items-center justify-center border cursor-pointer flex"
          onClick={handleNext}
        >
          <svg
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.0556 4.55554L17.5 9.99999M17.5 9.99999L12.0556 15.4444M17.5 9.99999L3.5 9.99999"
              stroke="#171717"
              strokeWidth="1.67"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};
