import React from "react";
import rentalIcon from "../../../assets/svgs/rental.svg";
import { getAccommodationText } from "..";

const openVR = () => {
  window.open(
    "https://teliportme.com/virtualtour/7ad3a639",
    "_blank",
    "location=yes"
  );
};

const AccommodationInformation = ({
  accId,
  vRlink,
  // openGoogleMaps, // Uncomment if you want to use this prop
}) => {
  const isTirana = accId !== "OAS9218APPU";

  const gettingAround = isTirana ? (
    <>{getAccommodationText("gettingAround")}</>
  ) : (
    <>{getAccommodationText("gettinAroundNoTirana")}</>
  );

  return (
    <div className="w-full mx-auto">
      <h2 className="text-[#2D3748] text-24 md:text-[32px] font-bold leading-[120%] mb-2">
        {getAccommodationText("gettingAroundTitle")}
      </h2>
      <p className="text-[#6E706E] text-[16px]">{gettingAround}</p>
      {/* <div className="flex flex-row gap-4 mt-6">
          <img src={rentalIcon} alt="icon" width="32" />
          <div className="flex flex-col">
            <span className="text-lg font-semibold text-black1">Rental Type</span>
            <span className="text-[#646464]">Hotel and B&B</span>
          </div>
        </div> */}
      {/*  <span*/}
      {/*      className="text-[14px] leading-5 font-medium text-[#240b3b] cursor-pointer mt-4"*/}
      {/*      // onClick={() => openGoogleMaps()}*/}
      {/*  >*/}
      {/*  /!*todo: redirect to google map with lat long and should be good*!/*/}
      {/*    Open in Google Maps {`>`}*/}
      {/*</span>*/}
      {/* {vRlink && isTirana && (
            <div className="mt-2">
          <span
              className="text-[14px] leading-5 font-medium text-[#240b3b] cursor-pointer"
              onClick={openVR}
          >
            Virtual Tour {`>`}
          </span>
            </div>
        )} */}
    </div>
  );
};

export default AccommodationInformation;
