export function transformBookingData(data) {
  return {
    key: data.rental_unit.id.toString(),
    apartment: {
      name: data.rental_unit?.name,
      image: data.rental_unit?.photo_url,
    },
    booked: formatDateRange(
      data.booking?.check_in_date,
      data.booking?.check_out_date
    ),
    paidOn: formatPaymentDate(data?.payment_date),
    price: `${data.currency ?? "€"}${parseFloat(data.total_amount)}`,
    status:
      data.payment_status === "paid"
        ? "paid"
        : data.payment_status === "not_paid"
        ? "not_paid"
        : "cancelled",
    prepayment_amount: data?.booking?.prepayment_amount,
    confirmation_code: data?.booking?.confirmation_code,
  };
}

export function formatDateRange(startDate, endDate) {
  const options = { day: "2-digit", month: "short", year: "numeric" };
  const start = new Date(startDate).toLocaleDateString("en-GB", options);
  const end = new Date(endDate).toLocaleDateString("en-GB", options);
  return `${start} - ${end}`;
}

export function formatPaymentDate(date) {
  const paymentDate = new Date(date);
  const options = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };
  return paymentDate.toLocaleDateString("en-GB", options);
}

export const formatDate = (date) => {
  const d = new Date(date);
  return d.toISOString().split("T")[0];
};
