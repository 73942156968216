import AddIcon from "@mui/icons-material/Add";
import React, { useState } from "react";
import cartImage from "../../../assets/images/payment-cards/VISA.png";
import { customerText } from "../../../components/Seo/whitelabel/bybest/bybest-customer-layout";
import ChoosePayment from "../../customer/wallet/choose-payment";
import { cashDeliveryIcon } from "../../customer/wallet/icons";
import AccAddCardModal from "./add-card-modal";

const PaymentMethod = () => {
  const [selectedPayment, setSelectedPayment] = useState(
    customerText("cashDelivery")
  );
  const [showCardModal, setShowCardModal] = useState(false);

  return (
    <div className="flex flex-col gap-4">
      <div className="text-[#101828] text-18 font-semibold leading-7">
        {customerText("paymentMethod")}
      </div>
      <div className="grid lg:flex items-center gap-4 cash-delivery">
        <ChoosePayment
          icon={cashDeliveryIcon}
          borderColor={
            selectedPayment === customerText("cashDelivery")
              ? "border-[#6DDFE2]"
              : "border-[#E3E8EF]"
          }
          badgeColor="#6DDFE2"
          description={customerText("default")}
          title={customerText("cashDelivery")}
          isSelected={selectedPayment === customerText("cashDelivery")}
          onSelect={setSelectedPayment}
        />
        <ChoosePayment
          icon={<img alt="visa" src={cartImage} className="max-w-[40px]" />}
          badgeColor="#6DDFE2"
          borderColor={
            selectedPayment === customerText("payWithCard")
              ? "border-[#6DDFE2]"
              : "border-[#E3E8EF]"
          }
          description={customerText("visaDescription")}
          title={customerText("payWithCard")}
          isSelected={selectedPayment === customerText("payWithCard")}
          onSelect={setSelectedPayment}
        />
        <div
          onClick={() => setShowCardModal(true)}
          className="h-20 max-sm:w-full cursor-pointer flex items-center justify-center md:aspect-square rounded-[12px] border-[1px] border-[#E3E8EF]"
        >
          <AddIcon />
        </div>
      </div>
      <AccAddCardModal
        restaurant_id={123}
        showModal={showCardModal}
        okButtonStyleProps={{
          backgroundColor: "#50B7ED",
          color: "white",
        }}
        onClose={() => setShowCardModal(false)}
      />
    </div>
  );
};

export default PaymentMethod;
