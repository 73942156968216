import React from "react";
import "./index.css";
import GoogleImage from "../../assets/images/google-review/google-img.png";
import { starIcon } from "./icons";

const GoogleReview = ({ className = "#cb0000 4px solid", rating = '4.0' }) => {
  const ratingValue = rating;

  const renderStars = (rating) => {
    const filledStars = Math.floor(Number(rating));
    const hasHalfStar = Number(rating) % 1 !== 0;

    const stars = [];

    for (let i = 0; i < filledStars; i++) {
      stars.push(<span key={`star-filled-${i}`}>{starIcon}</span>);
    }

    if (hasHalfStar) {
      stars.push(<span key="star-half">{starIcon}</span>);
    }

    return stars;
  };

  return (
    <div
      className={`google-review pr-4`}
      style={{
        borderTop: className,
      }}
    >
      <div className="google-review-child">
        <div className={`h-1 rounded-24 ${className}`} />
        <div className="flex items-center gap-5">
          <div>
            <img src={GoogleImage} alt="GoogleImage" />
          </div>
          <div className="right-section">
            <span className="title">Google Rating</span>
            <div className="stars flex items-center">
              <span className="value-number">{`${ratingValue}`}</span>
              <div className="star-icons flex items-center">
                {renderStars(ratingValue)}
              </div>
            </div>
            <span className="description">See all our reviews</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoogleReview;
