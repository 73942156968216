import React, { useEffect, useState } from "react";
import { customerText } from "../../../components/Seo/whitelabel/bybest/bybest-customer-layout";
import CustomerHeader from "../../customer/customer-header";
import "../../customer/wallet/index.css";
import WalletPoints from "../../customer/wallet/wallet-points";
import WalletTable from "../../customer/wallet/wallet-table";
import PaymentMethod from "./payment-method";
import PaymentsTable from "./payments-table";
import CustomerService from "../../../services/customer-service";

const AccWallet = () => {
  const [data, setData] = useState();

  const getData = (page) => {
    const query = `page=${page}`;
    CustomerService.getWalletInfo(query).then((res) => {
      setData(res?.wallet_info);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="flex flex-1 flex-col gap-5 ">
      <div className="bg-white w-full boxshadow border-[1px] border-[#EAECF0] rounded-lg mt-6">
        <div className="flex flex-col gap-1 p-6 border-b-[1px] border-[#EAECF0]">
          <CustomerHeader
            title={customerText("wallet")}
            description={customerText("view_and_manage_payment_transactions")}
            showItems={false}
          />
        </div>

        <div className="grid lg:grid-cols-3 grid-cols-1">
          <div className="col-span-2 border p-2 px-6">
            <PaymentMethod />
          </div>
          <WalletPoints
            iconClassName="!bg-[#6DDFE2]"
            showTier={false}
            data={data}
          />
        </div>
      </div>
      <WalletTable tableData={data?.walletActivities} />

      <PaymentsTable />
    </div>
  );
};

export default AccWallet;
