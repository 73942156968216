const SubmitBtn = (props) => {
  const {
    title,
    classes='',
    textClasses='',
    dark=false,
    onAction=()=>{}
  } = props

  return (
    <div
      className={`${dark ? 'bg-primary1' : 'bg-primary2'} w-full text-center py-3 rounded-lg cursor-pointer ${classes}`}
      onClick={() => onAction()}
    >
      <span className={`text-white font-bold ${textClasses}`}>{title}</span>
    </div>
  )
}

export default SubmitBtn;
