import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FormText from "../../../components/FormText";
import RentalCalendar from "./rental-calendar";
import RentalModal from "./rental-modal";

const RentalForm = ({
  header,
  guestList,
  onSetDate = () => { },
  bookingDate,
  pricePerNight,
  setprice,
  currency,
  rentalId,
}) => {
  const [checkIn, setCheckIn] = useState(null);
  const [checkOut, setCheckOut] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [modalCheckIn, setModalCheckIn] = useState(null);
  const [modalCheckOut, setModalCheckOut] = useState(null);
  const [show, setShow] = useState(true);
  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const [selectedGuests, setSelectedGuests] = useState({
    value: null,
    label: null,
  });
  const [days, setDays] = useState("");
  const [price, setPrice] = useState("");
  const [total, setTotal] = useState("");
  const [discount, setDiscount] = useState(null);
  const [discountValue, setDiscountValue] = useState(0);
  const navigate = useNavigate();

  const isDateInRange = (current) => {
    const today = moment().startOf("day");
    return bookingDate.some((dateRange) => {
      const start = moment(dateRange.start_date, "YYYY-MM-DD")
        .startOf("day")
        .toDate();
      const end = moment(dateRange.end_date, "YYYY-MM-DD")
        .endOf("day")
        .toDate();
      return (current >= start && current <= end) || current < today;
    });
  };

  useEffect(() => {
    if (checkIn && checkOut) {
      onSetDate(true);
    } else {
      onSetDate(false);
    }
  }, [checkIn, checkOut, onSetDate]);

  const handleAction = () => {
    setOpenModal(true);
    setShow(true);
  };

  const handleCloseModal = () => {
    setShow(false);
    setTimeout(() => setOpenModal(false), 100);
  };

  const handleSaveModal = () => {
    setSelectedDateRange({
      startDate: modalCheckIn,
      endDate: modalCheckOut,
    });
    setCheckIn(modalCheckIn);
    setCheckOut(modalCheckOut);
    handleCloseModal();
  };

  const CheckDateDifference = () => {
    const startDate = new Date(selectedDateRange.startDate);
    const endDate = new Date(selectedDateRange.endDate);
    const differenceInMilliseconds = endDate - startDate;
    const differenceInDays = Math.floor(
      differenceInMilliseconds / (24 * 60 * 60 * 1000)
    );
    return differenceInDays;
  };

  const getPriceValue = (guestNumber) => {
    return pricePerNight.find((item) => item.nr_guests === guestNumber);
  };

  useEffect(() => {
    if (selectedGuests !== null) {
      var discountValue = 0;
      let DaysRange = CheckDateDifference();
      setDays(DaysRange);
      const data = getPriceValue(selectedGuests?.value);
      setPrice(data?.price);
      setTotal(data?.price * CheckDateDifference());
      setprice(data?.price);
      setDiscount(header?.discount?.value ?? 0);

      if (header?.discount?.type === 'percentage') {
        discountValue = (total * (header?.discount?.value / 100)).toFixed(2);
      } else if (header?.discount?.type === 'fixed') {
        discountValue = header?.discount?.value ?? 0;
      } else {
        discountValue = 0;
      }
      setDiscountValue(discountValue);
    }
    // eslint-disable-next-line
  }, [selectedGuests, discount, days]);

  const formattedCheckInDate = checkIn?.format("YYYY-MM-DD");
  const formattedCheckOutDate = checkOut?.format("YYYY-MM-DD");

  const handleNavigate = () => {
    navigate("/rental/reserve", {
      state: {
        price: price,
        days: days,
        total: total,
        discount: discount,
        discountValue: discountValue,
        guestNo: selectedGuests.value,
        geustId: selectedGuests.label,
        checkInDate: formattedCheckInDate,
        checkOutDate: formattedCheckOutDate,
        pricePerNight: pricePerNight,
        guestList: guestList,
        selectedGuests: selectedGuests,
        currency: currency,
        modalCheckIn: modalCheckIn,
        modalCheckOut: modalCheckOut,
        rentalId,
        rentalName: header?.name,
        rentalImg: header?.gallery[0]?.photo_path,
        discount_id: header?.discount?.id,
        bookingDate: bookingDate,
        header: header,
      },
    });
  };
  return (
    <div>
      <div className="flex md:flex-row flex-col">
        <div className="w-full md:w-[70%] text-[#2D3748] text-16 font-normal leading-[150%]  md:pr-[49px]">
          <p className="">{header?.about}</p>
          <p className="font-bold mt-6 mb-2">The space</p>
          <p>{header?.about_space}</p>
          <p className="font-bold mt-6 mb-2">Guest access</p>
          <p>{header?.about_guest_access}</p>
        </div>
        <div className="w-full flex flex-col md:w-[30%] ">
          {selectedGuests.value && (
            <div className="block md:hidden border-[#E2E8F0] border-[1px] rounded-[5px] p-2 w-full mt-4">
              {checkIn && checkOut ? (
                <div className="flex flex-row items-end gap-2">
                  <FormText
                    title={`€ ${parseInt(price)}`}
                    type="itemtitle-sub-s"
                    customClass="!font-bold !text-[22px]"
                  />
                  <FormText title="night" customClass="!text-base mb-0.5" />
                </div>
              ) : (
                <FormText
                  title="Add dates for prices"
                  customClass="!text-xl !font-bold"
                />
              )}
            </div>
          )}
          <RentalCalendar
            {...{
              checkIn,
              checkOut,
              handleAction,
              isDateInRange,
              selectedGuests,
              setSelectedGuests,
              guestList,
            }}
          />
          <div className="flex w-full mt-6">
            <button
              disabled={!checkIn || !checkOut || !selectedGuests.value}
              onClick={checkIn && checkOut ? handleNavigate : handleAction}
              className="flex flex-1 rounded-[6px] bg-[#6DDFE2] text-white py-[10px] align-middle text-18 font-semibold leading-7"
            >
              {checkIn && checkOut ? "Book now" : "Check availability"}
            </button>
          </div>

          {selectedGuests.value && (
            <div
              className={`${checkIn && checkOut ? "flex" : "hidden"
                } flex-col gap-4 mt-4`}
            >
              <FormText
                title="You won't be charged yet"
                customClass="!text-sm text-center !font-medium"
              />
              <div className="flex flex-col pb-5 border-b gap-2">
                <div className="flex flex-row justify-between">
                  <FormText
                    title={`${currency} ${parseInt(price)} x ${days}`}
                    type="subtitle-link"
                  />
                  <FormText
                    title={`${currency} ${parseInt(total)}`}
                    type="subtitle-link"
                  />
                </div>
                <div className="flex flex-row justify-between">
                  <FormText
                    title={`Discount`}
                    type="subtitle-link"
                  />
                  <FormText
                    title={`${currency} ${discountValue}`}
                    type="subtitle-link"
                  />
                </div>
              </div>
              <div className="flex flex-row justify-between gap-4">
                <FormText
                  title="Total"
                  type="subtitle-link"
                  customClass="!font-bold"
                />
                <FormText
                  title={`${currency} ${total - discountValue}`}
                  type="subtitle-link"
                  customClass="!font-bold"
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <RentalModal
        {...{
          handleCloseModal,
          handleSaveModal,
          isDateInRange,
          modalCheckIn,
          modalCheckOut,
          openModal,
          setModalCheckIn,
          setModalCheckOut,
          show,
        }}
      />
    </div>
  );
};

export default RentalForm;
