import React from "react";
import { Route, Routes } from "react-router-dom";
import RouteNames from "../../constants/route_names.js";
import AboutAp from "../../views/about-ap/index.js";
import NotFound from "../../views/notFound/index.js";
import RentalReserve from "../../views/rental-reserve";
import AccommodationRentalProfile from "../../views/rental/index.js";
import AccommodationContact from "../../views/venue-accommodation/accommodation-contact/index.js";
import VenueAccommodation from "../../views/venue-accommodation/index.js";
import AccommodationLayout from "../layouts/AccommodationLayout.js";
import BrandLayout from "../layouts/BrandLayout.js";
import byBestAccommodationRoutes from "./byBestAccommodation-routes.jsx";
import AccLogin from "../../views/accommodation-customer/login/index.jsx";
import ScrollToTop from "../../components/Hooks/scrolltoTop.js";
import BookingSuccessScreen from "../../views/rental-reserve/booking-success";

const AppRoutes = () => {
  return (
    <React.Fragment>
      <ScrollToTop />
      <Routes>
        <Route path="" element={<BrandLayout />}>
          <Route exact path={""} element={<VenueAccommodation />} />
          <Route exact path={"/about"} element={<AboutAp />} />
          <Route exact path={"/contact"} element={<AccommodationContact />} />
          <Route exact path={"login"} element={<AccLogin />} />,
        </Route>

        <Route path="/accommodation/*" element={<AccommodationLayout />}>
          {byBestAccommodationRoutes}
        </Route>

        <Route path="rental" element={<BrandLayout />}>
          <Route exact path=":code" element={<AccommodationRentalProfile />} />
          <Route exact path="reserve" element={<RentalReserve />} />
          <Route
              exact
              path={"booking-success"}
              element={<BookingSuccessScreen />}
          />
        </Route>

        <Route exact path={RouteNames.not_found} element={<NotFound />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </React.Fragment>
  );
};

export default AppRoutes;
