import React from "react";

import GenericAccommodationHeader from "./generic-acc-header";
import { getAccommodationText } from "..";

const AccommodationHeader = ({ TagsButtonData, data }) => {
  return (
    <GenericAccommodationHeader
      {...{
        TagsButtonData,
        data,
        selectApartments: true,
      }}
      title={getAccommodationText("discoverComfort")}
    />
  );
};

export default AccommodationHeader;
