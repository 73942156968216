import React from "react";
import { getAccommodationText } from "..";
import Heart from "../../../assets/images/heart.svg";

const blockData = [
  {
    titleKey: "virtualTour",
    textKey: "beforeYouArrive",
  },
  {
    titleKey: "exclusivePromotions",
    textKey: "specialOffers",
  },
  {
    titleKey: "loyaltyTitle",
    textKey: "loyaltyProgram",
  },
  {
    titleKey: "complimentaryWifiTitle",
    textKey: "complimentaryWifi",
  },
  {
    titleKey: "localExperiencesTitle",
    textKey: "localExperiences",
  },
  {
    titleKey: "flexibleCheckInOutTitle",
    textKey: "flexibleCheckInOut",
  },
];

const VirtualTourBlocks = () => {
  const openVR = () => {
    window.open(
      "https://teliportme.com/virtualtour/7ad3a639",
      "_blank",
      "location=yes"
    );
  };
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8">
      {blockData.map(({ titleKey, textKey }, index) => (
        <div
          key={index}
          className="relative rounded-[6px] bg-[#F9F9F9] p-5 text-center flex flex-col gap-5 items-center"
        >
          <div className="flex justify-between gap-2">
            <img src={Heart} alt="icon" />
            {/* {index === 0 && (
              <div
                className="absolute right-5 cursor-pointer flex flex-col items-end gap-2"
                onClick={openVR}
              >
                <div className="author-section">
                  <span className="underline author text-[16px] text-normal">
                    {getAccommodationText("virtualTourLabel")}
                  </span>
                </div>
              </div>
            )} */}
          </div>
          <span className="text text-bold text-[24px]">
            {getAccommodationText(titleKey)}
          </span>
          <div className="author-section">
            <p className="author text-[16px] text-normal">
              {getAccommodationText(textKey)}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default VirtualTourBlocks;
