import {
  SET_ACCOMMODATIONLANGUAGE,
  SET_LANGUAGE,
  SET_SHOW_TOP_BTN,
} from "../actions/types";

const initialState = {
  language: "en",
  showTopBtn: false,
  bybestLanguage: "al",
  accommodationLanguage: "al",
};

const app = (state = initialState, action) => {
  switch (action.type) {
    case SET_LANGUAGE:
    case SET_ACCOMMODATIONLANGUAGE:
      return { ...state, language: action.payload || "al" };
    case SET_SHOW_TOP_BTN:
      return { ...state, showTopBtn: action.payload || false };
    default:
      return state;
  }
};

export default app;
