import * as yup from "yup";

export const validator = yup.object().shape({
  first_name: yup
    .string()
    .trim()
    .required("First name is required")
    .max(72, "Max exceeded")
    .min(2, "Min not met"),
  last_name: yup
    .string()
    .trim()
    .required("Last name is required")
    .max(72, "Max exceeded")
    .min(2, "Min not met"),
  email: yup
    .string()
    .trim()
    .required("Email is required")
    .max(72, "Max exceeded")
    .min(5, "Min not met")
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Invalid email"
    ),
  phone: yup.string().trim().required("Phone number is required"),

  state: yup.string().trim().required("State is required"),
  address_line1: yup
    .string()
    .trim()
    .required("Address Line 1 is required")
    .max(250, "Max exceeded")
    .min(6, "Min not met"),
  address_line2: yup.string().trim(),
  city: yup.string().required("City is required"),
  postcode: yup.string().required("Postal code is required"),
  company_name: yup.string(),
  additional_notes: yup.string(),
});
