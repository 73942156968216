import { ADD_TO_CART_BILLING_DETAILS, SET_CART, CLEAR_CHECKOUT_FORM, REMOVE_FROM_CART_BILLING_DETAILS, REMOVE_FROM_CART, ADD_TO_CART_COUPON, REMOVE_FROM_CART_COUPON, SET_CART_SHIPPING_METHOD, CLEAR_ORDER_SUCCESS_DATA, SET_ORDER_SUCCESS_DATA, } from '../actions/types';

// reducers/cartReducer.js
const initialState = {
    products: [],
    billing_detail: null,
    venue_app_key: null,
    coupon_detail: null,
    currency: null,
    shippingMethod: null,
    venueName: null,
    venueLogo: null,
    deliveryFee: null,
    discount: null,
    orderSucessData: null,
};

const app = (state = initialState, action) => {
    switch (action.type) {
        case SET_CART:
            return {
                ...state,
                products: action.payload.products,
                venue_app_key: action.payload.venue_app_key,
                currency: action.payload.currency,
                venueName: action.payload.venueName,
                venueLogo: action.payload.venueLogo,
                deliveryFee: action.payload.deliveryFee,
                discount: action.payload.discount,
            };
        case SET_CART_SHIPPING_METHOD:
            return {
                ...state,
                shippingMethod: action.payload,
            };
        case REMOVE_FROM_CART:
            return {
                ...state,
                products: state.products.filter((item) => item.id !== action.payload.id),
            };
        case ADD_TO_CART_BILLING_DETAILS:
            return {
                ...state,
                billing_detail: action.payload,
            };
        case REMOVE_FROM_CART_BILLING_DETAILS:
            return {
                ...state,
                billing_detail: null,
            };
        case ADD_TO_CART_COUPON:
            return {
                ...state,
                coupon_detail: action.payload,
            };
        case REMOVE_FROM_CART_COUPON:
            return {
                ...state,
                coupon_detail: null,
            };
        case SET_ORDER_SUCCESS_DATA:
            return {
                ...state,
                orderSucessData: action.payload,
            };
        case CLEAR_ORDER_SUCCESS_DATA:
            return {
                ...state,
                orderSucessData: null,
            };
        case CLEAR_CHECKOUT_FORM:
            return {
                ...state,
                billing_detail: null,
                venue_app_key: null,
                coupon_detail: null,
                currency: null,
                shippingMethod: null,
                venueName: null,
                venueLogo: null,
                deliveryFee: null,
                products: [],
                discount: null
            };
        default:
            return state;
    }
};

export default app;
