import { CloseOutlined } from "@mui/icons-material";
import { IconButton } from "@mui/material";

export const ImgItem = ({
  image,
  classes = "",
  backgroundColor = "",
  onCancel,
}) => {
  const _handleImageCancel = (e) => {
    e.stopPropagation();
    if (onCancel) {
      onCancel(null);
    }
  };

  return (
    <div
      className={`flex flex-col cursor-pointer justify-center items-center relative border border-gray-300 rounded-lg h-full ${classes}`}
      style={{ backgroundColor: backgroundColor }}
    >
      <div className="w-full h-full">
        <img
          className=" h-full object-cover rounded-lg"
          src={image.url}
          alt="preview"
        />
      </div>
      {image != null && (
        <IconButton
          style={{
            position: "absolute",
            width: 22,
            height: 22,
            right: 4,
            top: 4,
            backgroundColor: "#fff",
            border: "1px solid #000",
          }}
          onClick={_handleImageCancel}
        >
          <CloseOutlined
            style={{ color: "#000", width: "18px", height: "18px" }}
          />
        </IconButton>
      )}
    </div>
  );
};
