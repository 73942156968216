import React, { useEffect } from "react";
import { BsArrowUp } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { setShowTopBtn } from "../../redux/actions/app";
import "./index.css";

const ScrollToTopButton = () => {
  const dispatch = useDispatch();
  const showTopBtn = useSelector((state) => state.app.showTopBtn);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 500) {
        dispatch(setShowTopBtn(true));
      } else {
        dispatch(setShowTopBtn(false));
      }
    });
    // eslint-disable-next-line
  }, []);

  const goToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className=" sm:hidden relative z-999">
      {showTopBtn === true && (
        <div
          className="rounded-[50%] fixed bottom-[85px] right-8 z-[1000]"
          onClick={() => goToTop()}
        >
          <div className="bg-[#6DDFE2] h-[58px] w-[58px] rounded-[50%] flex justify-center items-center">
            <BsArrowUp className="text-white text-24" />
          </div>
        </div>
      )}
    </div>
  );
};

export default ScrollToTopButton;
