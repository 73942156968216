import { Person } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { changeLanguage } from "../../redux/actions/app";
import { isEmpty } from "../../utils/common";
import { KEYS, getStorageKey } from "../../utils/storage";
import { getAccommodationText } from "../../views/venue-accommodation";
import WhiteLabelService from "../../services/whiteLabelService";
import { ACCOMMODATION_ID } from "../../components/Messages/react-web-gifted-chat/Constant";

const VenueBrandHeader = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const language = useSelector((state) => state.app.language);
  const { venueLogo } = useSelector((state) => state.cart);
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [venueImage, setVenueImage] = useState();

  const venueDataLS = localStorage.getItem("venueData");
  const venueSelector = useSelector((state) => state.venue);
  const userData = getStorageKey(KEYS.USER_DATA);

  const getDetails = () =>
    WhiteLabelService.getDetails(ACCOMMODATION_ID).then((res) => {
      setVenueImage(res?.venue);
    });

  const venueData =
    Object.keys(venueSelector).length > 0
      ? venueSelector
      : JSON.parse(venueDataLS);

  useEffect(() => {
    if (!venueLogo && !venueData) {
      getDetails(ACCOMMODATION_ID);
    }
  }, [venueLogo, venueData]);

  const isRoute = (path = "contact") => pathname.includes(path);

  const redirectTo = (url = "shop") => navigate(`/${url}`);

  const handleLanguageChange = (event) => {
    const newLanguage = event.target.value;
    dispatch(changeLanguage(newLanguage));
    window.location.reload();
  };

  const languageComponent = (
    <li>
      <div className="menu-header">
        <div className="left-side flex items-center bg-white justify-center w-full">
          <select
            className="choose-lang cursor-pointer border-none outline-none"
            value={language}
            onChange={handleLanguageChange}
          >
            <option value="al">AL</option>
            <option value="en">EN</option>
          </select>
        </div>
      </div>
    </li>
  );

  return (
    <header
      aria-label="Site Header"
      className={`bg-white sticky w-full z-[1000] header`}
      style={{ boxShadow: "0px 1px 2px 0px #00000040" }}
    >
      <div className="px-4 lg:pl-[10%] lg:pr-[5%]">
        <div className={`flex h-20 items-center justify-between`}>
          <div className="flex md:items-center md:gap-12 cursor-pointer">
            {(!isEmpty(venueData?.overview?.logo) ||
              !isEmpty(venueImage?.overview?.logo) ||
              !isEmpty(venueLogo)) && (
              <div
                className="text-teal-600 flex items-center  gap-4 logo"
                onClick={() => redirectTo("")}
              >
                <img
                  alt=""
                  src={
                    venueLogo
                      ? venueLogo
                      : venueData
                      ? venueData?.overview?.logo
                      : venueImage?.overview?.logo
                  }
                  style={{ height: "56px" }}
                />
              </div>
            )}
          </div>
          <div className="">
            <div className="lg:hidden flex items-center">
              {userData?.name && (
                <li className="flex  w-full items-center text-16 lg:h-20 align-middle  font-medium text-[#666A73] transition gap-4 cursor-pointer px-4 pr-2 hover:text-black1/75">
                  <p
                    onClick={() =>
                      navigate("/accommodation/customer/personal-info")
                    }
                    style={{ textWrap: "nowrap" }}
                    className="w-full flex items-center text-16 font-medium align-middle text-nowrap"
                  >
                    <span className="mr-2">
                      <Person />
                    </span>

                    {userData.name}
                  </p>
                </li>
              )}
              <button
                data-collapse-toggle="navbar-default"
                type="button"
                className="inline-flex items-center p-1 text-sm text-primary1 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
                aria-controls="navbar-default"
                onClick={() => setIsNavOpen((prev) => !prev)}
              >
                <span className="sr-only" />
                <svg
                  className="w-6 h-6"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>

            <div className="nav flex-col lg:flex-row gap-12 w-full hidden lg:flex">
              <nav aria-label="Site Nav">
                <ul className="flex flex-col items-center lg:flex-row text-sm w-full">
                  {userData?.name && (
                    <li className="flex w-full items-center text-16 lg:h-20 align-middle  font-medium text-[#666A73] transition gap-4 cursor-pointer px-4 hover:text-black1/75">
                      <p
                        onClick={() =>
                          navigate("/accommodation/customer/personal-info")
                        }
                        style={{ textWrap: "nowrap" }}
                        className="w-full flex items-center text-16 h-0 font-medium align-middle text-nowrap"
                      >
                        <span className="mr-2">
                          <Person />
                        </span>

                        {userData.name}
                      </p>
                    </li>
                  )}
                  <li className="flex w-full items-center text-16 lg:h-20 align-middle nav-item-header font-medium text-[#666A73] transition gap-4 cursor-pointer px-4 hover:text-black1/75">
                    <div
                      onClick={() => redirectTo("about")}
                      className="text-16 h-0 font-medium align-middle nav-link transition hover:text-black1/75"
                    >
                      {getAccommodationText("about")}
                    </div>
                  </li>
                  <li className="flex w-full items-center text-16 lg:h-20 align-middle nav-item-header font-medium text-[#666A73] transition gap-4 cursor-pointer px-4 hover:text-black1/75">
                    <div
                      onClick={() => redirectTo("contact")}
                      className={`text-16 h-0 font-medium align-middle ${
                        isRoute() ? "text-[#6DDFE2]" : ""
                      } nav-link transition hover:text-black1/75`}
                    >
                      {getAccommodationText("contact")}
                    </div>
                  </li>
                  {languageComponent}
                </ul>
              </nav>
            </div>
          </div>

          {isNavOpen && (
            <div className="flex flex-col gap-12 w-full header-menu-mobile p-0 m-0 bg-gray-100">
              <nav aria-label="Site Nav" className=" mt-8">
                <ul className="flex flex-col gap-8 text-sm">
                  <li>
                    <div
                      className={`text-16 h-0 font-medium align-middle ${
                        isRoute("about") ? "text-[#8b1dbf]" : ""
                      } nav-link transition hover:text-black1/75`}
                      onClick={() => redirectTo("about")}
                    >
                      {getAccommodationText("about")}
                    </div>
                  </li>
                  <li>
                    <div
                      onClick={() => redirectTo("contact")}
                      className={`text-16 h-0 font-medium align-middle ${
                        isRoute() ? "text-[#8b1dbf]" : ""
                      } nav-link transition hover:text-black1/75`}
                    >
                      {getAccommodationText("contact")}
                    </div>
                  </li>
                  {languageComponent}
                </ul>
              </nav>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default VenueBrandHeader;
