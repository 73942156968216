import React from "react";
const RentalInformation = ({ thingsToKnow }) => {
  return (
    <div className="w-full md:w-[30%] mt-6 md:mt-0 ">
      <h2 className="text-[#2D3748] text-24 md:text-[30px] font-bold leading-[120%]  mb-6">
        Things to know
      </h2>
      <div className="text-[#2D3748] ">
        <p className="text-18 md:text-20 font-bold leading-[120%] mb-2">
          Accommodation details
        </p>
        {thingsToKnow.details?.map((item, key) => (
          <p key={key} className="text-14 md:text-16 font-normal leading-[150%] mb-3">
            {item}
          </p>
        ))}
        <div className="h-[30px]"></div>
      </div>
      <div className="text-[#2D3748] ">
        <p className="text-18 md:text-20 font-bold leading-[120%] mb-2">
          Staff Languages Spoken
        </p>
        {thingsToKnow.staff_languages?.map((item, key) => (
          <p key={key} className="text-14 md:text-16 font-normal leading-[150%] mb-3">
            {item.name}
          </p>
        ))}
        <div className="h-[30px]"></div>
      </div>
      <div className="text-[#2D3748] ">
        <p className="text-18 md:text-20 font-bold leading-[120%] mb-2">
          House rules
        </p>
        {thingsToKnow.rules?.map((item, key) => (
          <p key={key} className="text-14 md:text-16 font-normal leading-[150%] mb-3">
            {item}
          </p>
        ))}
        <div className="h-[30px]"></div>
      </div>
      <div className="text-[#2D3748] ">
        <p className="text-18 md:text-20 font-bold leading-[120%] mb-2">
          Cancellation policy
        </p>
        <p className="text-14 md:text-16 font-normal leading-[150%] mb-3">
          {thingsToKnow?.cancellation_policy}
        </p>
      </div>
    </div>
  );
};

export default RentalInformation;
