import { combineReducers } from "redux";
import contactsales from "./contactsales";
import chatbot from "./chatbot";
import cart from "./cart";
import app from "./app";
import auth from "./auth";
import venue from "./venue";
import search from "./search";

const reducers = combineReducers({
  app,
  auth,
  venue,
  contactsales,
  cart,
  chatbot,
  search,
});

export default reducers;
