// i18n.js
import i18n from "i18next";
import moment from "moment";
import { initReactI18next } from "react-i18next";
import translationAL from "../assets/locales/al.json";
import translationEN from "../assets/locales/en.json";
import translationES from "../assets/locales/es.json";
import translationFR from "../assets/locales/fr.json";
import translationGR from "../assets/locales/gr.json";
import { getStorageKey, KEYS, setStorageKey } from "../utils/storage";

export const appMoment = moment;

// the translations
const resources = {
  en: { translation: translationEN },
  es: { translation: translationES },
  fr: { translation: translationFR },
  gr: { translation: translationGR },
  al: { translation: translationAL },
};

const syncMomentLocale = (lang) => {
  const locale = lang === "en" ? "en" : lang;
  appMoment.locale(locale);
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: getStorageKey(KEYS.ACCOMMODATION_LANGUAGE) || "al",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    cleanCode: true,
    react: {
      useSuspense: false,
    },
  })
  .then(() => {
    syncMomentLocale(i18n.language);
  });

export const getLanguage = () => i18n.language;

export const setLanguage = async (lang) => {
  await i18n.changeLanguage(lang);
  setStorageKey(KEYS.ACCOMMODATION_LANGUAGE, lang);
  syncMomentLocale(lang);
};

export const setByBestLanguage = async (lang) => {
  await i18n.changeLanguage(lang);
  setStorageKey(KEYS.ACCOMMODATION_LANGUAGE, lang);
  syncMomentLocale(lang);
};

export const setAccommodationLanguage = async (lang) => {
  await i18n.changeLanguage(lang);
  setStorageKey(KEYS.ACCOMMODATION_LANGUAGE, lang);
  syncMomentLocale(lang);
};

export default i18n;
