/* eslint-disable react-hooks/exhaustive-deps */
import { t } from "i18next";
import React, { useEffect, useMemo, useState } from "react";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { NotificationManager } from "react-notifications";
import { customerText } from "../../../components/Seo/whitelabel/bybest/bybest-customer-layout";
import CustomerService from "../../../services/customer-service";
import "../../customer/promotions/index.css";
import PromotionCalendar from "../../customer/promotions/promotion-calendar";
import CurrentPromotions from "./current-promotions";
import UsedPromotions from "./used-promotions";

export const initialPagination = {
  current: 1,
  pageSize: 5,
  total: 0,
};

const AccPromotions = () => {
  const [dataSource, setDataSource] = useState({});
  const [promotionParam, setPromotionParams] = useState(initialPagination);

  const handlePageChange = (response) => {
    setPromotionParams((prev) => ({
      ...prev,
      current:
        typeof response === "number" ? response : response?.current_page ?? 1,
      ...response,
    }));
  };

  const query = useMemo(() => {
    return {
      per_page: promotionParam.pageSize,
      page: promotionParam.current,
      type: "discount",
    };
  }, [promotionParam?.current]);

  useEffect(() => {
    CustomerService.getPromotions(query)
      .then((response) => {
        setDataSource(response);
        if (response) {
          handlePageChange(response);
        }
      })
      .catch((error) => {
        NotificationManager.error(
          error?.message || t("contact_us.somethingWentWrong"),
          customerText("error"),
          3000
        );
      });
  }, [promotionParam.current]);

  return (
    <div className="bg-white w-full grid lg:grid-cols-3 grid-cols-1 gap-4 p-6 rounded-lg">
      <CurrentPromotions
        {...{ dataSource, promotionParam, handlePageChange }}
        setParams={setPromotionParams}
      />

      <PromotionCalendar
        promoClassName={"!bg-[#6DDFE2] !text-white"}
        promoCalendarClassName="promo2"
        dataSource={dataSource?.data}
      />

      <UsedPromotions />
    </div>
  );
};

export default AccPromotions;
