import React, { useState } from "react";
import {
  PaymentElement,
  LinkAuthenticationElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

export default function StripeCheckoutForm({
  onStripeCheckout,
  buttonClasses,
  type,
  showBtn = true,
  label = "Place order",
  className = "w-[240px]",
}) {
  const stripe = useStripe();
  const elements = useElements();

  const [email, setEmail] = useState("");
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      redirect: "if_required",
      confirmParams: {
        return_url:
          window.location.origin +
          (type === "food"
            ? "/order-restaurant-success"
            : type === "accommodation"
            ? "/booking-success"
            : "/order-retail-success"),
      },
    });

    // Handle the response after the payment attempt
    if (error) {
      // Errors could occur like declined card, insufficient funds, etc.
      setMessage(error.message);
    } else if (paymentIntent) {
      // Here, paymentIntent is available
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          // Pass the paymentIntentId to your callback or state handler
          onStripeCheckout(paymentIntent.id);
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    } else {
      // Handle other cases or log for debugging
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs",
  };

  return (
    <form
      id="payment-form"
      onSubmit={handleSubmit}
      className="w-full flex flex-col gap-4"
    >
      <LinkAuthenticationElement
        id="link-authentication-element"
        onChange={(e) => setEmail(e?.target?.value)}
      />
      <PaymentElement id="payment-element" options={paymentElementOptions} />
      {showBtn && (
        <div className="flex flex-row justify-end items-center mt-4">
          <div className={`flex flex-col justify-start ${className}`}>
            <button
              className={`${
                buttonClasses ??
                "bg-primary1 text-white rounded-md font-normal text-18 mt-5 px-10"
              }`}
              disabled={isLoading || !stripe || !elements}
              id="submit"
            >
              <span id="button-text">
                {isLoading ? (
                  <div className="spinner" id="spinner"></div>
                ) : (
                  label
                )}
              </span>
            </button>
          </div>
        </div>
      )}
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}
