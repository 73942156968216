import { getStatus } from "../../../components/columns/get-status";
import { customerText } from "../../../components/Seo/whitelabel/bybest/bybest-customer-layout";
import { formatDate } from "../wallet/payment-functions";

export const useGetPromotionsData = () => {
  const currentPromotionsColumns = [
    {
      title: customerText("discount"),
      dataIndex: "discounts",
      key: "discounts",
      render: (row) => {
        if (row && row.length > 0) {
          return (
            <p className="text-14 text-[#667085] font-medium">
              {row[0].value || "-"}
              {row[0].type == "percentage"
                ? "%"
                : `${row[0].type == "fixed" ? "€" : ""}`}
            </p>
          );
        }
        return <></>;
      },
    },
    {
      title: customerText("apartment"),
      dataIndex: "discounts",
      key: "discounts",
      render: (row) => (
        <div className="flex items-center gap-2">
          <p className="text-14 text-[#101828] font-medium">
            {row[0]?.rental_unit?.name || "-"}
          </p>
        </div>
      ),
    },
    {
      title: customerText("description"),
      dataIndex: "description",
      key: "description",
      render: (description) => (
        <p className="text-14 text-[#667085] font-medium">
          {description.slice(0, 50) + "..."}
        </p>
      ),
    },
    {
      title: customerText("startTime"),
      dataIndex: "start_time",
      key: "start_time",
      render: (expiryDate) => (
        <p className="text-14 text-[#667085] font-medium">
          {formatDate(expiryDate)}
        </p>
      ),
    },
    {
      title: customerText("expiryDate"),
      dataIndex: "end_time",
      key: "end_time",
      render: (expiryDate) => (
        <p className="text-14 text-[#667085] font-medium">
          {formatDate(expiryDate)}
        </p>
      ),
    },
    {
      title: customerText("status"),
      dataIndex: "status",
      key: "status",
      render: (value) => getStatus(value),
    },
  ];

  const usedPromotionsColumns = [
    {
      title: customerText("discount"),
      dataIndex: "discounts",
      key: "discounts",
      render: (row) => {
        if (row && row.length > 0) {
          return (
            <p className="text-14 text-[#667085] font-medium">
              {row[0].value || "-"}
              {row[0].type == "percentage"
                ? "%"
                : `${row[0].type == "fixed" ? "€" : ""}`}
            </p>
          );
        }
        return <></>;
      },
    },
    {
      title: customerText("apartment"),
      dataIndex: "discounts",
      key: "discounts",
      render: (row) => (
        <div className="flex items-center gap-2">
          <p className="text-14 text-[#101828] font-medium">
            {row[0]?.rental_unit?.name || "-"}
          </p>
        </div>
      ),
    },
    {
      title: customerText("description"),
      dataIndex: "description",
      key: "description",
      render: (description) => (
        <p className="text-14 text-[#667085] font-medium">
          {description.slice(0, 50) + "..."}
        </p>
      ),
    },
    // {
    //   title: customerText("usedAmount"),
    //   dataIndex: "discounts",
    //   key: "discounts",
    //   render: (row) => {
    //     if (row && row.length > 0) {
    //       return (
    //         <p className="text-14 text-[#667085] font-medium">
    //           €{row[0].bookings[0].discount_price}
    //         </p>
    //       );
    //     }
    //     return <></>;
    //   },
    // },
    {
      title: customerText("usedDate"),
      dataIndex: "end_time",
      key: "end_time",
      render: (usedDate) => (
        <p className="text-14 text-[#667085] font-medium">
          {formatDate(usedDate)}
        </p>
      ),
    },
  ];

  return {
    currentPromotionsColumns,
    usedPromotionsColumns,
  };
};
