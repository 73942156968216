import { Popover } from "antd";
import EmojiPicker from "emoji-picker-react";
import { useState } from "react";
import { BsEmojiSmile } from "react-icons/bs";
import "./index.css";

const EmojiContent = ({ onEmojiClick }) => {
  return <EmojiPicker skinTonesDisabled={true} onEmojiClick={onEmojiClick} />;
};

const EmojiPickerModal = ({ onSelect }) => {
  const [open, setOpen] = useState(false);

  return (
    <Popover
      content={<EmojiContent onEmojiClick={onSelect} />}
      title="Title"
      trigger="click"
      open={open}
      onOpenChange={setOpen}
    >
      <BsEmojiSmile
        size={24}
        className="absolute right-3 top-1/2 transform -translate-y-1/2 hover:text-gray-800"
      />
    </Popover>
  );
};

export default EmojiPickerModal;
