import React, { Suspense } from "react";
import ScrollToTopButton from "../../components/ScrollToUpButton";
import "react-notifications/lib/notifications.css";
import { NotificationContainer } from "react-notifications";
import { Outlet } from "react-router-dom";
import VenueBrandHeader from "../header/VenueBrandHeader";

// Lazy load the Footer component
const LazyFooter = React.lazy(() => import("../footer/BrandFooter"));

const BrandLayout = () => {
  return (
    <>
      <ScrollToTopButton />
      <VenueBrandHeader />
      <Outlet />
      <Suspense fallback={<div>Loading footer...</div>}>
        <LazyFooter />
      </Suspense>
      <NotificationContainer />
    </>
  );
};

export default BrandLayout;
