import apiFactory from "../utils/apiFactory";
import {
  API_ACCOMMODATION,
  API_AFFILIATES,
  API_Auth,
  API_MEMBERS,
  API_RETAIL,
  API_WHITE_LABEL,
} from "../constants/apiConfig";

const refreshToken = localStorage.getItem("refreshToken");

export default class WhiteLabelService {
  static getDetails = async (venue_app_code) => {
    let api = {
      content_type: "application/json",
      path: `${API_WHITE_LABEL.path}/web-profile`,
      query: `venue_app_code=${venue_app_code}`,
      method: "GET",
    };
    return apiFactory(api);
  };

  static apartamentDetails = async (payload, venue_app_key) => {
    let api = {
      content_type: "application/json",
      path: `${API_WHITE_LABEL.path}/check-availability`,
      // query: `venue_app_key=${venue_app_key}`,
      method: "POST",
    };
    return apiFactory(api, payload);
  };

  static reservation = async (payload, venue_app_key) => {
    let api = {
      content_type: "application/json",
      path: `${API_WHITE_LABEL.path}/reservation`,
      query: `venue_app_key=${venue_app_key}`,
      method: "POST",
    };
    return apiFactory(api, payload);
  };

  static guestEnroll = async (payload, venue_app_key) => {
    let api = {
      content_type: "application/json",
      path: `${API_WHITE_LABEL.path}/guest-enroll`,
      query: `venue_app_key=${venue_app_key}`,
      method: "POST",
    };
    return apiFactory(api, payload);
  };

  static getFormConfigurations = async (venue_app_key) => {
    let api = {
      content_type: "application/json",
      path: `${API_WHITE_LABEL.path}/brand-contact-configurations`,
      query: `venue_app_key=${venue_app_key}`,
      method: "GET",
    };
    return apiFactory(api);
  };

  static submitContact = async (payload, venue_app_key) => {
    let api = {
      content_type: "application/json",
      path: `${API_WHITE_LABEL.path}/submit-whitelabel-contact`,
      query: `venue_app_key=${venue_app_key}`,
      method: "POST",
    };
    return apiFactory(api, payload);
  };

  static emailSubscribe = async (payload, venue_app_key) => {
    let api = {
      content_type: "application/json",
      path: `${API_WHITE_LABEL.path}/email-subscribe`,
      query: `venue_app_key=${venue_app_key}`,
      method: "POST",
    };
    return apiFactory(api, payload);
  };

  static registerLanding = async (payload, venue_app_key) => {
    let api = {
      content_type: "application/json",
      path: `${API_MEMBERS.path}/register-landing`,
      query: `venue_app_key=${venue_app_key}`,
      method: "POST",
    };
    return apiFactory(api, payload);
  };

  static registerMembers = async (payload, venue_app_key) => {
    let api = {
      content_type: "application/json",
      path: `${API_MEMBERS.path}/register-myclub`,
      query: `venue_app_key=${venue_app_key}`,
      method: "POST",
    };
    return apiFactory(api, payload);
  };

  static bybestRegister = async (payload, venue_app_key) => {
    let api = {
      content_type: "application/json",
      path: `${API_Auth.path}/register-enduser`,
      query: `venue_app_key=${venue_app_key}`,
      method: "POST",
    };
    return apiFactory(api, payload);
  };

  static bybestLogin = async (payload, venue_app_key) => {
    let api = {
      content_type: "application/json",
      path: `${API_Auth.path}/login-enduser`,
      query: `venue_app_key=${venue_app_key}`,
      method: "POST",
    };

    return apiFactory(api, payload);
  };

  static refreshAccessToken = async () => {
    let api = {
      content_type: "application/json",
      path: `${API_Auth.path}/refresh-enduser`,
      method: "POST",
    };

    if (refreshToken) {
      return apiFactory(api, {}, refreshToken, undefined, true);
    }
  };

  static checkoutOrder = async (payload, venue_app_key) => {
    let api = {
      content_type: "application/json",
      path: `${API_RETAIL.path}/checkout`,
      query: `venue_app_key=${venue_app_key}`,
      method: "POST",
    };
    return apiFactory(api, payload);
  };

  static checkoutOrderBybest = async (payload) => {
    let api = {
      content_type: "application/json",
      path: `${API_WHITE_LABEL.path}/checkout/bybest`,
      method: "POST",
    };
    return apiFactory(api, payload);
  };

  static checkoutRestaurantOrder = async (payload, venue_app_key) => {
    let api = {
      content_type: "application/json",
      path: `${API_WHITE_LABEL.path}/restaurant/order`,
      query: `venue_app_key=${venue_app_key}`,
      method: "POST",
    };
    return apiFactory(api, payload);
  };

  static getProductDetails = async (id, venue_app_key) => {
    let api = {
      content_type: "application/json",
      path: `${API_RETAIL.path}/product-details/${id}`,
      query: `venue_app_key=${venue_app_key}`,
      method: "GET",
    };
    return apiFactory(api);
  };

  static getShippingMethods = async (venue_app_key) => {
    let api = {
      content_type: "application/json",
      path: `${API_RETAIL.path}/shipping-methods`,
      query: `venue_app_key=${venue_app_key}`,
      method: "GET",
    };
    return apiFactory(api);
  };

  static getAffiliateTypes = async () => {
    let api = {
      content_type: "application/json",
      path: `${API_AFFILIATES.path}/web-types`,
      method: "GET",
    };
    return apiFactory(api);
  };

  static getRentallDetails = async (rental_unit_code) => {
    let api = {
      content_type: "application/json",
      path: `${API_ACCOMMODATION.path}/white-label`,
      query: `rental_unit_code=${rental_unit_code}`,
      method: "GET",
    };
    return apiFactory(api);
  };

  static createPaymentIntent = async (payload, venue_app_key) => {
    let api = {
      content_type: "application/json",
      path: `${API_WHITE_LABEL.path}/payments`,
      query: `venue_app_key=${venue_app_key}`,
      method: "POST",
    };
    return apiFactory(api, payload);
  };
  static createBooking = async (payload, rental_unit_code) => {
    let api = {
      content_type: "application/json",
      path: `${API_ACCOMMODATION.path}/book`,
      query: `rental_unit_code=${rental_unit_code}`,
      method: "POST",
    };
    return apiFactory(api, payload);
  };
  static createDestinationCharge = async (payload, venue_app_key) => {
    let api = {
      content_type: "application/json",
      path: `${API_WHITE_LABEL.path}/payments/destination-charge`,
      query: `venue_app_key=${venue_app_key}`,
      method: "POST",
    };
    return apiFactory(api, payload);
  };

  static resendRegistration = async (payload, venue_app_key, token) => {
    let api = {
      content_type: "application/json",
      path: `${API_Auth.path}/resend-verify-email`,
      query: `venue_app_key=${venue_app_key}`,
      method: "POST",
    };
    return apiFactory(api, payload, token, {}, true);
  };

  static getCustomerData = async (payload, venue_app_key) => {
    let api = {
      content_type: "application/json",
      path: `${API_Auth.path}/get-customer-data`,
      query: `venue_app_key=${venue_app_key}`,
      method: "GET",
    };
    return apiFactory(api, payload);
  };

  static bybestUpdateCustomer = async (payload, venue_app_key) => {
    let api = {
      content_type: "application/json",
      path: `${API_Auth.path}/update-customer`,
      query: `venue_app_key=${venue_app_key}`,
      method: "POST",
    };
    return apiFactory(api, payload);
  };
}
