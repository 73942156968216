import React, { useState } from "react";
import { getAccommodationText } from "..";
import WhiteLabelService from "../../../services/whiteLabelService";
import { useParams } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import VirtualTourBlocks from "./virtual-tour-blocks";

const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

const AccommodationQuality = () => {
  const { app_key: accommodationId } = useParams();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");

  const onSubscribe = () => {
    setLoading(true);
    if (email.match(isValidEmail)) {
      const payload = {
        email,
      };
      WhiteLabelService.emailSubscribe(payload, accommodationId)
        .then((data) => {
          setLoading(false);
          NotificationManager.success(
            data?.message || getAccommodationText("thanksForSubscribe"),
            "Success",
            3000
          );
          setEmail("");
        })
        .catch((error) => {
          setLoading(false);
          NotificationManager.error(
            error?.error || getAccommodationText("somethingWentWrong"),
            "Error",
            3000
          );
        });
    } else {
      setLoading(false);
      NotificationManager.warning(
        getAccommodationText("validEmail"),
        "Error",
        3000
      );
    }
  };

  return (
    <React.Fragment>
      <div className="review-section w-full">
        <div className="fflex flex-col lg:flex-row justify-between gap-10 items-center w-full mb-6">
          <div className="lg:w-[100%] font-normal lg:text-[40px] text-[24px] md:text-28">
            {getAccommodationText("homeAwayMessage")}
          </div>
          <p className="lg:w-[100%] lg:text-18 text-16 text-[#6E706E]">
            {getAccommodationText("dedicatedService")}
          </p>
        </div>
        <VirtualTourBlocks />
      </div>
      <div className="stayUpdate w-full border shadow border-[#adb5bdb3] rounded-[12px] p-10 flex flex-col gap-2 items-center md:my-20 my-10">
        <div className="text-[#2D3748] lg:text-[48px] text-28 font-bold">
          {getAccommodationText("stayInformed")}
        </div>
        <p className="lg:text-18 text-16 text-[#6E706E]">
          {getAccommodationText("newsletter")}
        </p>
        <div className="flex flex-row gap-2 lg:w-[30%] mt-8">
          <input
            onChange={(e) => setEmail(e.target.value)}
            type="text"
            placeholder="Email Address"
            className="w-full h-[45px] p-3 border border-[#ADB5BD] rounded-[8px] shadow"
          />
          <button
            disabled={loading || email.length === 0}
            onClick={onSubscribe}
            className={`bg-[#6DDFE2] shadow text-white text-[16px] rounded-[8px] w-[120px] h-[45px] ${
              email.length === 0 ? "cursor-not-allowed opacity-70" : ""
            }`}
          >
            {loading
              ? getAccommodationText("loading")
              : getAccommodationText("subscribe")}
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AccommodationQuality;
