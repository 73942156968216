import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import "./assets/index.css";
import App from "./containers";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./redux/store";
import "./localisations/index";
import * as Sentry from "@sentry/react";
import { I18nextProvider } from "react-i18next";
import i18n from "./localisations/index";

Sentry.init({
  dsn: "https://705336b586770f178db3705165168c76@o4508410395230208.ingest.de.sentry.io/4508433355964496",
  integrations: [],
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <HelmetProvider prioritizeSeoTags>
        <I18nextProvider i18n={i18n}>
          <App />
        </I18nextProvider>
      </HelmetProvider>
    </BrowserRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals())
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
