import React from "react";
import { isEmpty } from "../../utils/common";

const FormTextarea = ({
  name,
  error,
  value,
  disabled = false,
  placeholder,
  className,
  inputClassName,
  onChange,
  rows,
  cols,
  enableLabel = true,
}) => {
  return (
    <div className={`w-full ${className}`}>
      {enableLabel && <p className="text-16 text-[#232323]">{placeholder}</p>}
      <textarea
        rows={rows}
        cols={cols}
        name={name}
        disabled={disabled}
        placeholder={!enableLabel && placeholder}
        className={`w-full bg-white min-h-[48px] border-[1px] font-medium mt-2 mb-0 rounded-[5px] border-[#CBD5E1] focus:border-[1px] outline-none focus:border-blue-400 py-1 px-2 ${inputClassName}`}
        value={value}
        onChange={(e) => onChange(e)}
      />
      {!isEmpty(error) && (
        <div className={"text-red-600 text-12 mt-2"}>{error}</div>
      )}
    </div>
  );
};

export default FormTextarea;
