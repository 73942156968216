import { DatePicker, Modal } from "antd";
import React from "react";
import "../rental/rental-form/index.css";
import dayjs from "dayjs";
import { rentalReserveText } from ".";

const disableDates = (current, bookingDate = []) => {
  return (
    current < dayjs().startOf("day") ||
    bookingDate.some((dateRange) => {
      const start = dayjs(dateRange.start_date, "YYYY-MM-DD")
        .startOf("day")
        .toDate();
      const end = dayjs(dateRange.end_date, "YYYY-MM-DD").endOf("day").toDate();
      return current >= start && current <= end;
    })
  );
};

const RentalReserveModal = ({
  openModal,
  handleCloseModal,
  handleSaveModal,
  modalCheckIn,
  modalCheckOut,
  show,
  setModalCheckIn,
  setModalCheckOut,
  setCheckIn,
  setCheckOut,
  bookingDate,
}) => {
  return (
    <Modal
      centered
      open={openModal}
      onCancel={() => handleCloseModal()}
      onOk={() => handleSaveModal()}
      maskClosable={false}
      okText={rentalReserveText("save")}
      okButtonProps={{
        style: { backgroundColor: "#6DDFE2", color: "white" },
        disabled: !modalCheckIn || !modalCheckOut,
      }}
      cancelButtonProps={{
        style: {
          backgroundColor: "transparent",
          color: "#240b3b",
          borderColor: "#6DDFE2",
        },
      }}
      width={680}
    >
      <div className={"flex flex-col py-4 w-full h-max md:h-[450px]"}>
        <div className="grid grid-cols-1 md:grid-cols-2 w-full flex-col gap-4 md:gap-10 ">
          <div className="flex flex-col">
            <p className="text-12 text-primary1 font-bold leading-[16px] mb-2">
              {rentalReserveText("checkin")}
            </p>
            <DatePicker
              className="hidden md:block"
              open={show}
              disabledDate={(current) => disableDates(current, bookingDate)}
              onChange={(e) => {
                setCheckIn(e);
                setModalCheckIn(e);
              }}
            />
            <DatePicker
              className="block md:hidden"
              disabledDate={(current) => disableDates(current, bookingDate)}
              onChange={(e) => {
                setCheckIn(e);
                setModalCheckIn(e);
              }}
            />
          </div>
          <div className="flex flex-col">
            <p className="text-12 text-primary1 font-bold leading-[16px] mb-2">
              {rentalReserveText("checkout")}
            </p>
            <DatePicker
              className="hidden md:block"
              open={show}
              disabledDate={(current) => disableDates(current, bookingDate)}
              onChange={(e) => {
                setCheckOut(e);
                setModalCheckOut(e);
              }}
            />
            <DatePicker
              className="block md:hidden"
              disabledDate={(current) => disableDates(current, bookingDate)}
              onChange={(e) => {
                setCheckOut(e);
                setModalCheckOut(e);
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RentalReserveModal;
