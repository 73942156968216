import React, { useState } from "react";
import Modal from "react-modal";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import MappinIcon from "../../../assets/svgs/map-pin.svg";
import MaphouseIcon from "../../../assets/svgs/map-house.svg";
import FormText from "../../../components/FormText";

Modal.setAppElement("#root"); // Set the app element for accessibility

const RentalHeader = ({ header, enableDate, price, currency }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const images = (header?.gallery ?? [])?.map((img) => img.photo_path) || [];

  const modalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      maxWidth: "800px",
      padding: "0",
      border: "none",
      background: "none",
      overflow: "hidden",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.8)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  };

  const lightboxStyles = {
    overlay: {
      zIndex: 1000,
      backgroundColor: "rgba(0, 0, 0, 0.1)",
      // width: "100%",
    },
    content: {
      padding: "0",
      border: "none",
      background: "none",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  };

  if (window.innerWidth >= 768) {
    lightboxStyles.overlay.margin = "10%";
  }

  return (
    <div>
      <div>
        <h1 className="text-primary1 text-24 md:text-[48px] font-bold ">
          {header?.name ?? "-"}
        </h1>
        <div className="flex flex-col md:flex-row md:items-center gap-2 md:gap-0 md:mt-0 mt-2 ">
          <div className="flex flex-row items-center ">
            <img alt="" src={MappinIcon} className="mr-2" />
            <span className="text-14 md:text-16 font-medium text-primary1 mr-4">
              {header?.address}
            </span>
          </div>
          <div className="flex flex-row items-center">
            <img alt="" src={MaphouseIcon} className="mr-2" />
            <span className="text-14 md:text-16 font-medium text-primary1">
              {header?.square_metters} · {header?.guest_limit ?? "0"} guests ·{" "}
              {header?.rooms?.join(" · ")} · {header?.beds?.join(" · ")} ·{" "}
              {header?.bathrooms > 0 && `${header?.bathrooms} bathrooms`}
            </span>
          </div>
        </div>
      </div>
      {header?.gallery ? (
        <div className="grid md:grid-cols-2 gap-2 my-8">
          {header?.gallery.length > 0 && (
            <div
              onClick={() => {
                setPhotoIndex(0);
                setIsOpen(true);
              }}
            >
              <img
                alt=""
                src={header?.gallery[0].photo_path}
                className="cursor-pointer"
              />
            </div>
          )}
          <div className="hidden md:grid grid-cols-2 gap-2">
            <div className="grid grid-rows-2 gap-2">
              {header?.gallery.length > 1 && (
                <img
                  alt=""
                  src={header?.gallery[1].photo_path}
                  className="w-[304px] h-[194px] cursor-pointer"
                  onClick={() => {
                    setPhotoIndex(1);
                    setIsOpen(true);
                  }}
                />
              )}
              {header?.gallery.length > 2 && (
                <img
                  alt=""
                  src={header?.gallery[2].photo_path}
                  className="w-[304px] h-[194px] cursor-pointer"
                  onClick={() => {
                    setPhotoIndex(2);
                    setIsOpen(true);
                  }}
                />
              )}
            </div>
            <div className="grid grid-rows-2 gap-2">
              {header?.gallery.length > 3 && (
                <img
                  alt=""
                  src={header?.gallery[3].photo_path}
                  className="w-[304px] h-[194px] cursor-pointer"
                  onClick={() => {
                    setPhotoIndex(3);
                    setIsOpen(true);
                  }}
                />
              )}
              {header?.gallery.length > 4 && (
                <img
                  alt=""
                  src={header?.gallery[4].photo_path}
                  className="w-[304px] h-[194px] cursor-pointer"
                  onClick={() => {
                    setPhotoIndex(4);
                    setIsOpen(true);
                  }}
                />
              )}
            </div>
          </div>
        </div>
      ) : null}
      <div className="flex md:flex-row flex-col">
        <div className="w-full md:w-[70%] md:pr-[49px]">
          <h2 className="text-primary1 text-24 md:text-[30px] font-bold leading-[120%] mb-2">
            {header?.name ?? "-"}
          </h2>
          <span>Hosted by</span>
          <span> {header?.hosted_by}</span>
        </div>
        <div className="w-full md:w-[30%] hidden md:flex items-end">
          {enableDate ? (
            <>
              {price ? (
                <div className="flex flex-row items-end gap-2 border-[#E2E8F0] border-[1px] rounded-[5px] p-2 w-full">
                  <FormText
                    title={`${currency} ${price}`}
                    type="itemtitle-sub-s"
                    customClass="!font-bold !text-[22px]"
                  />
                  <FormText title="night" customClass="!text-base mb-0.5" />
                </div>
              ) : (
                <FormText
                  title="Add dates for prices"
                  customClass="w-full !text-xl !font-bold border-[#E2E8F0] border-[1px] rounded-[5px] p-2"
                />
              )}
            </>
          ) : (
            <FormText
              title="Add dates for prices"
              customClass="w-full !text-xl !font-bold border-[#E2E8F0] border-[1px] rounded-[5px] p-2"
            />
          )}
        </div>
      </div>
      {isOpen && (
        <Modal
          isOpen={isOpen}
          onRequestClose={() => setIsOpen(false)}
          style={modalStyles}
        >
          <div className="relative bg-black rounded-md p-4">
            <Lightbox
              mainSrc={images[photoIndex]}
              nextSrc={images[(photoIndex + 1) % images.length]}
              prevSrc={images[(photoIndex + images.length - 1) % images.length]}
              onCloseRequest={() => setIsOpen(false)}
              onMovePrevRequest={() =>
                setPhotoIndex((photoIndex + images.length - 1) % images.length)
              }
              onMoveNextRequest={() =>
                setPhotoIndex((photoIndex + 1) % images.length)
              }
              reactModalStyle={lightboxStyles}
            />
          </div>
        </Modal>
      )}
    </div>
  );
};

export default RentalHeader;
