import starIcon from "../../../assets/svgs/starIcon.svg";

export const carouselItems = [
  {
    img: starIcon,
    name: "JOvi E.",
    review:
      "Good location, but too noisy in the morning. Panorama view ordinary. Very polite and welcome owners. Heped with taxi. Velur spa down stairs, so you can take hamam each day. A lot of shops and caffees beside",
  },
  {
    img: starIcon,
    name: "Eneida",
    review:
        "By Best Apartments are fantastic. Very clean and stylish place with everything you needed. In a great location, right near heaps of great places to eat! Ejona was very accommodating and helpful letting us drop our bags off early and checking in on us regularly to make sure we had everything we needed. Would definitely stay here again!",
  },
  {
    img: starIcon,
    name: "Altenisa B.",
    review:
      "Apartment very well located in Tirana.\n" +
        "Very good communication",
  },

  {
    img: starIcon,
    name: "Raimonda D.",
    review:
      "Great place to stay while you are visiting Tirana. Comfortable place and very easy to be booked.",
  },
  {
    img: starIcon,
    name: "Inis E.",
    review:
      "Great location with superb view from the city. Friendly staff and good service. Flexible check in and out. Clean and ready. Highly recommend",
  },
  {
    img: starIcon,
    name: "Ambra M.",
    review:
      "The perfect choice for someone who visit Tirana. Good located and its worth it for the prices.",
  },
];
