import React, { useState } from "react";
import CustomerHeader from "../customer-header";
import { walletColumns } from "./columns";
import GenericTable from "../../../components/Table/generic-table";
import { customerText } from "../../../components/Seo/whitelabel/bybest/bybest-customer-layout";

const WalletTable = ({ tableData = [] }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState({
    current: 1,
    total: tableData?.length || 0,
  });

  return (
    <div className="flex flex-1 flex-col mt-6">
      <div className="bg-white w-full boxshadow border-[1px] border-[#EAECF0] rounded-lg">
        <div className="flex justify-between items-center w-full p-6">
          <CustomerHeader
            description={customerText("orderKeepTrack")}
            numberOfItems={tableData?.length}
            items={customerText("activities")}
            title={customerText("walletActivities")}
            showHome={false}
          />
        </div>
        <GenericTable
          columns={walletColumns}
          data={tableData?.slice(
            (pagination.current - 1) * 10,
            pagination.current * 10
          )}
          className="w-full hidden md:block pr-4"
          pagination={{
            ...pagination,
            onChange: setCurrentPage,
          }}
        />
      </div>
    </div>
  );
};

export default WalletTable;
