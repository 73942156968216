import React from "react";
import { lightIcon } from "./icons";

const TierLevel = ({ className = "", level = "" }) => {
  return (
    <div className={`platinium-tier ${className}`}>
      <div className="flex items-center gap-2">
        <span>{lightIcon}</span>
        <span>{level}</span>
      </div>
    </div>
  );
};

export default TierLevel;
