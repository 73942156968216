import React, { useRef } from "react";

const ActionBtns = ({ onImageSelected }) => {
  const image_file_input = useRef(null);

  const _handleImageChange = (e) => {
    e.preventDefault();
    try {
      let reader = new FileReader();
      let file = e.target.files[0];

      reader.onloadend = () => {
        onImageSelected({
          type: "file",
          url: reader.result,
          file: file,
        });
      };

      reader.readAsDataURL(file);
    } catch (err) {
      console.log("handle image error", err);
    }
  };

  return (
    <div className="flex items-center space-x-2">
      <input
        hidden={true}
        ref={image_file_input}
        type="file"
        accept="image/*"
        onChange={_handleImageChange}
      />
      <button
        className="hover:text-gray-800"
        onClick={() => {
          image_file_input.current?.click();
        }}
      >
        <svg
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20.082 2.36816H4.58203C3.34203 2.36816 2.33203 3.37816 2.33203 4.61816V20.1182C2.33203 21.3582 3.34203 22.3682 4.58203 22.3682H20.082C21.322 22.3682 22.332 21.3582 22.332 20.1182V4.61816C22.332 3.37816 21.322 2.36816 20.082 2.36816ZM4.58203 3.86816H20.082C20.495 3.86816 20.832 4.20516 20.832 4.61816V14.2942L16.974 10.4362C16.834 10.2962 16.644 10.2162 16.444 10.2162H16.441C16.241 10.2162 16.048 10.2962 15.909 10.4402L11.592 14.8242L9.77903 13.0182C9.63903 12.8782 9.44903 12.7982 9.24903 12.7982C9.05603 12.7682 8.85403 12.8782 8.71403 13.0252L3.83203 18.0102V4.61816C3.83203 4.20516 4.16903 3.86816 4.58203 3.86816ZM3.83803 20.1482L9.25603 14.6142L15.538 20.8682H4.58203C4.18003 20.8682 3.85503 20.5462 3.83803 20.1482ZM20.082 20.8682H17.662L12.655 15.8812L16.447 12.0312L20.832 16.4152V20.1182C20.832 20.5312 20.495 20.8682 20.082 20.8682Z"
            fill="#1A1818"
          />
          <path
            d="M9.19825 10.2193C10.0499 10.2193 10.7402 9.52888 10.7402 8.67725C10.7402 7.82563 10.0499 7.13525 9.19825 7.13525C8.34663 7.13525 7.65625 7.82563 7.65625 8.67725C7.65625 9.52888 8.34663 10.2193 9.19825 10.2193Z"
            fill="#1A1818"
          />
        </svg>
      </button>
      {/* <button className="hover:text-gray-800">
				<svg
					width="25"
					height="25"
					viewBox="0 0 25 25"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M19.3344 10.8682V9.16821H14.9344V15.5682H16.6344V13.5682H18.6344V11.8682H16.6344V10.8682H19.3344ZM12.0344 9.16821H13.7344V15.5682H12.0344V9.16821ZM8.43438 10.7682C8.83438 10.7682 9.33437 10.9682 9.63438 11.2682L10.8344 10.2682C10.2344 9.56821 9.33438 9.16821 8.43438 9.16821C6.63438 9.16821 5.23438 10.5682 5.23438 12.3682C5.23438 14.1682 6.63438 15.5682 8.43438 15.5682C9.43438 15.5682 10.2344 15.1682 10.8344 14.4682V11.9682H8.03437V13.1682H9.23437V13.7682C9.03437 13.8682 8.73438 13.9682 8.43438 13.9682C7.53438 13.9682 6.83437 13.2682 6.83437 12.3682C6.83437 11.5682 7.53438 10.7682 8.43438 10.7682Z"
						fill="#1A1818"
					/>
					<path
						d="M20.832 2.38818H3.83203C2.59203 2.38818 1.58203 3.39518 1.58203 4.63518V20.1422C1.58203 21.3802 2.59203 22.3882 3.83203 22.3882H20.832C22.072 22.3882 23.082 21.3802 23.082 20.1422V4.63518C23.082 3.39518 22.072 2.38818 20.832 2.38818ZM21.582 20.1422C21.582 20.5522 21.246 20.8882 20.832 20.8882H3.83203C3.41803 20.8882 3.08203 20.5522 3.08203 20.1422V4.63518C3.08203 4.22318 3.41803 3.88818 3.83203 3.88818H20.832C21.246 3.88818 21.582 4.22318 21.582 4.63518V20.1422Z"
						fill="#1A1818"
					/>
				</svg>
			</button> */}
    </div>
  );
};

export default ActionBtns;
