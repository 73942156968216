import React from "react";
import TierLevel from "../../../components/Sidebar/tier-level";
import WalletSummary from "./wallet-summary";
import { customerText } from "../../../components/Seo/whitelabel/bybest/bybest-customer-layout";

const WalletPoints = ({ iconClassName, showTier = true, data }) => {
  return (
    <div className="flex flex-col gap-4 p-4 border">
      <div className="flex justify-between">
        <div className="text-[#101828] text-18 font-semibold leading-7">
          {customerText("wallet")}
        </div>
        {showTier && <TierLevel className="w-fit p-2" />}
      </div>
      <WalletSummary
        value={data?.balance}
        title={customerText("totalPoints")}
        description={`${customerText("moneyValue")} ~ ${
          data?.money_value ?? 0
        } ${data?.currency}`}
        iconClassName={iconClassName}
        className="!flex"
      />
    </div>
  );
};

export default WalletPoints;
