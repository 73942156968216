import { t } from "i18next";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLogout } from "../../../../services/hooks/use-logout";
import { tokenExpiry } from "../../../../views/onboarding/common-functions";
import Sidebar from "../../../Sidebar";

export const customerText = (label = "") => t(`bybest.customer.${label}`);

const BybestCustomerLayout = ({ children, selected = "orders" }) => {
  const navigate = useNavigate();
  const vbToken = localStorage.getItem("vbToken");
  const expire = tokenExpiry();
  const handleLogout = useLogout(false);

  useEffect(() => {
    if (!vbToken || vbToken === null) {
      navigate("/login");
      window.location.reload();
    }
  }, [vbToken]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (expire * 1000 < new Date().getTime()) {
      handleLogout();
    }
  }, [expire]);

  return (
    <div className="w-full flex flex-col justify-center items-center bg-white">
      <div className="mt-2 flex gap-4 w-full md:px-5 px-4">
        <Sidebar selected={selected} />
        <div className="w-full">{children}</div>
      </div>
    </div>
  );
};

export default BybestCustomerLayout;
