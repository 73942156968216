/* eslint-disable array-callback-return */
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { Icon } from "react-icons-kit";
import { eye } from "react-icons-kit/feather/eye";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { NotificationManager } from "react-notifications";
import { customerText } from "../../../components/Seo/whitelabel/bybest/bybest-customer-layout";
import CustomerService from "../../../services/customer-service";
import CustomerFooterButtons from "../customer-footer-buttons";
import CustomerHeader from "../customer-header";

const ChangePassword = ({ buttonColor }) => {
  const [data, setData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [type, setType] = useState("password");
  const [newType, setNewType] = useState("password");
  const [conType, setConType] = useState("password");
  const [icon, setIcon] = useState(eyeOff);
  const [newIcon, setNewIcon] = useState(eyeOff);
  const [conIcon, setConIcon] = useState(eyeOff);
  const [loading, setLoading] = useState(false);
  const [subTitle, setSubTitle] = useState(customerText("lastPasswordUpdate"));

  const timeDifference = (date) => {
    const now = new Date();
    const diffInSeconds = Math.floor((now - new Date(date)) / 1000);

    const units = [
      { label: "year", seconds: 31536000 },
      { label: "month", seconds: 2592000 },
      { label: "day", seconds: 86400 },
      { label: "hour", seconds: 3600 },
      { label: "minute", seconds: 60 },
    ];

    for (const unit of units) {
      const interval = Math.floor(diffInSeconds / unit.seconds);
      if (interval >= 1) {
        return `${interval} ${unit.label}${interval !== 1 ? "s" : ""} ago`;
      }
    }

    return "just now";
  };

  useEffect(() => {
    // get login security activities
    CustomerService.getUserSecurityActivities()
      .then((response) => {
        if (response?.activities?.length > 0) {
          response?.activities
            ?.sort((a, b) => {
              return a.id - b.id;
            })
            .map((item) => {
              if (item.action === "Reset password") {
                setSubTitle(
                  "Last password updated " + timeDifference(item.created_at)
                );
              }
            });
        }
      })
      .catch((error) => {
        NotificationManager.error(
          error?.message || t("contact_us.somethingWentWrong"),
          customerText("error"),
          3000
        );
      });
  }, []);

  const handleToggle = () => {
    if (type === "password") {
      setIcon(eye);
      setType("text");
    } else {
      setIcon(eyeOff);
      setType("password");
    }
  };

  const handleConPasswordToggle = () => {
    if (conType === "password") {
      setConIcon(eye);
      setConType("text");
    } else {
      setConIcon(eyeOff);
      setConType("password");
    }
  };

  const handleNewPasswordToggle = () => {
    if (newType === "password") {
      setNewIcon(eye);
      setNewType("text");
    } else {
      setNewIcon(eyeOff);
      setNewType("password");
    }
  };

  const handleChange = (e) => {
    setData((info) => ({ ...info, [e.target.name]: e.target.value }));
  };

  const onSave = () => {
    setLoading(true);
    CustomerService.resetPassword(data)
      .then((response) => {
        NotificationManager.success(
          "Password reset successfully",
          "Success",
          3000
        );
        setLoading(false);
        setData({
          currentPassword: "",
          newPassword: "",
          confirmPassword: "",
        });
      })
      .catch((error) => {
        setLoading(false);
        NotificationManager.error(
          error?.message || "Something went wrong!",
          "Error",
          3000
        );
      });
  };

  return (
    <div>
      <CustomerHeader
        title={customerText("loginSecurityTitle")}
        description={subTitle}
        showItems={false}
        showHome={false}
      />
      <hr className="border-[1px] hidden md:block border-[#E5E7EB] my-5 " />

      <div className="w-full flex flex-col md:flex-row items-center mt-6 md:mt-0">
        <div className="w-full md:w-[30%] ">
          <p className="text-14 text-[#374151] font-semibold leading-5">
            {customerText("currentPassword")}
          </p>
        </div>
        <div className="w-full md:w-[70%]">
          <div className="w-full  h-[38px] px-5 border-[#CBD5E1] border-[1px] rounded-[5px] flex align-middle focus:border-blue-400 ">
            <input
              type={type}
              name="currentPassword"
              value={data?.currentPassword || ""}
              onChange={handleChange}
              autoComplete="current-password"
              className="flex-1 border-none focus-visible:border-none focus-visible:outline-none"
            />
            <span
              className="flex justify-around items-center cursor-pointer"
              style={{ color: "#CBD5E1" }}
              onClick={handleToggle}
            >
              <Icon icon={icon} size={18} />
            </span>
          </div>
        </div>
      </div>
      <hr className="border-[1px] hidden md:block border-[#E5E7EB] my-5 " />

      <div className="w-full flex flex-col md:flex-row items-center mt-6 md:mt-0">
        <div className="w-full md:w-[30%] ">
          <p className="text-14 text-[#374151] font-semibold leading-5">
            {customerText("newPassword")}
          </p>
        </div>
        <div className="w-full md:w-[70%]">
          <div className="w-full  h-[38px] px-5 border-[#CBD5E1] border-[1px] rounded-[5px] flex align-middle focus:border-blue-400 ">
            <input
              type={newType}
              name="newPassword"
              value={data?.newPassword || ""}
              onChange={handleChange}
              autoComplete="new-password"
              className="flex-1 border-none focus-visible:border-none focus-visible:outline-none"
            />
            <span
              className="flex justify-around items-center cursor-pointer"
              style={{ color: "#CBD5E1" }}
              onClick={handleNewPasswordToggle}
            >
              <Icon icon={newIcon} size={18} />
            </span>
          </div>
        </div>
      </div>
      <hr className="border-[1px] hidden md:block border-[#E5E7EB] my-5 " />

      <div className="w-full flex flex-col md:flex-row items-center mt-6 md:mt-0">
        <div className="w-full md:w-[30%] ">
          <p className="text-14 text-[#374151] font-semibold leading-5">
            {customerText("confirmPassword")}
          </p>
        </div>
        <div className="w-full md:w-[70%]">
          <div className="w-full  h-[38px] px-5 border-[#CBD5E1] border-[1px] rounded-[5px] flex align-middle focus:border-blue-400">
            <input
              type={conType}
              name="confirmPassword"
              value={data?.confirmPassword || ""}
              onChange={handleChange}
              className="flex-1 border-none focus-visible:border-none focus-visible:outline-none"
            />
            <span
              className="flex justify-around items-center cursor-pointer"
              style={{ color: "#CBD5E1" }}
              onClick={handleConPasswordToggle}
            >
              <Icon className="absolute " icon={conIcon} size={18} />
            </span>
          </div>
        </div>
      </div>
      <hr className="border-[1px] hidden md:block border-[#E5E7EB] my-5 " />

      <CustomerFooterButtons
        onSave={onSave}
        buttonColor={buttonColor}
        loading={loading}
      />
    </div>
  );
};

export default ChangePassword;
