import React from "react";
import { Table } from "antd";
import GenericPagination from "./generic-pagination";
import "./index.css";
import { useMatchMedia } from "../../services/hooks/use-match-media";

const GenericTable = ({
  columns = [],
  data = [],
  rowSelection,
  className,
  pagination = {
    current: 1,
    page: 1,
    total: data.length,
    pageSize: 10,
    total_pages: 1,
  },
  onDoubleClick,
  showPagination = true,
  loading = false,
  isBackendPaginated = false,
  onPageChange,
  setParams,
}) => {
  const isMobile = useMatchMedia();

  const modifiedColumns = columns.map((column, index) => {
    const isLastColumn = index === columns.length - 1;

    return {
      ...column,
      title: (
        <span
          className={`table-header-text ${
            isLastColumn ? "flex pr-4" : index === 0 ? "pl-2" : ""
          }`}
        >
          {column.title}
        </span>
      ),
      onHeaderCell: () => ({ className: "table-column-table" }),
      ellipsis: isMobile,
      render: (value, record) => {
        const content = column.render ? column.render(value, record) : value;

        return isLastColumn ? (
          <div className="flex">
            <div
              className={
                column?.dataIndex === "status"
                  ? "flex justify-start w-28 pr-4"
                  : "pr-4"
              }
            >
              <span className="w-full">{content}</span>
            </div>
          </div>
        ) : index === 0 ? (
          <div className="pl-2">{content}</div>
        ) : (
          content
        );
      },
    };
  });

  const handleRow = (record) => ({
    onDoubleClick: () => {
      if (onDoubleClick) {
        onDoubleClick(record);
      }
    },
  });

  return (
    <div className={isMobile ? "mobile-table-container" : "table-container"}>
      <Table
        {...{ rowSelection, className, loading }}
        dataSource={data}
        pagination={false}
        rowKey="id" // Assuming "id" is the unique key from backend
        onRow={handleRow}
        className={onDoubleClick ? "cursor-pointer !p-0" : "!p-0"}
        scroll={{ x: isMobile ? 800 : undefined }}
      >
        {modifiedColumns.map((column, index) => (
          <Table.Column key={column.key || index} {...column} />
        ))}
      </Table>

      {showPagination && (
        <GenericPagination
          {...pagination}
          isBackendPaginated={isBackendPaginated}
          onChange={onPageChange}
          setParams={setParams}
        />
      )}
    </div>
  );
};

export default GenericTable;
