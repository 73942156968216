import axios from "axios";
import React, { useEffect, useState } from "react";
import "react-notifications/lib/notifications.css";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import RouteNames from "../constants/route_names";
import { initCountry, initLanguage } from "../redux/actions/app";
import { loadLoginData } from "../redux/actions/auth";
import { loadCartItems } from "../redux/actions/cart";
import { initializeMixpanel } from "../utils/mixpanelUtil";
import { KEYS, setStorageKey } from "../utils/storage";
import "./index.css";
import AppRoutes from "./routes/appRoutes";

const App = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { isLoggedIn, isAffilate } = useSelector((state) => state.auth);
  const [appLoaded, setAppLoaded] = useState(false);

  const vbToken = localStorage.getItem("vbToken");
  // const external_id = createUUID();
  // const country = localStorage.getItem(KEYS.COUNTRY_CODE);

  useEffect(() => {
    getGeoInfo();
    loadSettings();
    initializeMixpanel();
    // handleFbService({
    //   event_name: "load home",
    //   external_id,
    //   country,
    // });
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      if (isAffilate && pathname === RouteNames.affiliates_login) {
        navigate(RouteNames.affiliates_dashboard);
      } else if (pathname === RouteNames.login && vbToken) {
        navigate(RouteNames.dashboard);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, isAffilate, pathname]);

  const getGeoInfo = () => {
    axios
      .get("https://ipapi.co/json/")
      .then(async (response) => {
        let data = response.data;
        setStorageKey(KEYS.COUNTRY_CODE, data.country);
      })
      .catch((error) => {
        setStorageKey(KEYS.COUNTRY_CODE, "US");

        // throw new Error("Error with getting country code based on ip");
      });
  };

  const loadSettings = async () => {
    try {
      dispatch(initLanguage());

      await dispatch(initCountry());
      await dispatch(loadCartItems());
      await dispatch(loadLoginData());
      setAppLoaded(true);
    } catch (error) {}
  };

  if (!appLoaded) {
    return null;
  }

  return <AppRoutes />;
};

export default App;
