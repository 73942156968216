import apiFactory from "../../utils/apiFactory";
import { API_AFFILIATES, API_Auth } from "../../constants/apiConfig";
import { APP_SET_LOGIN_USER, APP_SET_LOGOUT } from "./types";
import {
  KEYS,
  getStorageKey,
  removeStorageKey,
  setStorageKey,
} from "../../utils/storage";

export const login = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    apiFactory(
      { ...API_Auth, path: API_Auth.path + "/login-enduser", method: "post" },
      payload
    )
      .then(async (data) => {
        setStorageKey(KEYS.USER_DATA, { ...data, isAffilate: false });
        dispatch({
          type: APP_SET_LOGIN_USER,
          payload: { ...data, isAffilate: false },
        });
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const register = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    apiFactory(
      {
        ...API_Auth,
        path: API_Auth.path + "/register-enduser",
        method: "post",
      },
      payload
    )
      .then(async (data) => {
        setStorageKey(KEYS.USER_DATA, { ...data, isAffilate: false });
        dispatch({
          type: APP_SET_LOGIN_USER,
          payload: { ...data, isAffilate: false },
        });
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const logout = (payload) => (dispatch) => {
  const token = localStorage.getItem("vbToken");
  return new Promise(async (resolve, reject) => {
    apiFactory(
      {
        ...API_Auth,
        path: API_Auth.path + "/logout-enduser",
        method: "post",
        has_token: true,
      },
      payload,
      token
    )
      .then(async (data) => {
        removeStorageKey(KEYS.USER_DATA);
        dispatch({
          type: APP_SET_LOGOUT,
        });
        resolve(data);
      })
      .catch((error) => reject(error));
  });
};

export const registerAffiliate = (payload) => {
  return new Promise(async (resolve, reject) => {
    apiFactory(
      {
        ...API_AFFILIATES,
        path: API_AFFILIATES.path + "/register",
        method: "post",
      },
      payload
    )
      .then(async (data) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const loginAffiliate = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    apiFactory(
      { ...API_Auth, path: API_Auth.path + "/login-affiliate", method: "post" },
      payload
    )
      .then(async (data) => {
        setStorageKey(KEYS.USER_DATA, { ...data, isAffilate: true });
        dispatch({
          type: APP_SET_LOGIN_USER,
          payload: { ...data, isAffilate: true },
        });
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const logoutAffiliate = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    apiFactory(
      {
        ...API_Auth,
        path: API_Auth.path + "/logout-affiliate",
        method: "post",
        has_token: true,
      },
      payload
    )
      .then(async (data) => {
        removeStorageKey(KEYS.USER_DATA);
        dispatch({
          type: APP_SET_LOGOUT,
        });
        resolve(data);
      })
      .catch((error) => {
        removeStorageKey(KEYS.USER_DATA);
        dispatch({
          type: APP_SET_LOGOUT,
        });
        reject(error);
      });
  });
};

export const loadLoginData = () => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    const data = getStorageKey(KEYS.USER_DATA);
    if (data?.user != null && data?.access_token != null) {
      dispatch({
        type: APP_SET_LOGIN_USER,
        payload: data,
      });
    }
    resolve();
  });
};

export const resendEmailVerify = (payload) => {
  return new Promise(async (resolve, reject) => {
    apiFactory(
      {
        ...API_Auth,
        path: API_Auth.path + "/resend-verify-email",
        method: "post",
        has_token: true,
      },
      payload
    )
      .then(async (data) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const verifyEmail = (payload) => (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    apiFactory(
      { ...API_Auth, path: API_Auth.path + "/verify-email", method: "post" },
      payload
    )
      .then(async (data) => {
        const user_data = { ...getState().auth, user: data.user };
        setStorageKey(KEYS.USER_DATA, user_data);
        dispatch({
          type: APP_SET_LOGIN_USER,
          payload: user_data,
        });
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
