import React, { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import GoogleMapContainer from "../../components/GoogleMapContainer/index.js";
import { Seo } from "../../components/Seo";
import { SET_VENUE_DATA } from "../../redux/actions/types";
import WhiteLabelService from "../../services/whiteLabelService";
import { isEmpty } from "../../utils/common";
import { identifyVenue, trackEvent } from "../../utils/mixpanelUtil";
import AccommodationInformation from "../venue-accommodation/accommodation-information/index.js";
import AccommodationInformationTwo from "../venue-accommodation/accommodation-informationTwo/index.js";
import AccommodationLocation from "../venue-accommodation/accommodation-location/index.js";
import { location } from "../venue-accommodation/accommodation-location/location-functions.js";
import AccommodationQuality from "../venue-accommodation/accommodation-quality/index.js";
import AccommodationServices from "../venue-accommodation/accommodation-services/index.js";
import AboutHeader from "./about-header";
import { ACCOMMODATION_ID } from "../../components/Messages/react-web-gifted-chat/Constant.js";
import { getAccommodationText } from "../venue-accommodation/index.js";

const AboutSp = () => {
  const [details, setDetails] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = () => {
    WhiteLabelService.getDetails(ACCOMMODATION_ID).then(
      (res) => {
        if (res.allowed_only_from !== "vacation_rental") {
          navigate("/");
        } else {
          dispatch({ type: SET_VENUE_DATA, payload: res.venue });
          setDetails(res.venue);

          identifyVenue(ACCOMMODATION_ID);
          trackEvent("White Label - Accommodation", {
            action_category: "Page Load",
            action_outcome: "success",
            interaction_element: "screen",
            industry: "accommodation",
            source: "vb_web",
          });
        }
      },
      (error) => {
        NotificationManager.error(
          error?.message || getAccommodationText("error_msg_oops"),
          "Error",
          3000
        );

        identifyVenue(ACCOMMODATION_ID);
        trackEvent("White Label - Accommodation", {
          action_category: "Page Load",
          action_outcome: "fail",
          interaction_element: "screen",
          industry: "accommodation",
          source: "vb_web",
        });
      }
    );
  };

  const TagsBrand = details?.brand_profile?.filter(
    (item) => item.element_name === "Tags"
  )[0];
  const TagsButtonData = {
    text: isEmpty(
      TagsBrand?.venue_brand_profile_customizations?.find(
        (c) => c.customization_key === "text"
      )?.customization_value
    )
      ? TagsBrand?.default_name
      : TagsBrand?.venue_brand_profile_customizations?.find(
          (c) => c.customization_key === "text"
        )?.customization_value,
    bgColor: TagsBrand?.venue_brand_profile_customizations?.find(
      (c) => c.customization_key === "color"
    )?.customization_value,
    textColor: TagsBrand?.venue_brand_profile_customizations?.find(
      (c) => c.customization_key === "text_color"
    )?.customization_value,
  };

  return (
    <div className="flex flex-col w-full mt-[-32px]">
      <Seo
        title="About By Best Apartments | Your Stay in the Heart of Tirana"
        description="Discover By Best Apartments, where comfort meets style in Tirana's vibrant city center. Learn more about our mission to provide exceptional city-center stays."
        url="https://metrosuites.al/about"
      />
      <AboutHeader TagsButtonData={TagsButtonData} data={details} />

      <div className="w-full mb-8 px-4 sm:px-6 md:px-8 lg:px-[10%]">
        <div className=" mx-auto w-full my-8 md:my-16">
          <AccommodationQuality />
        </div>
      </div>

      <AccommodationServices />

      <div className="grid md:grid-cols-3 grid-cols-1 gap-5 mx-auto bg-[#F8F9FA] w-full py-16 pb-20 px-4 sm:px-6 md:px-8 lg:px-[10%]">
        <AccommodationLocation
          // openGoogleMaps={openGoogleMaps(ACCOMMODATION_ID)}
          location={location(ACCOMMODATION_ID)}
          vRlink={location(ACCOMMODATION_ID).vr_link}
          accId={ACCOMMODATION_ID}
          address={details?.overview?.address}
        />
        <AccommodationInformation
          accId={ACCOMMODATION_ID}
          // openGoogleMaps={openGoogleMaps}
          vRlink={location(ACCOMMODATION_ID).vr_link}
          // openVR={openVR()}
        />
        <AccommodationInformationTwo
          accId={ACCOMMODATION_ID}
          // openGoogleMaps={openGoogleMaps}
          vRlink={location(ACCOMMODATION_ID).vr_link}
          // openVR={openVR()}
        />
      </div>
      <div className="w-full pb-16 px-4 sm:px-6 md:px-8 lg:px-[10%] -mt-12">
        <GoogleMapContainer
          accId={ACCOMMODATION_ID}
          location={location(ACCOMMODATION_ID)}
          address={details?.overview?.address}
          showSearch={false}
        />
      </div>
    </div>
  );
};

export default AboutSp;
