export const style = (isMobile) => ({
  position: "fixed",
  bottom: 24,
  right: isMobile ? "1%" : 24,
  margin: 0,
  minWidth: isMobile ? "98%" : "40%",
  width: isMobile ? "90%" : "40%",
  minHeight: isMobile ? "70%" : "55%",
  bgcolor: "background.paper",
  borderRadius: "16px",
  boxShadow: 24,
  padding: "16px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  outline: "none",
});

export const mainBoxStyles = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingBottom: "8px",
  borderBottom: "1px solid #ddd",
  // minWidth: 700,
  maxHeight: "60vh",
  overflowY: "auto",
};

export const receievedMessageStyles = (msg) => ({
  width: 50,
  height: 50,
  backgroundColor: "#6DDFE2",
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "#FFFFFF",
  fontWeight: "bold",
  fontSize: "1.2rem",
  marginRight: !msg.sentByMe ? 0 : 1,
  alignSelf: "flex-end",
});

export const isTextStyles = (msg) => ({
  bgcolor: msg.sentByMe ? "#f7f7f9" : "#e5e5e5",
  color: "#000000",
  padding: 2,
  marginRight: !msg.sentByMe ? 0 : 1,
  marginLeft: !msg.sentByMe ? 1 : 0,
  borderRadius: 2,
  maxWidth: "75%",
  wordWrap: "break-word",
  position: "relative",
  mt: 2,
});

export const noTextStyles = (msg) => ({
  bgcolor: msg.sentByMe ? "#6DDFE2" : "#F5F5F5",
  color: msg.sentByMe ? "#FFFFFF" : "#000000",
  padding: "8px",
  marginRight: !msg.sentByMe ? 0 : 1,
  marginLeft: !msg.sentByMe ? 1 : 0,
  borderRadius: "12px",
  maxWidth: "75%",
  wordWrap: "break-word",
  position: "relative",
  mt: 2,
});

export const imageStyles = {
  borderRadius: "12px",
  width: "100%",
  height: "120px",
  objectFit: "cover",
};

export const createAtStyles = (msg) => ({
  display: "block",
  fontSize: "0.75rem",
  marginTop: 0.5,
  textAlign: msg.sentByMe ? "right" : "left",
  paddingLeft: !msg.sentByMe ? 6 : 0,
  marginBottom: "4px!important",
  marginRight: !msg.sentByMe ? 0 : 1,
  marginLeft: !msg.sentByMe ? 1 : 0,
});

export const menuIcon = (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.8516 8.52V3.98C22.8516 2.57 22.2116 2 20.6216 2H16.5816C14.9916 2 14.3516 2.57 14.3516 3.98V8.51C14.3516 9.93 14.9916 10.49 16.5816 10.49H20.6216C22.2116 10.5 22.8516 9.93 22.8516 8.52Z"
      stroke="#000929"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      opacity="0.4"
      d="M22.8516 19.77V15.73C22.8516 14.14 22.2116 13.5 20.6216 13.5H16.5816C14.9916 13.5 14.3516 14.14 14.3516 15.73V19.77C14.3516 21.36 14.9916 22 16.5816 22H20.6216C22.2116 22 22.8516 21.36 22.8516 19.77Z"
      stroke="#000929"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      opacity="0.4"
      d="M11.3516 8.52V3.98C11.3516 2.57 10.7116 2 9.12156 2H5.08156C3.49156 2 2.85156 2.57 2.85156 3.98V8.51C2.85156 9.93 3.49156 10.49 5.08156 10.49H9.12156C10.7116 10.5 11.3516 9.93 11.3516 8.52Z"
      stroke="#000929"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.3516 19.77V15.73C11.3516 14.14 10.7116 13.5 9.12156 13.5H5.08156C3.49156 13.5 2.85156 14.14 2.85156 15.73V19.77C2.85156 21.36 3.49156 22 5.08156 22H9.12156C10.7116 22 11.3516 21.36 11.3516 19.77Z"
      stroke="#000929"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
