import React from "react";
import "react-multi-carousel/lib/styles.css";
import { CarusellImages } from "./carusell-images";
import { getAccommodationText } from "..";

const AccommodationServices = () => {
  return (
    <div className="flex flex-col px-4 md:px-[10%] md:py-16 py-10">
      <h2 className="text-[#2D3748] text-[22px] md:text-[36px] font-normal leading-[120%] mb-2">
        {getAccommodationText("welcomeMessage")}
        <strong> {getAccommodationText("bybestApartments")}</strong>{" "}
        {getAccommodationText("whereGuests")}
      </h2>
      <span className="text-[18px] text-[#6E706E] mb-4">
        {getAccommodationText("memorableExperience")}
      </span>
      <CarusellImages />
    </div>
  );
};

export default AccommodationServices;
