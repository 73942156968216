import dayjs from "dayjs";
import { customerText } from "../../../components/Seo/whitelabel/bybest/bybest-customer-layout";

export const ApartmentCard = ({ apartment, onClick }) => {
  const statusColor = {
    Pending: "#f19802",
    Cancelled: "#f44336",
    Completed: "#12B76A",
    Active: "#12B76A",
  };

  const statusBgColor = {
    Pending: "#FFF4E3",
    Cancelled: "#FEE2E2",
    Completed: "#ECFDF3",
    Active: "#ECFDF3",
  };

  return (
    <div className="border rounded-lg flex gap-2 w-full shadow-lg cursor-pointer">
      <div
        onClick={() => onClick(apartment)}
        className="w-1/2 sm:w-1/4 aspect-square rounded-lg m-3 overflow-hidden"
      >
        <img
          src={apartment.rental_unit_photo}
          alt={apartment.rental_unit_name}
          className="object-cover"
        />
      </div>
      <div className="p-4">
        <h3
          onClick={() => onClick(apartment)}
          className="text-xl font-semibold"
        >
          {apartment.rental_unit_name}
        </h3>
        <p
          onClick={() => onClick(apartment)}
          className="text-sm text-[#667085] mt-2"
        >
          {dayjs(apartment.check_in).format("DD")}
          {"-"}
          {dayjs(apartment.check_out).format("DD")}{" "}
          {dayjs(apartment.check_out).format("MMM")}{" "}
          {dayjs(apartment.check_out).format("YYYY")} • {apartment.total}{" "}
          {apartment.currency}
        </p>
        <div
          onClick={() => onClick(apartment)}
          className="flex items-center gap-2 mt-2"
        >
          <span
            className="px-2 py-1 text-xs font-medium rounded-lg"
            style={{
              color: statusColor[apartment.status],
              backgroundColor: statusBgColor[apartment.status],
            }}
          >
            {apartment.status}
          </span>
        </div>
        <a
          target="_blank"
          rel="noreferrer"
          href={`https://metrosuites.al/rental/${apartment.rental_unit_code}`}
          className="flex mt-4 w-full py-2 px-3 text-white bg-[#6DDFE2] rounded-lg font-semibold"
        >
          {customerText("rebook_appartment")}
        </a>
      </div>
    </div>
  );
};
