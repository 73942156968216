import React, { useState, useEffect } from "react";
import { Form, Input, Modal, Select, Row, Col } from "antd";
import { CreditCardOutlined } from "@ant-design/icons";
import "./index.css";
import { customerText } from "../../../components/Seo/whitelabel/bybest/bybest-customer-layout";
import masterCard from "../../../assets/images/payment-cards/Mastercard.png";
import visaCard from "../../../assets/images/payment-cards/VISA.png";
import amex from "../../../assets/images/payment-cards/AMEX.png";
const { Option } = Select;
const countries = [
  { code: "AL", name: "Albania" },
  { code: "US", name: "United States" },
];

const AccAddCardModal = ({ showModal, onClose, onCardSaved }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(showModal);
  }, [showModal]);

  const [form] = Form.useForm();
  const [country, setCountry] = useState("AL");

  const handleCountryChange = (value) => {
    setCountry(value);
  };

  const cardSufix = (
    <div className="flex items-center gap-2">
      <img src={visaCard} alt="" />
      <img src={masterCard} alt="" />
      <img src={amex} alt="" />
    </div>
  );

  return (
    <Modal
      title={
        <div className="flex flex-col gap-2 mb-4">
          <span className="font-semibold text-bold text-[18px]">
            {customerText("addNewCard")}
          </span>
          <span className="text-sm font-[400]">
            {customerText("addNewCardDescription")}
          </span>
        </div>
      }
      centered
      open={open}
      onCancel={onClose}
      onOk={onCardSaved}
      maskClosable={false}
      okText={customerText("addCard")}
      cancelText={customerText("cancel")}
      okButtonProps={{
        style: { backgroundColor: "#6DDFE2", color: "white" },
      }}
    >
      <div
        className={
          "flex flex-col justify-center items-center py-4 w-full border-t"
        }
      >
        <Form form={form} layout="vertical">
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label={customerText("cardNumber")}>
                <Input
                  placeholder="1234 1234 1234 1234"
                  suffix={cardSufix}
                  style={{ height: "43px", padding: "10px" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={customerText("expiry")}>
                <Input
                  placeholder="MM / YY"
                  style={{ height: "43px", padding: "10px" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="CVC">
                <Input
                  placeholder="CVC"
                  style={{ height: "43px", padding: "10px" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={customerText("country")}
                className="acc-add-cart"
              >
                <Select
                  value={country}
                  onChange={handleCountryChange}
                  dropdownStyle={{ maxHeight: 200 }}
                  dropdownRender={(menu) => <div>{menu}</div>}
                  options={countries.map((country) => ({
                    value: country.code,
                    label: country.name,
                  }))}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={customerText("postalCode")}>
                <Input
                  placeholder="90210"
                  style={{ height: "43px", padding: "10px" }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

export default AccAddCardModal;
