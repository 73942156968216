/* eslint-disable react-hooks/exhaustive-deps */
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import React from "react";
import { customerText } from "../../../components/Seo/whitelabel/bybest/bybest-customer-layout";
import GenericTable from "../../../components/Table/generic-table";
import CustomerHeader from "../../customer/customer-header";
import { useGetPromotionsData } from "./data";

const CurrentPromotions = ({
  promotionParam,
  dataSource = {},
  handlePageChange,
  setParams,
}) => {
  const { currentPromotionsColumns } = useGetPromotionsData();

  return (
    <div className="col-span-2 w-full border rounded-[12px]">
      <div className="flex justify-between items-center w-full p-6">
        <CustomerHeader
          description={
            <span className="flex items-center gap-3">
              <span>{customerText("keep_track_active_promotions")}</span>
              <ArrowForwardIcon />
            </span>
          }
          numberOfItems={dataSource?.total}
          items={customerText("promotions")}
          title={customerText("currentPromotions")}
        />
      </div>
      <GenericTable
        columns={currentPromotionsColumns}
        data={dataSource?.data}
        className="w-full md:block pr-4"
        pagination={{
          ...promotionParam,
          onChange: handlePageChange,
        }}
        isBackendPaginated
        onPageChange={handlePageChange}
        setParams={setParams}
      />
    </div>
  );
};

export default CurrentPromotions;
