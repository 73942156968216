/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./index.css";

const CustomCalendar = ({
  data = [],
  promoCalendarClassName = "promo1",
  onSetSummary,
}) => {
  const [selectedDate, setSelectedDate] = useState(new Date());

  useEffect(() => {
    const booking = data.find(
      (item) =>
        new Date(item.start_time).toISOString().split("T")[0] ===
        selectedDate.toISOString().split("T")[0]
    );
    onSetSummary(booking);
  }, [selectedDate, data?.data]);

  const tileClassName = ({ date, view }) => {
    if (view === "month") {
      const dateStr = date.toISOString().split("T")[0];
      const booking = data.find(
        (item) =>
          new Date(item.start_time).toISOString().split("T")[0] === dateStr
      );

      if (booking) {
        return "calhighlight";
      }

      return null;
    }
  };

  return (
    <div className={`calendar-container !w-full ${promoCalendarClassName}`}>
      <Calendar
        onChange={setSelectedDate}
        value={selectedDate}
        tileClassName={tileClassName}
      />
    </div>
  );
};

export default CustomCalendar;
