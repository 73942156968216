import CircleIcon from "@mui/icons-material/Circle";
import { Avatar, Box, IconButton, Typography } from "@mui/material";
import React from "react";
import { customerText } from "../../../../components/Seo/whitelabel/bybest/bybest-customer-layout";
import "../index.css";
import { mainBoxStyles, menuIcon } from "../styles";
import headerMenu from "../../../../assets/images/bbApartments.png";

const HeaderChatSection = () => {
  return (
    <Box sx={mainBoxStyles}>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <div className="border-2 rounded-[50%] h-full w-full flex justify-center items-center avatar-image">
          <Avatar src={headerMenu} alt="Support Avatar" />
        </div>
        <Box>
          <Typography variant="body1" fontWeight="bold">
            Support
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <CircleIcon
              fontSize="small"
              sx={{ color: "green", marginRight: "4px" }}
            />
            <Typography variant="caption" sx={{ color: "green" }}>
              {customerText("online")}
            </Typography>
          </Box>
        </Box>
      </Box>
      <IconButton>{menuIcon}</IconButton>
    </Box>
  );
};

export default HeaderChatSection;
