import React, { useState, useEffect } from "react";
import "./index.css";
import CustomerHeader from "../customer-header";
import { customerText } from "../../../components/Seo/whitelabel/bybest/bybest-customer-layout";
import CustomerService from "../../../services/customer-service";
import { NotificationManager } from "react-notifications";
import { t } from "i18next";

const LoginSecurityActivities = () => {
  const [loginSecurityActivities, setLoginSecurityActivities] = useState([]); // array of login security activities

  useEffect(() => {
    // get login security activities
    CustomerService.getUserSecurityActivities()
      .then((response) => {
        setLoginSecurityActivities(response?.activities || []);
      })
      .catch((error) => {
        NotificationManager.error(
          error?.message || t("contact_us.somethingWentWrong"),
          customerText("error"),
          3000
        );
      });
  }, []);

  return (
    <div className="login-security">
      <CustomerHeader
        title={customerText("loginSecurityAndActivitiesTitle")}
        description={customerText("lastLoginNow")}
        showItems={false}
        showHome={false}
      />
      <hr className="border-[1px] hidden md:block border-[#E5E7EB] w-full " />
      <span className="activities">{customerText("activitiesCapital")}</span>
      <hr className="border-[1px] hidden md:block border-[#E5E7EB] w-full " />
      {loginSecurityActivities.map((item, index) => (
        <>
          <span className="login-item">{`${item.action} on ${new Date(
            item.created_at
          ).toLocaleDateString()}, IP ${item.ip}, on ${item.os}`}</span>
          {index !== loginSecurityActivities.length - 1 && (
            <hr className="border-[1px] hidden md:block border-[#E5E7EB] w-full " />
          )}
        </>
      ))}
    </div>
  );
};

export default LoginSecurityActivities;
