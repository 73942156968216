import React from "react";
import BybestCustomerLayout, {
  customerText,
} from "../../../components/Seo/whitelabel/bybest/bybest-customer-layout";
import "../../customer/login-security/index.css";
import CustomerHeader from "../../customer/customer-header";
import LoginSecurityActivities from "../../customer/login-security/login-security-activities";
import ChangePassword from "../../customer/login-security/change-password";

const AccSecurity = () => {
  return (
    <div className="p-6 gap-2 flex flex-col bg-white">
      <CustomerHeader
        title={customerText("loginSecurityTitle")}
        description={customerText("loginSecurityDescription")}
        showItems={false}
      />
      <hr className="border-[1px]  border-[#E5E7EB] my-5" />

      <div className="grid lg:grid-cols-2 gap-5">
        <ChangePassword buttonColor={"#6DDFE2"} />
        <LoginSecurityActivities />
      </div>
    </div>
  );
};

export default AccSecurity;
