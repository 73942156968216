import React, { Suspense, useEffect } from "react";
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import { Outlet, useNavigate } from "react-router-dom";
import ScrollToTop from "../../components/Hooks/scrolltoTop";
import ScrollToTopButtonByBest from "../../components/ScrollToUpButton/scroll-to-top-by-best";
import { useLocalStorage } from "../../services/hooks/use-localstorage";
import { useLogout } from "../../services/hooks/use-logout";
import { useMatchMedia } from "../../services/hooks/use-match-media";
import WhiteLabelService from "../../services/whiteLabelService";
import BrandFooter from "../footer/BrandFooter";
import AccommodationHeader from "../header/AccommodationHeader";
const LazyFooter = React.lazy(() => import("../footer/BBAparmentsFooter"));

const AccommodationLayout = () => {
  const accToken = Boolean(localStorage.getItem("accToken"));
  const handleLogout = useLogout(false);
  const isMobile = useMatchMedia();
  const navigate = useNavigate();
  const [authResponse] = useLocalStorage("authResponse", null);

  const refreshAccessToken = () => {
    WhiteLabelService.refreshAccessToken()
      .then((res) => {
        const newExpiresAt = Math.floor(Date.now() / 1000) + 3600;
        localStorage.setItem(
          "authResponse",
          JSON.stringify({
            ...authResponse,
            ...res,
            expires_at: res?.expires_at ?? newExpiresAt,
          })
        );
        localStorage.setItem("refreshToken", res.refresh_token);
        localStorage.setItem("accToken", res.access_token ?? res.token);
      })
      .catch(() => handleLogout());
  };

  useEffect(() => {
    if (!accToken) {
      navigate(`/login`);
    }

    const checkTokenAndRefresh = () => {
      const expiresAt = authResponse?.expires_at
        ? Number(authResponse.expires_at)
        : 0;

      if (Date.now() >= expiresAt * 1000) {
        refreshAccessToken();
      }
    };

    const intervalId = setInterval(checkTokenAndRefresh, 60 * 60 * 1000);

    checkTokenAndRefresh();

    return () => clearInterval(intervalId);
  }, [accToken, authResponse]);

  return (
    <>
      <ScrollToTopButtonByBest buttonClassName={"!bg-[#6DDFE2]"} />
      <AccommodationHeader isAuth={accToken} />
      <div
        className={
          accToken
            ? `${
                isMobile ? "pl-[16px]" : "pl-[306px]"
              } pl-[16px] pr-[16px] pt-4 h-full w-full bg-[#f3f3f3]`
            : ""
        }
        style={{ minHeight: "calc(100vh - 198px)" }}
      >
        <Outlet />
      </div>
      <Suspense fallback={<div>Loading footer...</div>}>
        {accToken ? <LazyFooter /> : <BrandFooter isAccApp={true} />}
      </Suspense>

      <NotificationContainer />
      <ScrollToTop />
    </>
  );
};

export default AccommodationLayout;
