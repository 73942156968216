/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import BBApartments from "../../../../assets/images/bbApartments.png";
import cardImg from "../../../../assets/images/payment-cards/Mastercard.png";
import { ReactComponent as BookIcon } from "../../../../assets/svgs/book.svg";
import { customerText } from "../../../../components/Seo/whitelabel/bybest/bybest-customer-layout";
import CustomerService from "../../../../services/customer-service";
import ChatModal from "../../../customer/chat/chat-modal";
import CustomerHeader from "../../../customer/customer-header";
import { chatIcon } from "../../../customer/orders/icons";
import {
  calendarIcon,
  giftIcon,
  handMoney,
  keyIcon,
  locationIcon,
  recieptIcon,
  refundIcon,
  userIcon,
} from "./icons";
import "./index.css";

const AccBookingDetails = () => {
  const { bookingId } = useParams();
  const [bookData, setBookData] = useState({});
  const [open, setOpen] = useState(false);
  const [chatId, setChatId] = useState();

  const currency = {
    EUR: "€",
    USD: "$",
  };

  const { paymentMethod, reservations } = useMemo(() => {
    const reservations = [
      {
        icon: calendarIcon("#6DDFE2"),
        title: `${customerText("dates")}: Tue, 16 Jul 2024 - Wed, 17 Jul 2024`,
        body: (
          <>
            <span>Check-in: {bookData?.check_in}</span>
            <span>Check-out: {bookData?.check_out}</span>
          </>
        ),
      },
      {
        icon: keyIcon("#6DDFE2"),
        title: customerText("check_in_info"),
        body: (
          <>
            <span>{customerText("info_from_host")}</span>

            <a
              className="text-[#6DDFE2] cursor-pointer"
              href={`https://metrosuites.al/rental/${bookData.unit_code}`}
              target="_blank"
              rel="noreferrer"
            >
              {customerText("view_details")}
            </a>
          </>
        ),
      },
    ];

    const paymentMethod = [
      {
        icon: userIcon("#6DDFE2"),
        title: customerText("total_amount"),
        body: (
          <div className="flex gap-2">
            <span className="text-2xl font-semibold">{`${
              currency[bookData?.currency]
            }${bookData?.total}`}</span>
            {bookData?.booking?.discount_price &&
              bookData?.booking?.discount_price !== "0.00" && (
                <span className="px-2 py-1 font-bold text-sm flex items-center rounded-full bg-[#E148481C] text-[#E14848]">
                  {`${currency[bookData?.currency]}${
                    bookData?.booking?.discount_price
                  }`}{" "}
                  {customerText("discount_label")}
                </span>
              )}
          </div>
        ),
      },
      {
        icon: recieptIcon("#6DDFE2"),
        title:
          bookData?.booking?.paid_with === "cash"
            ? customerText("paid_with_cash")
            : customerText("paid_with_card"),
        body: bookData?.booking?.paid_with !== "cash" && (
          <div className="flex gap-2 items-center border rounded-md px-4 py-2 w-fit">
            <img src={cardImg} alt="" />
            &#x2022; &#x2022; &#x2022; &#x2022;&#x2022;&#x2022;&#x2022;&#x2022;{" "}
            {bookData?.booking?.last_four_digit}
          </div>
        ),
        subtitle:
          bookData?.booking?.paid_with === "cash"
            ? customerText("paid_with_cash_subtitle")
            : "",
      },
    ];

    return { reservations, paymentMethod };
  }, [bookData]);

  const statusColor = {
    Pending: "#f19802",
    Cancelled: "#f44336",
    Completed: "#12B76A",
    Active: "#12B76A",
  };

  const statusBgColor = {
    Pending: "#FFF4E3",
    Cancelled: "#FEE2E2",
    Completed: "#ECFDF3",
    Active: "#ECFDF3",
  };

  useEffect(() => {
    CustomerService.getBookingById(bookingId).then((res) => {
      setBookData(res);
      setChatId(res?.chat_id);
    });
  }, [bookingId]);

  const CardComponent = ({ title, icon, body, subtitle }) => {
    return (
      <div className="flex flex-col gap-1 w-full border shadow-md rounded-lg p-6">
        <div className="flex gap-3 font-semibold">
          <span>{icon}</span>
          <span>{title}</span>
        </div>
        {subtitle && (
          <div className="ml-7 text-sm text-gray-500">{subtitle}</div>
        )}
        <div className="ml-7 flex flex-col gap-1">{body}</div>
      </div>
    );
  };

  const status = bookData?.status ?? bookData?.booking?.status;

  return (
    <div className="bg-white rounded-lg">
      <div className="flex flex-1 flex-col">
        <div className="w-full boxshadow border-[1px] border-[#EAECF0] rounded-lg">
          <div className="grid md:flex justify-between items-center w-full border p-6">
            <CustomerHeader
              breadCrumb={
                <>
                  <p className="text-[#6B7280] text-20 font-medium leading-5">{`>`}</p>
                  <p className="text-[#6B7280] text-14 font-medium leading-5">
                    {`${customerText("bookings")} > ${
                      bookData?.rental_unit_name
                    }`}
                  </p>
                </>
              }
              title={
                <div className="flex flex-col sm:flex-row gap-3 sm:items-center">
                  <span>{customerText("your_acc_booking")}</span>
                  <div className="status-card ">
                    {/* <span className="text-sm">{bookData?.id}</span> */}
                    <span
                      className="px-2 py-1 text-xs font-medium rounded-lg"
                      style={{
                        color: statusColor[status],
                        backgroundColor: statusBgColor[status],
                      }}
                    >
                      {status}
                    </span>
                  </div>
                </div>
              }
              showItems={false}
            />
            <div className="flex flex-col gap-5 items-start md:items-end w-full">
              <div className="flex items-center gap-2">
                <a
                  href={`https://metrosuites.al/rental/${bookData?.unit_code}`}
                  target="_blank"
                  rel="noreferrer"
                  className="status-card cursor-pointer"
                >
                  <BookIcon />
                  <span>{customerText("rebook_appartment")}</span>
                </a>
                <div
                  className="status-card cursor-pointer"
                  onClick={() => setOpen(true)}
                >
                  <span>{chatIcon}</span>
                  <span>{customerText("chatNow")}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white p-6 flex flex-col sm:flex-row gap-4 items-center">
            <div className="text-[#101828] text-18 font-semibold leading-7">
              {bookData?.rental_unit_name}
            </div>
            <div className="flex flex-col sm:flex-row gap-3 items-center">
              <div className="flex gap-3 items-center">
                {locationIcon("#6DDFE2")}
                <span>{bookData?.booking?.guest?.address}</span>
              </div>

              <a
                href={`https://metrosuites.al/rental/${bookData.unit_code}`}
                className="text-[#6DDFE2]"
                target="_blank"
                rel="noreferrer"
              >
                {customerText("get_direction")}
              </a>
            </div>
          </div>

          <div className="grid sm:grid-cols-2 gap-6 p-6 !pt-0 text-sm">
            {reservations.map((item) => (
              <CardComponent {...item} />
            ))}
          </div>

          <div className="text-[#101828] text-18 font-semibold leading-7 p-6 pt-0">
            {customerText("booking_details")}
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 p-6 !pt-0 text-sm">
            {[
              {
                icon: userIcon("#6DDFE2"),
                title: customerText("nr_of_guests"),
                body: `${bookData?.guest_count} ${customerText("guests")}`,
              },
              {
                icon: giftIcon("#6DDFE2"),
                title: customerText(
                  bookData?.booking?.discount_id ? "benefits" : "no_benefits"
                ),
                subtitle: !bookData?.booking?.discount_id
                  ? customerText("no_benefits_subtitle")
                  : undefined,
                body: bookData?.booking?.discount_id ? (
                  `${bookData?.booking?.discount?.value} ${
                    bookData?.booking?.discount?.type === "percentage"
                      ? "%"
                      : `${currency[bookData?.currency]}`
                  } ${customerText("benefits_description")}`
                ) : (
                  <></>
                ),
              },
            ].map((item) => (
              <CardComponent {...item} />
            ))}
            <div className="sm:col-span-2">
              <CardComponent
                icon={refundIcon("#6DDFE2")}
                title={customerText("partially_refundable")}
                body={customerText("refund_policy")}
              />
            </div>
          </div>

          <div className="text-[#101828] text-18 font-semibold leading-7 p-6 pt-0">
            {customerText("payment_details")}
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 p-6 !pt-0 text-sm">
            {paymentMethod.map((item) => (
              <CardComponent {...item} />
            ))}
          </div>

          <div className="p-6 pt-0">
            <div className="bg-[#6DDFE236] p-6 rounded-[12px] border border-[#6DDFE2]">
              <p>{customerText("points_earned")}</p>
            </div>
          </div>
          <div className="p-6 pt-0">
            <CardComponent
              icon={handMoney("#6DDFE2")}
              title={customerText("prepayment")}
              body={customerText("prepayment_description")}
            />
          </div>
          <ChatModal
            handleClose={() => setOpen(false)}
            open={open}
            iconUrl={BBApartments}
            colorTheme={"#6DDFE2"}
            chatId={chatId}
          />
        </div>
      </div>
    </div>
  );
};

export default AccBookingDetails;
