export const location = (accommodation_id) => ({
  latitude: accommodation_id === "OAS9218APPU" ? "41.3235445" : "41.3235445",
  longitude: accommodation_id === "OAS9218APPU" ? "19.8130371" : "19.8130371",
  vr_link: "https://tourmake.net/bybest_apartments",
});

export const openGoogleMaps = (accommodation_id) => {
  // if (Object.keys(location.length > 0)) {
  //   let url = "";
  //   if (accommodation_id !== "OAS9218APPU") {
  //     url =
  //       "https://www.google.com/maps/place/By+Best+Apartments/@41.3235485,19.8104622,17z/data=!3m1!4b1!4m9!3m8!1s0x13503103b02e8c85:0x36065537ae782fb2!5m2!4m1!1i2!8m2!3d41.3235445!4d19.8130371!16s%2Fg%2F11fnv_x1b4?entry=ttu";
  //   } else {
  //     url = `https://www.google.com/maps/place/W+Blaine+St,+Riverside,+CA+92507,+USA/@33.9829769,-117.3310023,17z/data=!3m1!4b1!4m6!3m5!1s0x80dcae42840b13f7:0xe25f766bdd0b92a6!8m2!3d33.9829769!4d-117.3284274!16s%2Fg%2F1tcwx07t?entry=ttu`;
  //   }
  //
  //   window.open(url, "_blank", "location=yes");
  // }
};

export const openVR = () => {
  console.log('1');
  // window.open(location.vr_link, "_blank", "location=yes");
};
