import React, { useMemo } from "react";
import Footerlogo from "../../assets/svgs/footer-icon.png";
import { useSelector } from "react-redux";
import { isEmpty } from "../../utils/common";
import { useLocation } from "react-router-dom";
import { byBestRetailSegments } from "../data";
import { appKey } from "../../components/Sidebar/data";
import Svg_fb from "../../assets/svgs/bybest/bb-facebook.svg";
import Svg_inst from "../../assets/svgs/bybest/bb-instagram.svg";
import { getAccommodationText } from "../../views/venue-accommodation";
import GoogleReview from "../../components/google-review";

const socials = [
  {
    href: "https://www.facebook.com/bybestapartments/",
    src: Svg_fb,
    label: "Facebook",
  },
  {
    href: "https://www.instagram.com/by_best_apartments/",
    src: Svg_inst,
    label: "Instagram",
  },
];
const BrandFooter = ({ isAccApp = false }) => {
  const { pathname } = useLocation();
  const venueData = useSelector((state) => state.venue);
  const bgColor = useMemo(() => {
    const btnData = venueData?.brand_profile?.find(
      (b) => b.element_name === "Footer"
    );
    return btnData?.venue_brand_profile_customizations?.find(
      (c) => c.customization_key === "color"
    )?.customization_value;
  }, [venueData]);

  const hideFooter =
    pathname.includes("/beach-bar-order") ||
    pathname.includes("/venue/restaurant");

  const isLoggedInUser = pathname.includes("/customer/");
  const isPathnameMatch = () => {
    return byBestRetailSegments.some((segment) => {
      return (
        pathname.includes(`${appKey}/${segment}`) ||
        pathname.includes(`${appKey}/customer/${segment}`)
      );
    });
  };

  return (isLoggedInUser || isPathnameMatch()) && !isAccApp ? (
    <div className={isPathnameMatch() ? "hidden" : "h-5 border"}></div>
  ) : (
    <div className={`align-col-middle border ${hideFooter ? "!hidden" : ""}`}>
      <div className="py-12 w-full mx-auto flex flex-col lg:flex-row justify-between px-4 sm:px-6 md:px-8 lg:px-[10%]">
        <div className="lg:w-[55%]">
          <div className="flex flex-col justify-between gap-6 w-full lg:max-w-[313px] h-full">
            <div className="flex flex-col gap-3">
              <h4 className="text-[18px] font-bold text-[#101113]">
                <span className="text-[#6DDFE2]">
                  {getAccommodationText("luxuryInTiranaTitle")}
                </span>
              </h4>
              <p className="text-[15px] text-[#5C5F66] leading-[18px]">
                {getAccommodationText("luxuryInTirana")}
              </p>
            </div>
            <a
              rel="noreferrer"
              href="https://www.google.com/search?sca_esv=aaaa9a10aaa1b9d1&hl=en-GR&tbm=lcl&q=By+Best+Apartments+Reviews&rflfq=1&num=20&stick=H4sIAAAAAAAAAONgkxI2trA0AiIzI1NTE3MDEzMLY5MNjIyvGKWcKhWcUotLFBwLEotKclPzSooVglLLMlPLixex4pEEAMjFLyhUAAAA&rldimm=3892892625547046834&sa=X&ved=2ahUKEwiE5Iv196SIAxXQSfEDHX67DqIQ9fQKegQIHxAF&biw=1470&bih=762&dpr=2#lkt=LocalPoiReviews"
              target="_blank"
              className="flex lg:justify-end justify-center w-full"
            >
              <GoogleReview className="#6DDFE2 4px solid" rating={"4.8"} />
            </a>
          </div>
        </div>
        <div className="lg:w-[55%]">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            <div className="flex flex-col gap-3">
              <p className="text-[16px] md:mt-0 mt-4 text-[#101113] font-bold">
                {getAccommodationText("primeLocationTitle")}
              </p>
              <p className="text-[15px] text-[#5C5F66] font-normal max-w-[410px] leading-[18px]">
                {getAccommodationText("primeLocation")}
              </p>
            </div>
            <div className="flex flex-col gap-3">
              <p className="text-[16px] text-[#101113] font-bold">
                {getAccommodationText("personalizedServiceTitle")}
              </p>
              <p className="text-[15px] text-[#5C5F66] font-normal max-w-[410px] leading-[18px]">
                {getAccommodationText("personalizedService")}
              </p>
            </div>
            <div className="flex flex-col gap-3">
              <p className="text-[16px] text-[#101113] font-bold">
                {getAccommodationText("exclusivePerksTitle")}
              </p>
              <p className="text-[15px] text-[#5C5F66] font-normal max-w-[410px] leading-[18px]">
                {getAccommodationText("exclusivePerks")}
              </p>
            </div>
            <div className="flex flex-col gap-3">
              <p className="text-[16px] text-[#101113] font-bold">
                {getAccommodationText("followUs")}
              </p>
              <ul className="flex flex-row gap-4 lg:mt-4 mt-0 lg:mb-0 mb-3">
                {socials.map(({ href, src, label }, index) => (
                  <a
                    key={index}
                    rel="noreferrer"
                    href={href}
                    aria-label={`VenueBoost ${label} Account`}
                    target="_blank"
                  >
                    <img alt={`${label} Icon`} src={src} />
                  </a>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div
        className="bg-primary1 w-full"
        style={
          bgColor != null
            ? { backgroundColor: bgColor, height: "60px" }
            : { height: "60px" }
        }
      >
        <div className="flex h-full w-full">
          <div
            className={`relative mx-auto w-full px-4 sm:px-6 md:px-8 lg:px-[10%] flex justify-between items-center`}
          >
            <div className="flex flex-col md:items-start w-1/2">
              <div
                className={` flex justify-center md:items-start flex-col md:flex-row w-1/2 h-3/5"
                     md:block md:w-[20%]`}
              >
                <div>
                  <img
                    src={
                      !isEmpty(venueData?.overview?.logo) &&
                      venueData?.full_whitelabel
                        ? venueData?.overview?.logo
                        : Footerlogo
                    }
                    alt="VB Logo"
                    style={{ width: "30%" }}
                  />
                </div>
              </div>
            </div>
            <div className="text-white w-full flex justify-end">
              <span>Powered by VenueBoost</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandFooter;
