export const byBestRetailSegments = [
  "login",
  "order/tracking",
  "my-club",
  "cart",
  "checkout",
  "payment",
  "success-checkout",
  "products",
  "brand",
  "category",
  "collection",
  "group",
  "wishlist",
  "blog/category",
  "search",
  "virtual-gift-card",
  "page",
  "return-policy",
  "help",
  "frequently-asked-questions",
  "job-listing",
  "contact-us",
  "about-us",
  "stores-and-hours",
  "privacy-policy",
  "cookies",
  "prices-and-currency",
  "payment-methods",
  "shipping-methods",
  "product-exchange",
  "personal-information",
];
