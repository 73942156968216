import { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./index.css";

export const SidebarLink = ({
  href,
  icon,
  label,
  selected,
  onItemClick,
  setOpen,
  prefix,
  linkKey,
  ativeLinkClassNames,
}) => {
  const navigate = useNavigate();

  const handleClick = useCallback(
    (e) => {
      e.preventDefault();

      if (onItemClick) {
        onItemClick(e);
      } else if (href === "/" || linkKey === "home") {
        navigate("/");
      } else {
        navigate(`${prefix ?? ""}/customer${href}`);
        setOpen?.(false);
      }
    },
    [href]
  );

  return (
    <div
      className={`sidebar-item cursor-pointer ${
        selected ? `sidebar-item-active ${ativeLinkClassNames ?? ""}` : ""
      }`}
      onClick={handleClick}
    >
      <div className="flex items-center gap-2">
        <span>{icon}</span>
        <p className="text-[#191D23] text-[13px] font-semibold whitespace-nowrap">
          {label}
        </p>
      </div>
    </div>
  );
};
