import { Box, Modal } from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { MSG_TYPE_TEXT } from "../../../components/Messages/react-web-gifted-chat/Constant";
import CustomerService from "../../../services/customer-service";
import { useLocalStorage } from "../../../services/hooks/use-localstorage";
import { useMatchMedia } from "../../../services/hooks/use-match-media";
import FooterChatSection from "./components/footer-chat-section";
import HeaderChatSection from "./components/header-chat-section";
import "./index.css";
import {
  createAtStyles,
  imageStyles,
  isTextStyles,
  noTextStyles,
  receievedMessageStyles,
  style,
} from "./styles";

const ChatModal = ({ open, handleClose, chatId }) => {
  const [authResponse] = useLocalStorage("authResponse", null);
  const userData = authResponse?.user;
  const isMobile = useMatchMedia();

  const [loading, setLoading] = useState(false);
  const [conversation, setConversation] = useState([]);
  const endOfMessagesRef = useRef(null);

  const senderId = userData?.customer?.user_id ?? userData?.id;

  const updateChat = () => {
    CustomerService.getMessages(chatId, senderId)
      .then((newMessages) => {
        setConversation(newMessages);
      })
      .catch(() => {
        setConversation([]);
      });
  };

  const chatMessages = useMemo(() => conversation, [conversation?.length]);

  useEffect(() => {
    if (chatId) {
      updateChat();

      const intervalId = setInterval(() => {
        updateChat();
      }, 2000);

      if (!open) {
        clearInterval(intervalId);
      }

      return () => clearInterval(intervalId);
    }
  }, [chatId, open, userData?.customer?.user_id]);

  useEffect(() => {
    if (endOfMessagesRef.current) {
      endOfMessagesRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [chatMessages]);

  const onSendMsg = (inputMsg) => {
    if (inputMsg) {
      const newMessage = {
        sender_id: senderId,
        chat_id: chatId,
        venue_id: userData?.venue?.id,
        type: inputMsg.type,
        content: inputMsg.type === MSG_TYPE_TEXT ? inputMsg.content : "",
        ...(inputMsg.type === "image" && inputMsg.content?.file != null
          ? { image: inputMsg.content?.file }
          : {}),
      };

      let formData = new FormData();
      if (inputMsg.type === "image" && inputMsg.content?.file != null) {
        formData.append("image", inputMsg.content?.file);
      }
      formData.append("sender_id", senderId);
      formData.append("chat_id", chatId);
      formData.append("venue_id", userData?.venue?.id);
      formData.append("type", inputMsg.type);
      formData.append(
        "content",
        inputMsg.type == MSG_TYPE_TEXT ? inputMsg.content : ""
      );

      setConversation([
        ...conversation,
        {
          ...newMessage,
          id: conversation.length + 1,
          type: MSG_TYPE_TEXT,
          sentByMe: true,
          // created_at: new Date(),
        },
      ]);

      setLoading(true);
      CustomerService.sendMessage(formData)
        .then((res) => {
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-chat-title"
      aria-describedby="modal-chat-description"
      disableEnforceFocus
      keepMounted
    >
      <Box sx={style(isMobile)}>
        <HeaderChatSection />

        <Box
          flexGrow={1}
          display="flex"
          flexDirection="column"
          sx={{
            marginTop: 2,
            marginBottom: 4,
            maxHeight: isMobile ? "50vh" : "60vh",
            overflowY: "auto",
          }}
          className="chat-container"
        >
          {!conversation || conversation === undefined ? (
            <div className="spinner" id="spinner"></div>
          ) : (
            conversation.map((msg) => (
              <div
                key={msg.id}
                style={{ opactiy: msg?.created_at ? "1" : "0.6" }}
                className="flex flex-col"
              >
                <Box
                  display="flex"
                  flexDirection={msg.sentByMe ? "row-reverse" : "row"}
                  alignItems="flex-start"
                >
                  {!msg.sentByMe && (
                    <Box sx={receievedMessageStyles(msg)}>GG</Box>
                  )}
                  {msg.type === MSG_TYPE_TEXT ? (
                    <Box sx={isTextStyles(msg)}>
                      <span>{msg.content ?? msg?.text}</span>
                    </Box>
                  ) : (
                    <Box sx={noTextStyles(msg)}>
                      <img alt="" src={msg.content} style={imageStyles} />
                    </Box>
                  )}
                </Box>
                {msg.created_at && (
                  <Box component="span" sx={createAtStyles(msg)}>
                    {new Date(msg.created_at).toLocaleTimeString()}
                  </Box>
                )}
              </div>
            ))
          )}
          <div ref={endOfMessagesRef} />
        </Box>

        <FooterChatSection {...{ onSendMsg, loading }} />
      </Box>
    </Modal>
  );
};

export default ChatModal;
