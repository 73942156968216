// AuthRoutes.js
import React from "react";
import { Route } from "react-router-dom";
import AccBookingDetails from "../../views/accommodation-customer/bookings/booking-details/index.jsx";
import AccBookings from "../../views/accommodation-customer/bookings/index.jsx";
import AccPersonalInformation from "../../views/accommodation-customer/personal-info/index.jsx";
import AccPromotions from "../../views/accommodation-customer/promotions/index.jsx";
import AccSecurity from "../../views/accommodation-customer/security/index.jsx";
import AccWallet from "../../views/accommodation-customer/wallet/index.jsx";

const byBestAccommodationRoutes = [
  <Route exact path="customer/login-security" element={<AccSecurity />} />,
  <Route exact path={"customer/bookings"} element={<AccBookings />} />,
  <Route
    exact
    path={"customer/personal-info"}
    element={<AccPersonalInformation />}
  />,
  <Route
    exact
    path={"customer/bookings/:bookingId"}
    element={<AccBookingDetails />}
  />,
  <Route exact path={"customer/promotions"} element={<AccPromotions />} />,
  <Route exact path={"customer/wallet"} element={<AccWallet />} />,
];

export default byBestAccommodationRoutes;
