import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import PropTypes from "prop-types";

export const Seo = ({ title, description, url, image }) => {
    // Hardcoded fallback values from public index.html
    const defaultTitle = "By Best Apartments | Discover urban convenience in the heart of Tirana";
    const defaultDescription = "Experience city-center living in our five exquisite apartments, each offering a unique blend of comfort and style, steps away from Tirana's vibrant core.";
    const defaultUrl = "https://metrosuites.al";
    const defaultImage = "https://metrosuites.al/bybest-apartments-share.jpg";

    return (
        <HelmetProvider>
            <Helmet>
                <title>{title || defaultTitle}</title>
                <meta name="description" content={description || defaultDescription} />
                <link rel="canonical" href={url || defaultUrl} />

                {/* Open Graph Meta Tags */}
                <meta property="og:title" content={title || defaultTitle} />
                <meta property="og:description" content={description || defaultDescription} />
                <meta property="og:url" content={url || defaultUrl} />
                <meta property="og:image" content={image || defaultImage} />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="By Best Apartments" />

                {/* Twitter Meta Tags */}
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:title" content={title || defaultTitle} />
                <meta name="twitter:description" content={description || defaultDescription} />
                <meta name="twitter:image" content={image || defaultImage} />
            </Helmet>
        </HelmetProvider>
    );
};

Seo.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    url: PropTypes.string,
    image: PropTypes.string,
};

export default Seo;
