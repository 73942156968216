import React, { useEffect, useState } from "react";
import CustomerHeader from "../../customer/customer-header";
import GenericTable from "../../../components/Table/generic-table";
import { customerText } from "../../../components/Seo/whitelabel/bybest/bybest-customer-layout";
import CustomerService from "../../../services/customer-service";
import { transformBookingData } from "./payment-functions";

const PaymentsTable = () => {
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    total: data.length,
  });

  useEffect(() => {
    CustomerService.getCustomerPayments().then((res) => {
      setData(res.data?.map((item) => transformBookingData(item)));
    });
  }, []);

  const paymentsColumns = [
    {
      title: customerText("apartment"),
      dataIndex: "apartment",
      key: "apartment",
      render: (apartment) => (
        <div className="flex items-center gap-2">
          <img
            src={apartment.image}
            alt={apartment.name}
            className="w-12 h-12 rounded-lg object-cover"
          />
          <p className="text-14 text-[#101828] font-medium">{apartment.name}</p>
        </div>
      ),
    },
    {
      title: customerText("booked"),
      dataIndex: "booked",
      key: "booked",
      render: (dates) => (
        <p className="text-14 text-[#667085] font-medium">{dates}</p>
      ),
    },
    {
      title: customerText("paidOn"),
      dataIndex: "paidOn",
      key: "paidOn",
      render: (paidOn) => (
        <p className="text-14 text-[#667085] font-medium">{paidOn}</p>
      ),
    },
    {
      title: customerText("price"),
      dataIndex: "price",
      key: "price",
      render: (price) => (
        <p className="text-14 text-[#101828] font-medium">{price}</p>
      ),
    },
    {
      title: customerText("prepayment_amount"),
      dataIndex: "prepayment_amount",
      key: "prepayment_amount",
      render: (prepayment_amount) => (
        <p className="text-14 text-[#101828] font-medium">
          {prepayment_amount}
        </p>
      ),
    },
    {
      title: customerText("confirmation_code"),
      dataIndex: "confirmation_code",
      key: "confirmation_code",
      render: (confirmation_code) => (
        <p className="text-14 text-[#101828] font-medium">
          {confirmation_code}
        </p>
      ),
    },
    {
      title: customerText("status"),
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <span
          className={`px-2 py-1 rounded-lg text-14 font-medium ${
            status === "completed" || status === "paid"
              ? "bg-[#ECFDF3] text-[#12B76A]"
              : "bg-[#FEF3F2] text-[#F04438]"
          }`}
        >
          {customerText(status)}
        </span>
      ),
    },
  ];

  const handlePageChange = (page) => {
    setPagination((prev) => ({
      ...prev,
      current: page,
    }));
  };

  return (
    <div className="flex flex-1 flex-col mt-6">
      <div className="bg-white w-full boxshadow border-[1px] border-[#EAECF0] rounded-lg">
        <div className="flex justify-between items-center w-full p-6">
          <CustomerHeader
            description={customerText("paymentHistoryDescription")}
            numberOfItems={data.length}
            items={customerText("totalPayments")}
            title={customerText("payments")}
            showHome={false}
          />
        </div>
        <GenericTable
          columns={paymentsColumns}
          data={data?.slice(
            (pagination.current - 1) * 10,
            pagination.current * 10
          )}
          className="w-full hidden md:block pr-4"
          pagination={{
            ...pagination,
            onChange: handlePageChange,
          }}
        />
      </div>
    </div>
  );
};

export default PaymentsTable;
