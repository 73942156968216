import { API_Enduser } from "../constants/apiConfig";
import { endUser_Appkey } from "../constants/config";
import apiFactory from "../utils/apiFactory";

const xApi = "SN_BOOST_CORE_ENDUSER_API_KEY";
const appKey = endUser_Appkey;

export default class CustomerService {
  static getMessages = async (chatId, enduser_id) => {
    const token = localStorage.getItem("accToken");

    let api = {
      content_type: "application/json",
      path: `${API_Enduser.path}/chat/messages/${chatId}`,
      method: "GET",
      query: `enduser_id=${enduser_id}`,
    };
    if (token) {
      return apiFactory(api, {}, token, undefined, true, xApi, appKey);
    }
  };

  static getProfile = async () => {
    const token = localStorage.getItem("accToken");
    let api = {
      content_type: "application/json",
      path: `${API_Enduser.path}/setting/profile`,
      method: "GET",
    };
    if (token) {
      return apiFactory(api, {}, token, undefined, true, xApi, appKey);
    }
  };

  static bybestUpdateProfile = async (payload) => {
    const token = localStorage.getItem("accToken");

    let api = {
      content_type: "application/json",
      path: `${API_Enduser.path}/setting/profile`,
      method: "PUT",
    };
    return apiFactory(api, payload, token, undefined, true, xApi, appKey);
  };

  static resetPassword = async (payload) => {
    const token = localStorage.getItem("accToken");

    let api = {
      content_type: "application/json",
      path: `${API_Enduser.path}/setting/reset-password`,
      method: "POST",
    };
    if (token) {
      return apiFactory(api, payload, token, undefined, true, xApi, appKey);
    }
  };

  static getUserSecurityActivities = async () => {
    const token = localStorage.getItem("accToken");

    let api = {
      content_type: "application/json",
      path: `${API_Enduser.path}/setting/user-security-activities`,
      method: "GET",
    };
    if (token) {
      return apiFactory(api, {}, token, undefined, true, xApi, appKey);
    }
  };

  static getBookingById = async (id) => {
    const token = localStorage.getItem("accToken");

    let api = {
      content_type: "application/json",
      path: `${API_Enduser.path}/bookings/details/${id}`,
      method: "GET",
    };
    if (token) {
      return apiFactory(api, {}, token, undefined, true, xApi, appKey);
    }
  };

  static getCustomerPayments = async (id) => {
    const token = localStorage.getItem("accToken");

    let api = {
      content_type: "application/json",
      path: `${API_Enduser.path}/guest/payments`,
      method: "GET",
    };
    if (token) {
      return apiFactory(api, {}, token, undefined, true, xApi, appKey);
    }
  };

  static getPromotions = async (query) => {
    const token = localStorage.getItem("accToken");

    let api = {
      content_type: "application/json",
      path: `${API_Enduser.path}/promotions-guest`,
      method: "GET",
      query: `per_page=${query.per_page}&page=${query.page}&type=${query.type}`,
    };
    if (token && query.per_page && query.page) {
      return apiFactory(api, query, token, undefined, true, xApi, appKey);
    }
  };

  static sendMessage = async (payload) => {
    const token = localStorage.getItem("accToken");
    let api = {
      content_type: "multipart/form-data",
      path: `${API_Enduser.path}/chat/messages`,
      method: "POST",
    };
    if (token) {
      return apiFactory(api, payload, token, undefined, true, xApi, appKey);
    }
  };

  // marketing

  static getMarketingSettings = async () => {
    const token = localStorage.getItem("accToken");
    const source = "metrosuites";
    const api = {
      content_type: "application/json",
      path: `${API_Enduser.path}/setting/marketing-settings`,
      method: "GET",
      query: `source=${source}`,
    };
    if (token) {
      return apiFactory(api, {}, token, undefined, true, xApi, appKey);
    }
  };

  static updateMarketingSettings = async (settings) => {
    const token = localStorage.getItem("accToken");
    const api = {
      content_type: "application/json",
      path: `${API_Enduser.path}/setting/marketing-settings`,
      method: "POST",
    };

    return apiFactory(api, settings, token, undefined, true, xApi, appKey);
  };

  static getWalletInfo = async () => {
    const token = localStorage.getItem("accToken");
    const source = "metrosuites"; // or any other source you want to send
    let api = {
      content_type: "application/json",
      path: `${API_Enduser.path}/wallet/info`,
      method: "GET",
      query: `source=${source}`,
    };
    if (token) {
      return apiFactory(api, {}, token, undefined, true, xApi, appKey);
    }
  };
}
