import { DatePicker, Select } from "antd";
import React from "react";

const RentalCalendar = ({
  handleAction,
  guestList = [],
  selectedGuests,
  setSelectedGuests,
  isDateInRange,
  checkIn,
  checkOut,
}) => {
  return (
    <div className="rounded-[8px] border-[1px] border-[#E2E8F0] p-3 mt-4 md:mt-0">
      <div className="grid grid-cols-2 gap-3 mb-2">
        <div className="">
          <p className="text-12 text-[#6DDFE2] font-bold leading-[16px] mb-2">
            CHECK-IN
          </p>
          <DatePicker
            className="flex flex-1"
            open={false}
            value={checkIn}
            disabledDate={isDateInRange}
            onClick={() => handleAction()}
          />
        </div>
        <div className="">
          <p className="text-12 text-[#6DDFE2] font-bold leading-[16px] mb-2">
            CHECK-OUT
          </p>
          <DatePicker
            className="flex flex-1"
            open={false}
            value={checkOut}
            disabledDate={isDateInRange}
            onClick={() => handleAction()}
          />
        </div>
      </div>
      <div className="mt-4">
        <p className="text-12 text-[#6DDFE2] font-bold leading-[16px] mt-1 mb-2">
          GUESTS
        </p>
        <Select
          defaultValue="Select Guest"
          className="flex flex-1"
          value={selectedGuests.label}
          onChange={(e, value) => {
            setSelectedGuests({
              value: value.children,
              label: e,
            });
          }}
        >
          {guestList
            .sort((a, b) => a.label - b.label)
            .map((guest, index) => {
              return (
                <Select.Option key={index} value={guest.value}>
                  {guest.label}
                </Select.Option>
              );
            })}
        </Select>
      </div>
    </div>
  );
};

export default RentalCalendar;
