import { SET_CHAT_MESSAGES, SET_CHAT_LAST_MSG } from '../actions/types';
import ImgLogo from '../../assets/images/logo_circle.png';

const initialState = {
    messages: [],
    lastMessage: null
};

const app = (state = initialState, action) => {
    switch (action.type) {
        case SET_CHAT_MESSAGES:
            return { ...state, messages: action.payload || [] };
        case SET_CHAT_LAST_MSG:
            return { ...state, lastMessage: action.payload };
        default:
            return state;
    }
};

export default app;
