import dayjs from "dayjs";
import { ApartmentCard } from "./apartment-card";

export function BookingsData({ bookingsData, onClick, filterValue }) {
  const periods = bookingsData?.Unknown?.periods;
  const mappablePeriods = periods
    ? Object.entries(periods).map(([key, value]) => {
        return { month: key, ...value };
      })
    : [];
  const filteredData = (period) =>
    period?.bookings
      .map((booking_item) => ({
        ...booking_item,
        status: (booking_item.status === "Pending" || booking_item.status === "Confirmed") ? "Active" : booking_item.status,
      }))
      .filter((booking_item) => booking_item.status === filterValue);

  const title =
    Object.keys(bookingsData)[0] === "Unknown"
      ? "Albania"
      : Object.keys(bookingsData)[0];

  return mappablePeriods.map((period) => {
    const filtered = filteredData(period)?.length > 0;
    return (
      filtered && (
        <div className="py-4 mt-6 border-t-[1px]">
          <div className="mb-6">
            <h2 className="text-xl font-semibold">{title}</h2>
            <p className="text-sm text-[#667085]">
              {`16-18 ${dayjs(period.month).format("MMM YYYY")}`}
            </p>
          </div>
          <div className="grid sm:grid-cols-2 grid-cols-1 flex-wrap gap-6">
            {filteredData(period).map((booking) => (
              <div key={booking.id}>
                <ApartmentCard
                  key={booking.id}
                  apartment={booking}
                  onClick={onClick}
                />
              </div>
            ))}
          </div>
        </div>
      )
    );
  });
}
