export const APP_NAME = "VenueBoost Web";
export const API_BOOST_CORE_URL = "https://core.venueboost.io/api/v1/";
// export const API_BOOST_CORE_URL = 'http://localhost/api/v1/';
export const APP_KEY = "boost-sn-24011xC0R4-web!";
export const endUser_Appkey = "boost-sn-25010xC0R5-enduser!";
// prod
export const STRIPE_PUB_KEY =
  "pk_live_51NfR0wK9QDeYHZl0uhI2Yiks5BlbB7xC7VT4DcXvjWv2TK18ZxgkGdNC1JVlLZzY1XMikT2bdAFh4aOIhNNbtvab008FOIt7uw";
// test
// export const STRIPE_PUB_KEY = 'pk_test_51NfR0wK9QDeYHZl0UJfwbBV4pQA720OkzlPCINfIXd1ghM1AHnvGIYdgOyp1s57JgJfg9zllXgROGytlwkwR0PeO00jNXxIIVd';

export const Langs = [
  {
    id: "en",
    title: "EN",
    name: "English",
  },
  // Temporary disable
  // {
  //     id: 'es',
  //     title: 'ES',
  //     name: 'Español'
  // },
  // {
  //     id: 'fr',
  //     title: 'FR',
  //     name: 'Français'
  // },
  // {
  //     id: 'de',
  //     title: 'DE',
  //     name: 'Deutsch'
  // },
  // {
  //     id: 'it',
  //     title: 'IT',
  //     name: 'Italiano'
  // },
  // Temporary disable
  // {
  //     id: 'gr',
  //     title: 'GR',
  //     name: 'Ελληνικά'
  // },
  // {
  //     id: 'al',
  //     title: 'AL',
  //     name: 'Shqip'
  // },
];
