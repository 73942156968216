import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { identifyVenue, trackEvent } from "../../../utils/mixpanelUtil";
import { NotificationManager } from "react-notifications";
import WhiteLabelService from "../../../services/whiteLabelService";
import SearchIcon from "../../../assets/images/search.svg";
import CheckIn from "../../../assets/images/checkIn.svg";
import CheckOut from "../../../assets/images/checkOut.svg";
import Appt from "../../../assets/images/appt.svg";
import Guest from "../../../assets/images/guest.svg";
import { getAccommodationText } from "..";

export const SelectApartment = () => {
  const rental_units =
    useSelector((state) => state.venue?.additional_information?.rental_units) ??
    [];
  const navigate = useNavigate();

  const [rental_unit, setRentalUnit] = useState();
  const [guestList, setGuestList] = useState([]);
  const [dates, setDates] = useState([]);
  const [selectedApartment, setSelectedApartment] = useState();
  const [checkinDate, setCheckinDate] = useState("");
  const [checkoutDate, setCheckoutDate] = useState("");
  const [days, setDays] = useState("");
  const [price, setPrice] = useState("");
  const [total, setTotal] = useState("");
  const [discount, setDiscount] = useState(null);
  const [discountValue, setDiscountValue] = useState(null);
  const [selectedGuests, setSelectedGuests] = useState("");

  const guestOptions = useMemo(() => {
    if (rental_unit?.price_per_nights) {
      return rental_unit?.price_per_nights?.map((item) => {
        return {
          value: item.nr_guests,
          label: `${item?.nr_guests} ${
            item?.nr_guests === 1 ? "Guest" : "Guests"
          }`,
        };
      });
    }
    return [];
  }, [rental_unit]);

  const getDetails = (rental_code) => {
    WhiteLabelService.getRentallDetails(rental_code).then(
      (res) => {
        setRentalUnit(res?.rental_unit);

        if (res?.rental_unit?.price_per_nights) {
          setGuestList(
            res?.rental_unit?.price_per_nights.map((item) => {
              return { value: item.id, label: item.nr_guests };
            })
          );
        }

        // dispatch({ type: SET_VENUE_DATA, payload: res.venue });

        identifyVenue(rental_code);
        trackEvent("Vacation Rental - Detail", {
          action_category: "Page Load",
          action_outcome: "success",
          interaction_element: "screen",
          industry: "accommodation",
          source: "vb_web",
        });
      },
      (error) => {
        NotificationManager.error(
          error?.message || getAccommodationText("error_msg_oops"),
          "Error"
        );

        identifyVenue(rental_code);
        trackEvent("Vacation Rental - Detail", {
          action_category: "Page Load",
          action_outcome: "fail",
          interaction_element: "screen",
          industry: "accommodation",
          source: "vb_web",
        });
      }
    );
  };

  const handleGuestChange = (event) => {
    setSelectedGuests(event.target.value);
  };

  const handleCheckinChange = (event) => {
    const selectedDate = event.target.value;
    setCheckinDate(selectedDate);

    // + 1 day validation on calendar doesn't work
    // const generateCheckoutDates = () => {
    //   const dateArray = [];
    //   const startDate = new Date(selectedDate);
    //
    //   for (let i = 1; i <= 60; i++) {
    //     const date = new Date(startDate);
    //     date.setDate(startDate.getDate() + i);
    //
    //     const yyyy = date.getFullYear();
    //     const mm = String(date.getMonth() + 1).padStart(2, "0");
    //     const dd = String(date.getDate()).padStart(2, "0");
    //
    //     const dateString = `${dd} ${date.toLocaleString("default", {
    //       month: "long",
    //     })} ${yyyy}`;
    //     const optionValue = `${yyyy}-${mm}-${dd}`;
    //
    //     dateArray.push({ value: optionValue, label: dateString });
    //   }
    //
    //   setDates(dateArray);
    // };
    //
    // generateCheckoutDates();
  };

  const handleCheckoutChange = (event) => {
    const selectedDate = event.target.value;
    setCheckoutDate(selectedDate);

    const checkIn = new Date(checkinDate);
    const checkOut = new Date(selectedDate);

    if (
      checkinDate &&
      checkoutDate &&
      checkIn.getTime() === checkOut.getTime()
    ) {
      NotificationManager.warning(
        "Check-in and check-out dates cannot be the same."
      );
      return 0;
    } else if (checkinDate && checkOut <= checkIn) {
      NotificationManager.warning(
        getAccommodationText("laterDates"),
        getAccommodationText("invalidDates")
      );
      setCheckoutDate("");
    }
  };

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    const apartment = JSON.parse(selectedValue);
    if (apartment) {
      setSelectedApartment(apartment);
    }
  };

  const getPriceValue = (guestNumber) => {
    return (rental_unit?.price_per_nights ?? []).find(
      (item) => Number(item?.nr_guests) === Number(guestNumber)
    );
  };

  const CheckDateDifference = () => {
    const startDate = new Date(checkinDate);
    const endDate = new Date(checkoutDate);

    const differenceInMilliseconds = endDate - startDate;
    const differenceInDays = Math.floor(
      differenceInMilliseconds / (24 * 60 * 60 * 1000)
    );
    return differenceInDays;
  };

  const extractIdFromUrl = (url) => {
    const parts = url.split("/rental/");
    return parts.length > 1 ? parts[1] : null;
  };

  const handleNavigate = () => {
    navigate("/rental/reserve", {
      state: {
        price,
        days,
        total,
        discount,
        discountValue,
        guestNo: selectedGuests,
        geustId: selectedGuests.label,
        checkInDate: checkinDate,
        checkOutDate: checkoutDate,
        pricePerNight: rental_unit?.price_per_nights ?? [],
        guestList,
        selectedGuests,
        currency: rental_unit?.header_section?.currency,
        rentalId: extractIdFromUrl(selectedApartment?.url),
        rentalName: selectedApartment?.name,
        rentalImg: rental_unit?.header_section?.gallery[0].photo_path,
      },
    });
  };

  const checkApartmentAvailability = () => {
    const payload = {
      rental_unit: selectedApartment.name,
      check_in_date: checkinDate,
      check_out_date: checkoutDate,
    };
    WhiteLabelService.apartamentDetails(payload)
      .then((res) => {
        if (res.code === 1) {
          handleNavigate();
        } else {
          NotificationManager.warning(res.message ?? "");
        }
      })
      .catch(() => {
        console.log("");
      });
  };

  useEffect(() => {
    if (selectedGuests) {
      let discountValue = 0;
      let DaysRange = CheckDateDifference();
      setDays(DaysRange);
      const data = getPriceValue(selectedGuests);
      setPrice(Number(data?.price));
      setTotal(Number(data?.price) * Number(CheckDateDifference() ?? 1));
      setDiscount(Number(data?.discount ?? 0));
      discountValue = Number(total) * (Number(data?.discount ?? 0) / 100);
      setDiscountValue(discountValue);
    }
    // eslint-disable-next-line
  }, [selectedGuests]);

  useEffect(() => {
    if (selectedApartment) {
      const url = selectedApartment.url;
      const id = extractIdFromUrl(url);

      getDetails(id);
    }
  }, [selectedApartment?.url, selectedApartment]);

  useEffect(() => {
    const generateNext60Days = () => {
      const today = new Date();
      const dateArray = [];

      for (let i = 0; i < 60; i++) {
        const date = new Date();
        date.setDate(today.getDate() + i);

        const yyyy = date.getFullYear();
        const mm = String(date.getMonth() + 1).padStart(2, "0");
        const dd = String(date.getDate()).padStart(2, "0");

        const dateString = `${dd} ${date.toLocaleString("default", {
          month: "long",
        })} ${yyyy}`;
        const optionValue = `${yyyy}-${mm}-${dd}`;

        dateArray.push({ value: optionValue, label: dateString });
      }

      setDates(dateArray);
    };

    generateNext60Days();
  }, []);

  const isSearchEnabled =
    selectedApartment && checkinDate && checkoutDate && selectedGuests;

  return (
    <div className="lg:w-full w-[90%] z-50 mb-8 lg:mb-0 mt-5 lg:mt-0 lg:mx-0 mx-10">
      <div className="max-w-[1114px] w-full rounded-[20px] bg-white flex flex-col lg:flex-row justify-between items-center mx-auto p-7 lg:gap-0 gap-2">
        <div className="w-full grid lg:grid-cols-2 md:grid-cols-1 lg:flex lg:flex-col gap-2 lg:px-[16px] py-2 lg:py-0">
          <span className="text-18 leading-[30px] font-semibold flex gap-2 items-center">
            <img src={CheckIn} alt="icons" />{" "}
            {getAccommodationText("checkInDate")}
          </span>
          <select
            placeholder="Select check-in date"
            className="text-16 p-0 text-gray-500"
            style={{
              WebkitAppearance: "none",
              appearance: "none",
              backgroundColor: "#ffffff",
              backgroundImage: `url("data:image/svg+xml;utf8,<svg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M1 1L5 5L9 1' stroke='%23D3D3D3' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/></svg>")`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "right 10px center",
              paddingRight: "25px",
            }}
            onChange={handleCheckinChange}
            value={checkinDate}
          >
            <option value="">{getAccommodationText("selectDate")}</option>
            {dates.map((date, index) => (
              <option key={index} value={date.value}>
                {date.label}
              </option>
            ))}
          </select>
        </div>
        <hr className="bg-[#D3D3D3] w-[1px] lg:h-[65px] h-[0px]" />
        <div className="w-full grid lg:grid-cols-2 md:grid-cols-1 lg:flex lg:flex-col gap-2 lg:px-[16px] py-2 lg:py-0">
          <span className="text-18 leading-[30px] font-semibold flex gap-2 items-center">
            <img src={CheckOut} alt="icons" />{" "}
            {getAccommodationText("checkOutDate")}
          </span>
          <select
            placeholder="Checkout"
            className="text-16 p-0 text-gray-500"
            style={{
              WebkitAppearance: "none",
              appearance: "none",
              backgroundColor: "#ffffff",
              backgroundImage: `url("data:image/svg+xml;utf8,<svg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M1 1L5 5L9 1' stroke='%23D3D3D3' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/></svg>")`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "right 10px center",
              paddingRight: "25px", // Adjust based on your existing padding
            }}
            onChange={handleCheckoutChange}
            value={checkoutDate}
          >
            <option value="">{getAccommodationText("selectDate")}</option>
            {dates.map((date, index) => (
              <option key={index} value={date.value}>
                {date.label}
              </option>
            ))}
          </select>
        </div>
        <hr className="bg-[#D3D3D3] w-[1px] lg:h-[65px] h-[0px]" />
        <div className="w-full grid lg:grid-cols-2 md:grid-cols-1 lg:flex lg:flex-col gap-2 lg:px-[16px] py-2 lg:py-0">
          <span className="text-18 leading-[30px] font-semibold flex gap-2 items-center">
            <img src={Appt} alt="icons" /> {getAccommodationText("apartment")}
          </span>
          <select
            placeholder="Select type"
            className="text-16 p-0 text-gray-500"
            style={{
              WebkitAppearance: "none",
              appearance: "none",
              backgroundColor: "#ffffff",
              backgroundImage: `url("data:image/svg+xml;utf8,<svg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M1 1L5 5L9 1' stroke='%23D3D3D3' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/></svg>")`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "right 10px center",
              paddingRight: "25px", // Adjust based on your existing padding
            }}
            onChange={handleChange}
          >
            <option value="">{getAccommodationText("selectApartment")}</option>
            {rental_units.map((unit, index) => (
              <option key={index} value={JSON.stringify(unit)}>
                {unit.name}
              </option>
            ))}
          </select>
        </div>
        <hr className="bg-[#D3D3D3] w-[1px] lg:h-[65px] h-[0px]" />
        <div className="w-full grid lg:grid-cols-2 md:grid-cols-1 lg:flex lg:flex-col gap-2 lg:px-[16px] py-2 lg:py-0">
          <span className="text-18 leading-[30px] font-semibold flex gap-2 items-center">
            <img src={Guest} alt="icons" /> {getAccommodationText("nrOfGuests")}
          </span>
          <select
            placeholder={getAccommodationText("selectGuests")}
            className="text-16 p-0 text-gray-500"
            value={selectedGuests}
            style={{
              WebkitAppearance: "none",
              appearance: "none",
              backgroundColor: "#ffffff",
              backgroundImage: `url("data:image/svg+xml;utf8,<svg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M1 1L5 5L9 1' stroke='%23D3D3D3' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/></svg>")`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "right 10px center",
              paddingRight: "25px", // Adjust based on your existing padding
            }}
            onChange={handleGuestChange}
          >
            <option value="">{getAccommodationText("selectGuests")}</option>
            {guestOptions.map((option, index) => (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
        <button
          onClick={checkApartmentAvailability}
          className={`searchBtn lg:mt-0 mt-3 ${
            isSearchEnabled ? "bg-[#6DDFE2]" : "bg-[#6DDFE2]"
          }`}
          disabled={!isSearchEnabled}
        >
          <img className="hidden lg:block" src={SearchIcon} alt="SearchIcon" />
          <p className="text-[16px] text-white text-center lg:hidden block font-bold">
            {getAccommodationText("checkAvailability")}
          </p>
        </button>
      </div>
    </div>
  );
};
