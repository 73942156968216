import Carousel from "react-multi-carousel";
import { motion } from "framer-motion";
import { useRef } from "react";
import "./index.css";
import { carouselItems } from "./data";
import "react-multi-carousel/lib/styles.css";
import Avatar from "../../../assets/images/black-avatar.png";
import { getAccommodationText } from "..";

const AccommodationGuests = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 2,
    },
    largeDesktop: {
      breakpoint: { max: 3000, min: 2050 },
      items: 2,
    },
    desktop: {
      breakpoint: { max: 2050, min: 1240 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1240, min: 768 },
      items: 1,
    },
    mobile1: {
      breakpoint: { max: 768, min: 414 },
      items: 1,
    },
    mobile2: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
    },
  };

  const carouselRef = useRef(null);

  const handlePrev = () => {
    if (carouselRef?.current) {
      carouselRef?.current?.previous();
    }
  };

  const handleNext = () => {
    if (carouselRef?.current) {
      carouselRef?.current?.next();
    }
  };

  return (
    <div className="bg-[#F8F9FA] guest-saying py-16 w-full mx-auto px-4 sm:px-6 md:px-8 lg:px-16 flex justify-center flex-col large-width">
      <div className="text-[#2D3748] lg:text-[48px] text-28 font-bold">
        {getAccommodationText("GuestTestimonialsTitle")}
      </div>
      <p className="lg:text-18 text-16 text-[#6E706E]">
        {getAccommodationText("guestTestimonials")}
      </p>
      <div className="w-full">
        <Carousel
          ref={carouselRef}
          responsive={responsive}
          infinite={false}
          autoPlay={false}
          centerMode={false}
          showDots={true}
          partialVisible={false}
          arrows={false}
          swipeable={false}
          draggable={true}
          keyBoardControl={false}
          className="!h-full pb-14"
          containerClass={"pt-6 services-carousel w-full"}
        >
          {carouselItems.map((item, index) => (
            <motion.div
              key={index}
              className="flex !h-full"
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0.8 }}
            >
              <div className="rounded-[8px] bg-white py-[30px] px-6 w-full m-3 flex flex-col gap-5">
                <div className="flex gap-1">
                  {[1, 2, 3, 4, 5].map((star) => (
                    <img
                      key={star}
                      src={item.img}
                      alt=""
                      className="mt-4 rounded-lg"
                    />
                  ))}
                </div>
                <div className="text-18 font-medium text-[#121511] leading-7">
                  {item.review}
                </div>
                <div className="flex flex-row gap-3 items-center">
                  <img className="w-12 h-12" src={Avatar} alt="Avatar" />
                  <div>
                    <div className="text-18 text-[#171717] font-semibold">
                      {item.name}
                    </div>
                    {/*<div className="text-16 text-[#6E706E] font-normal">*/}
                    {/*  United Kingdom*/}
                    {/*</div>*/}
                  </div>
                </div>
              </div>
            </motion.div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default AccommodationGuests;
